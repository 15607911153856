import React, { useEffect, useState } from "react";
import { GetFaqs } from "../../api";
import Header from "../../Components/Layout/Header";
import Footer from "../../Components/Layout/Footer";
import Loader from '../../Components/loader'

import { Link, useNavigate } from "react-router-dom";

import {
  platinumFeetText,
  headerSearchIcon,
  legsImage01,
  imgTopCorner,
  imgBottomCorner,
  hundredPercentage,
  homeImg01,
  modelText,
  modelImg01,
  modelCardTopCorner,
  modelCardBottomCorner,
  modelImg02,
  modelImg03,
  modelImg04,
  framePic,
  hoverableImg,
  mainFrameImg,
  videoPlayIcon,
  modelImg05,
  modelImg06,
  modelImg07,
  howItWorksText,
  howItWorksImg,
  forClientText,
  forClientImg,
  forModelsText,
  formodelImg01,
  formodelImg02,
  faqText,
} from "../../Asserts/images/index";

import "./style.css";

import Aos from "aos";
import "aos/dist/aos.css";

const Statement = () => {
  const [getFaqs, setgetFaqs] = useState();
const [isLoading , setLoading] = useState(false)
  const Faqs = async () => {
    try {
      const response = await GetFaqs();
      console.log("response", response);

      setgetFaqs(response?.data);
      setLoading(false);
    } catch (error) {
      console.error("Error in logging in:", error);

      // toastAlert(error, ALERT_TYPES.ERROR);
    }
  };

  console.log("getFaqs", getFaqs);
  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  useEffect(() => {
    Faqs();
  }, []);
  const navigate = useNavigate();
  const handleclick = () => {
    navigate("/model-page");
  };
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <div className="home_page_main">
      <div>
        <Header />
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="models_section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div
                className="faq_heading_text_img text-center"
                data-aos="fade-left"
                data-aos-anchor-placement="center-bottom"
                data-aos-duration="2000"
              >
                {/* <img src={faqText} /> */}
                <h1 className="main_head">2257 Statement    </h1>
                <p className="main_para">
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has roots in a piece of classical Latin literature
                  from 45 BC, making it over 2000 years old. Richard
                  McClintock, a Latin professor at Hampden-Sydney College in
                  Virginia, looked up one of the more obscure Latin words,
                  consectetur, from a Lorem Ipsum passage, and going through
                  the cites of the word in classical literature, discovered
                  the undoubtable source. Lorem Ipsum comes from sections
                  1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The
                  Extremes of Good and Evil) by Cicero, written in 45 BC. This
                  book is a treatise on the theory of ethics, very popular
                  during the Renaissance. The first line of Lorem Ipsum,
                  "Lorem ipsum dolor sit amet..", comes from a line in section
                  1.10.32 Contrary to popular belief, Lorem Ipsum is not
                  simply random text. It has roots in a piece of classical
                  Latin literature from 45 BC, making it over 2000 years old.
                  Richard McClintock, a Latin professor at Hampden-Sydney
                  College in Virginia, looked up one of the more obscure Latin
                  words, consectetur, from a Lorem Ipsum passage, and going
                  through the cites of the word in classical literature,
                  discovered the undoubtable source. Lorem Ipsum comes from
                  sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et
                  Malorum" (The Extremes of Good and Evil) by Cicero, written
                  in 45 BC. This book is a treatise on the theory of ethics,
                  very popular during the Renaissance. The first line of Lorem
                  Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in
                  section 1.10.32.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      )}
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Statement;
