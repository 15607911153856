import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import "./style.css";

export const SelectBox = (props) => {
  const handleClick = (e) => {
    console.log(e);
  };

  return (
    <div className="inputWrapper">
    <div className="inputIcon">
      {/* Use FontAwesomeIcon for styling */}
      <FontAwesomeIcon icon={props?.iconShow} />
    </div>
    {/* Conditionally render label based on props */}
    {props?.label && (
      <label htmlFor={props?.id} className={props?.labelClass}>
        {props?.label}
        {props?.required ? '*' : ''}
      </label>
    )}
    <div className="fieldData">
      {/* Dynamic Select Box */}
      <select
        className={props?.selectClass}
        name={props?.name}
        id={props?.id}
        onChange={props?.onChange}
        value={props?.value}
        required={props?.required}
        disabled={props?.disabled}
      >
        {/* Placeholder Option */}
        {!props?.keepSelected && (
          <option value="Null">{`Select ${props?.name}`}</option>
        )}
        {/* Render Options based on props.option */}
        {Array.isArray(props?.option) &&
          props.option.map((item) => (
            <option key={item.id} value={item.id}>
              {item?.title || item?.slot || item?.name || item?.first_name}
            </option>
          ))}
      </select>
      {/* Conditionally render button action if passed in props */}
      {props?.buttonAction && (
        <button type="button" onClick={props?.buttonAction}>
          <FontAwesomeIcon
            icon={faTrashAlt}
            className="removeField"
          ></FontAwesomeIcon>
        </button>
      )}
    </div>
  </div>
  );
};
