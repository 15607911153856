import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Header from "../../Components/Layout/Header";
import Footer from "../../Components/Layout/Footer";
import { Swiper, SwiperSlide } from "swiper/react";
// import $ from 'jquery';
import posticon from "../../Asserts/images/posticon.png";
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../Components/loader";
import { modellist, CategoryListing, Postlisting, GetFaqs } from "../../api";
import {
  platinumFeetText,
  headerSearchIcon,
  legsImage01,
  imgTopCorner,
  imgBottomCorner,
  hundredPercentage,
  homeImg01,
  onfrontimage,
  modelText,
  modelImg01,
  userProfilePic,
  modelCardTopCorner,
  locked_icon,
  modelCardBottomCorner,
  modelImg02,
  modelImg03,
  modelImg04,
  framePic,
  hoverableImg,
  mainFrameImg,
  videoPlayIcon,
  modelImg05,
  modelImg06,
  modelImg07,
  howItWorksText,
  howItWorksImg,
  forClientText,
  forClientImg,
  forModelsText,
  formodelImg01,
  Cancel,
  formodelImg02,
  faqText
} from "../../Asserts/images/index";
import dummy from "../../Asserts/images/dummy.jpg";
import platinumlogo from "../../Asserts/images/platinumlogo.png";
import feet from "../../Asserts/images/feetlogo.png";
import "./style.css";

import Aos from "aos";
import "aos/dist/aos.css";

const Home = () => {
  //CONST VALS
  const [inputValue, setInputValue] = useState("");

  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();
  const handledetail = (id) => {
    navigate(`/profile-page/${id}`);
  };
  const handleclick = () => {
    navigate("/model-page");
  };
  useEffect(() => {
    Aos.init();
  }, []);

  const LogoutData = localStorage.getItem("userToken");

  const handlenavigate = (id) => {
    // navigate('/profile-page')
    navigate(`/model-detail-page/${id}`);
  };

  const handlenavigatepost = (id) => {
    // navigate('/profile-page')
    navigate(`/profile-detail-page/${id}`);
  };

  const handlemodelnavigate = (id) => {
    // navigate('/profile-page')
    navigate(`/model-detail-page/${id}`);
  };

  const baseurl = `${process.env.REACT_APP_BASE_URL}/public/`;
  console.log("baseurl", baseurl);
  const [Postlists, setPostlists] = useState([]);
  const [GetCatlists, setGetCatlists] = useState([]);
  const [Modellists, setModellists] = useState([]);

  const CategoryList = async () => {
    try {
      const response = await CategoryListing();
      console.log("response", response);

      setGetCatlists(response?.data);
      setLoading(false);
    } catch (error) {
      console.error("Error in logging in:", error);

      // toastAlert(error, ALERT_TYPES.ERROR);
    }
  };

  console.log("Modellists", Modellists);

  const [isplatinum, setisPlatinum] = useState(false);
  const ModelList = async () => {
    try {
      const response = await modellist();
      console.log("response", response);

      setModellists(response?.data);
      setisPlatinum(response?.is_platinum);
      setLoading(false);
    } catch (error) {
      console.error("Error in logging in:", error);

      // toastAlert(error, ALERT_TYPES.ERROR);
    }
  };

  const Postist = async () => {
    try {
      const response = await Postlisting();
      console.log("response", response);

      setPostlists(response?.data);
      setLoading(false);
    } catch (error) {
      console.error("Error in logging in:", error);

      // toastAlert(error, ALERT_TYPES.ERROR);
    }
  };
  const [getFaqs, setgetFaqs] = useState();

  const Faqs = async () => {
    try {
      const response = await GetFaqs();
      console.log("response", response);

      setgetFaqs(response?.data);
      setLoading(false);
    } catch (error) {
      console.error("Error in logging in:", error);

      // toastAlert(error, ALERT_TYPES.ERROR);
    }
  };

  console.log("getFaqs", getFaqs);

  useEffect(() => {
    ModelList();
    Faqs();
  }, []);

  // /  /GetFaqs

  console.log("GetCatlists", Postlists);
  useEffect(() => {
    Postist();
    CategoryList();
  }, []);

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  // const filterData = Postlists?.filter((item) =>
  //   item?.post_title?.toLowerCase().includes(inputValue.toLowerCase())
  // );

  const [pkgcatid, setpkgcatid] = useState("");
  const [catid, setcatid] = useState("");

  console.log("pkgcatid", pkgcatid);
  const filterData = Postlists?.filter((item) => {
    const matchesTitle = item?.post_title
      ?.toLowerCase()
      .includes(inputValue?.toLowerCase() || "");
    const matchesCategory = catid ? item?.category_id === catid : true;

    return matchesTitle && matchesCategory;
  });

  // const pakage = Postlists?.filter(
  //   (item) => {
  //     const pkgitm = item?.package?.toLowerCase() === "platinum";
  //     const matchesCategory = pkgcatid
  //       ? pkgitm?.category_id == pkgcatid
  //       : true;
  //     return matchesCategory;
  //   }
  //   //  item?.package?.toLowerCase() === "platinum"
  // );

  console.log("filterData", filterData);

  const pakage = Modellists?.filter((item) => {
    // Check if the item has a platinum package
    const isPlatinum = item?.package?.toLowerCase() === "platinum";

    // Check if category matches or if no category filter is applied
    // const matchesCategory =
    //   isPlatinum && (pkgcatid ? item?.category_id === pkgcatid : true);

    // Return true if both conditions are met
    return isPlatinum;
  });

  console.log("pakage", pakage);

  const [modalShow, setModalShow] = useState(
    sessionStorage.getItem("modalshow") !== "false" // Default to true if not set or set to true
  );

  useEffect(() => {
    // Store modal state in sessionStorage whenever it changes
    sessionStorage.setItem("modalshow", modalShow);
  }, [modalShow]);
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="home_page_main">
          <div>
            <Header />
          </div>

          <div className="home_section">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 col-lg-7">
                  <div className="home_left_part mainHeroHeading">
                    <div
                      className="platinum_feet_img"
                      data-aos="fade-right"
                      data-aos-easing="ease-out-cubic"
                      data-aos-duration="2000"
                    >
                      <img
                        src={platinumFeetText}
                        className="img-fluid"
                        id="platinumFeetTextimg"
                      />
                      <div className="mainHeroHeadingImages">
                        <div class="mainHeroImg1Div">
                          <img
                            src={platinumlogo}
                            className="mainHeroImg1"
                            alt=""
                          />
                        </div>
                        <div class="mainHeroImg2Div">
                          <img src={feet} className="mainHeroImg2" alt="" />
                        </div>
                      </div>
                    </div>

                    <div className="actionBtns_and_searchBar">
                      <div>
                        {/* <button
                          className="sign_actionBtn"
                          onClick={() => navigate("/login-page")}
                        >
                          sign in
                        </button> */}

                        {!LogoutData && (
                          <button
                            className="sign_actionBtn"
                            onClick={() => navigate("/login-page")}
                          >
                            sign in
                          </button>
                        )}
                      </div>

                      <div>
                        {/* <button
                          className="sign_actionBtn"
                          onClick={() => navigate("/signup-page")}
                        >
                          sign up
                        </button> */}
                        {!LogoutData && (
                          <button
                            className="sign_actionBtn"
                            onClick={() => navigate("/signup-page")}
                          >
                            sign up
                          </button>
                        )}
                      </div>

                      <div className="main_searchBar">
                        <input
                          onChange={handleChange}
                          value={inputValue}
                          className="searchbar_input_field"
                          type="text"
                          placeholder="search post here"
                        />
                        <button className="searchbar_actionBtn">
                          <img src={headerSearchIcon} />
                        </button>
                      </div>
                    </div>

                    <div className="row py-3">
                      <div className="col-12 col-sm-4 col-lg-5 mx-lg-auto mx-0">
                        <div
                          className="legs_img_with_corners"
                          data-aos="fade-right"
                          data-aos-anchor-placement="center-bottom"
                          data-aos-duration="2000"
                        >
                          <img src={legsImage01} className="img-fluid" />
                        </div>

                        <div className="img_top_corner">
                          <img src={imgTopCorner} />
                        </div>

                        <div className="img_bottom_corner">
                          <img src={imgBottomCorner} />
                        </div>
                      </div>

                      <div className="col-12 col-sm-8 col-lg-12 col-xl-7 align-self-center">
                        <div
                          className="legs_both_parts"
                          data-aos="fade-left"
                          data-aos-anchor-placement="center-bottom"
                          data-aos-duration="2000"
                        >
                          <div className="first_leg_part">
                            <h4 className="legs_right_part_text_01">
                              no tricks
                            </h4>
                            <h4 className="legs_right_part_text_02">no bots</h4>
                            <h4 className="legs_right_part_text_03">
                              no baiting
                            </h4>
                          </div>

                          <div>
                            <div className="hundred_percent_text_img">
                              <img src={hundredPercentage} />
                            </div>
                            <h4 className="legs_right_part_text_04">
                              authentic models
                            </h4>
                            <h4 className="legs_right_part_text_05">
                              clients ethics
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-8 col-lg-5 mx-auto">
                  <div className="home_right_part">
                    <img src={homeImg01} className="img-fluid" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="models_section">
            <div className="container">
              <div className="row">
                <div className="col-md-12 mt-4">
                  <div className="models_main_container">
                    <div
                      className="model_text_img text-center pb-5"
                      data-aos="fade-up"
                      data-aos-anchor-placement="center-bottom"
                      data-aos-duration="2000"
                    >
                      <div className="model_text_img_head">
                        {/* <img src={platinumlogo} className="platinumModalImg" /> */}
                        <img src={modelText} className="modelTextImg" />
                      </div>

                      <p className="home-para">
                        Join Platinum Feet and start checking things out. You
                        can join instantly if you want. Post Pics & Videos Start
                        posting your feet pics and videos to attract buyers to
                        connect . Connect & Get Paid You start connecting with
                        buyers and start getting paid for your pics/vids and
                        even for customized versions.
                      </p>
                    </div>
                  </div>
                </div>

                {Modellists &&
                  Modellists?.map((item, index) => (
                    <div
                      key={index}
                      className="col-10 col-sm-6 col-lg-3 mx-auto mb-4"
                    >
                      <div className="home_page_model_card">
                        <Swiper
                          spaceBetween={30}
                          slidesPerView={1}
                          onSlideChange={() => console.log("slide change")}
                          onSwiper={(swiper) => console.log(swiper)}
                        >
                          <SwiperSlide key={index}>
                            <div className="model_card_img position-relative">
                              <img
                                src={
                                  item?.profile_pic
                                    ? baseurl + item?.profile_pic
                                    : dummy
                                }
                                className="img-fluid"
                              />
                            </div>

                            <div className="home_page_model_card_desc ">
                              <div className="model_div">
                                {/* <div className="image_with_text_row">
                                <img className="img-fluid model_img" src={userProfilePic} />
                                <p className="post_name_one"> Brittanyvues </p>
                              </div> */}
                              </div>
                              <div className="description_box">
                                <div className="image_with_text_row_two justify-content-between">
                                  <a className="product_heading" href="#">
                                    {`${item?.name?.slice(0, 10)}...`}
                                  </a>
                                  <span className=" gap-4  g-4 d-flex ">
                                    {/* <p className="free_locked_text">
                                      <span className="unlocked_icon">
                                        <i className="fa-solid fa-unlock"></i>
                                      </span>
                                      Free
                                    </p> */}
                                    {/* <p className="lock_text_clr free_locked_text">
                                      <span className="locked_icon">
                                        <i className="fa-solid fa-lock"></i>
                                      </span>
                                      Locked
                                    </p> */}
                                  </span>
                                </div>

                                <p className="product_description">
                                  {/* {item?.bio} */}
                                  {`${item?.bio?.slice(0, 30)}...`}
                                </p>
                              </div>
                              <div className="view_collection_btn_div">
                                <button
                                  className="view_collection_btn"
                                  onClick={() => handlemodelnavigate(item?.id)}
                                >
                                  {" "}
                                  View Collection{" "}
                                </button>
                              </div>

                              {/* <div className="name_with_status">
                                <span className="online_circle">
                                  <i class="fa-solid fa-circle"></i>
                                </span>
                                <span className="hot_model_name">
                                  {item?.name}
                                </span>
                              </div> */}
                              {/* <div>
                                <span className="hotmodel_info">
                                  {item?.address}
                                </span>
                                <span className="send_tip_text">
                                  send tip
                                </span>
                              </div> */}

                              {/* <div className="pt-2">
                                <button className="sign_actionBtn" onClick={() => handleclick(item?.id)}>
                                  view profile
                                </button>
                              </div> */}
                            </div>
                          </SwiperSlide>
                        </Swiper>

                        <div className="model_card_top_corner_img">
                          <img src={modelCardTopCorner} />
                        </div>

                        <div className="model_card_bottom_corner_img">
                          <img src={modelCardBottomCorner} />
                        </div>

                        {/* <div className="framePic">
                          <img src={framePic} className="" />
                        </div> */}
                      </div>
                    </div>
                  ))}

                <div className="col-md-12 mb-4">
                  <div className="text-center">
                    <button onClick={handleclick} className="sign_actionBtn">
                      View More
                    </button>
                  </div>
                </div>

                <div className="col-md-12 mt-4 mb-4">
                  <div className="models_main_container">
                    <div className="model_text_img_head">
                      {/* <img src={platinumlogo} className="platinumModalImg" /> */}
                      <img src={posticon} className="modelTextImg" />
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="modalTags justify-content-center mb-4">
                    {GetCatlists?.map((item, index) => (
                      <div key={index} className="mb-0 justify-content-center ">
                        <button
                          onClick={() => setcatid(item?.id)}
                          className="sign_actionBtn"
                        >
                          {item?.name}
                        </button>
                      </div>
                    ))}
                  </div>
                </div>

                {filterData &&
                  filterData?.map((item, index) => (
                    <div
                      key={index}
                      className="col-10 col-sm-6 col-lg-3 mx-auto"
                    >
                      <div className="home_page_model_card">
                        <Swiper
                          spaceBetween={30}
                          slidesPerView={1}
                          onSlideChange={() => console.log("slide change")}
                          onSwiper={(swiper) => console.log(swiper)}
                        >
                          <SwiperSlide key={index}>
                            <div
                              className="model_card_img position-relative first_model_card"
                              data-toggle="modal"
                              // data-target=".exampleModal"

                              data-target={
                                item?.is_blur !== true
                                  ? ".exampleModal"
                                  : undefined
                              }
                            >
                              {item?.is_blur == true ? (
                                <div
                                  type="btn"
                                  // onClick={() =>
                                  //   model_listpicbyid(item?.id)
                                  // }
                                  id="lock_img"
                                  className="lock_icon_image"
                                  data-toggle="modal"
                                  data-target=".exampleModalunlock"
                                >
                                  <img src={locked_icon} id="lock_img" />
                                </div>
                              ) : (
                                ""
                              )}
                              {item?.is_blur == true ? (
                                <img
                                  src={
                                    item?.post_image
                                      ? baseurl + item?.post_image
                                      : "dummy"
                                  }
                                  className="img-fluid"
                                  style={{height:"350px",
                                    filter: item?.is_blur
                                      ? "blur(20px)"
                                      : "none" // Adjust blur amount as needed
                                  }}
                                />
                              ) : (
                                <img
                                  src={
                                    item?.post_image
                                      ? baseurl + item?.post_image
                                      : "dummy"
                                  }
                                  className="img-fluid"
                                />
                              )}
                              <span className="edit_icon_img"></span>
                              <span className="share_icon_img"></span>
                              <span className="boost_icon_img"></span>
                            </div>

                            <div className="home_page_model_card_desc ">
                              <div className="model_div">
                                {/* <div className="image_with_text_row">
                                <img className="img-fluid model_img" src={userProfilePic} />
                                <p className="post_name_one"> Brittanyvues </p>
                              </div> */}
                              </div>
                              <div className="description_box">
                                <div className="image_with_text_row_two justify-content-between">
                                  <a className="product_heading" href="#">
                                    {`${item?.post_title?.slice(0, 15)}...`}
                                  </a>
                                  <span className=" gap-4  g-4 d-flex ">
                                    {/* <p className="free_locked_text">
                                      <span className="unlocked_icon">
                                        <i className="fa-solid fa-unlock"></i>
                                      </span>
                                      Free
                                    </p>
                                    <p className="lock_text_clr free_locked_text">
                                      <span className="locked_icon">
                                        <i className="fa-solid fa-lock"></i>
                                      </span>
                                      Locked
                                    </p> */}
                                  </span>
                                </div>

                                <p className="product_description">
                                  {`${item?.post_description?.slice(
                                    0,
                                    15
                                  )}...`}
                                </p>
                              </div>
                              <div className="view_collection_btn_div">
                                <button
                                  className="view_collection_btn"
                                  onClick={() => handlenavigatepost(item?.id)}
                                >
                                  {" "}
                                  View Collection{" "}
                                </button>
                              </div>

                              {/* <div className="name_with_status">
                                <span className="online_circle">
                                  <i class="fa-solid fa-circle"></i>
                                </span>
                                <span className="hot_model_name">
                                  {item?.name}
                                </span>
                              </div> */}
                              {/* <div>
                                <span className="hotmodel_info">
                                  {item?.address}
                                </span>
                                <span className="send_tip_text">
                                  send tip
                                </span>
                              </div> */}

                              {/* <div className="pt-2">
                                <button className="sign_actionBtn" onClick={() => handleclick(item?.id)}>
                                  view profile
                                </button>
                              </div> */}
                            </div>
                          </SwiperSlide>
                        </Swiper>

                        <div className="model_card_top_corner_img">
                          <img src={modelCardTopCorner} />
                        </div>

                        <div className="model_card_bottom_corner_img">
                          <img src={modelCardBottomCorner} />
                        </div>

                        {/* <div className="framePic">
                          <img src={framePic} className="" />
                        </div> */}
                      </div>
                    </div>
                  ))}

                <div className="col-md-12">
                  <div className="text-center">
                    <button onClick={handleclick} className="sign_actionBtn">
                      View More
                    </button>
                  </div>
                </div>

                {isplatinum && (
                  <div className="col-md-12 mt-4">
                    <div className="models_main_container">
                      <div
                        className="model_text_img text-center pb-5"
                        data-aos="fade-up"
                        data-aos-anchor-placement="center-bottom"
                        data-aos-duration="2000"
                      >
                        <div className="model_text_img_head">
                          <img
                            src={platinumlogo}
                            className="platinumModalImg"
                          />
                          <img src={modelText} className="modelTextImg" />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* <div className="col-md-12">
                  <div className="modalTags justify-content-center mb-4">
                    {GetCatlists?.map((item, index) => (
                      <div key={index} className="mb-0 justify-content-center ">
                        <button
                          onClick={() => setpkgcatid(item?.id)}
                          className="sign_actionBtn"
                        >
                          {item?.name}
                        </button>
                      </div>
                    ))}
                  </div>
                </div> */}
                {isplatinum &&
                  pakage?.map((item, index) => (
                    <div
                      key={index}
                      className="col-10 col-sm-6 col-lg-3 mx-auto"
                    >
                      <div className="home_page_model_card">
                        <Swiper
                          spaceBetween={30}
                          slidesPerView={1}
                          onSlideChange={() => console.log("slide change")}
                          onSwiper={(swiper) => console.log(swiper)}
                        >
                          <SwiperSlide key={index}>
                            <div className="model_card_img position-relative">
                              <img
                                src={
                                  item?.profile_pic
                                    ? baseurl + item?.profile_pic
                                    : dummy
                                }
                                className="img-fluid"
                              />
                            </div>

                            <div className="home_page_model_card_desc ">
                              <div className="model_div">
                                {/* <div className="image_with_text_row">
                                <img className="img-fluid model_img" src={userProfilePic} />
                                <p className="post_name_one"> Brittanyvues </p>
                              </div> */}
                              </div>
                              <div className="description_box">
                                <div className="image_with_text_row_two justify-content-between">
                                  <a className="product_heading" href="#">
                                    {`${item?.name?.slice(0, 10)}...`}
                                  </a>
                                  <span className=" gap-4  g-4 d-flex ">
                                    {/* <p className="free_locked_text">
                                      <span className="unlocked_icon">
                                        <i className="fa-solid fa-unlock"></i>
                                      </span>
                                      Free
                                    </p>
                                    <p className="lock_text_clr free_locked_text">
                                      <span className="locked_icon">
                                        <i className="fa-solid fa-lock"></i>
                                      </span>
                                      Locked
                                    </p> */}
                                  </span>
                                </div>

                                <p className="product_description">
                                  {`${item?.bio?.slice(0, 15)}...`}
                                </p>
                              </div>
                              <div className="view_collection_btn_div">
                                <button
                                  className="view_collection_btn"
                                  onClick={() => handlenavigate(item?.id)}
                                >
                                  {" "}
                                  View Collection{" "}
                                </button>
                              </div>

                              {/* <div className="name_with_status">
                                <span className="online_circle">
                                  <i class="fa-solid fa-circle"></i>
                                </span>
                                <span className="hot_model_name">
                                  {item?.name}
                                </span>
                              </div> */}
                              {/* <div>
                                <span className="hotmodel_info">
                                  {item?.address}
                                </span>
                                <span className="send_tip_text">
                                  send tip
                                </span>
                              </div> */}

                              {/* <div className="pt-2">
                                <button className="sign_actionBtn" onClick={() => handleclick(item?.id)}>
                                  view profile
                                </button>
                              </div> */}
                            </div>
                          </SwiperSlide>
                        </Swiper>

                        <div className="model_card_top_corner_img">
                          <img src={modelCardTopCorner} />
                        </div>

                        <div className="model_card_bottom_corner_img">
                          <img src={modelCardBottomCorner} />
                        </div>

                        {/* <div className="framePic">
                          <img src={framePic} className="" />
                        </div> */}
                      </div>
                    </div>
                  ))}
              </div>

              <div className="row py-lg-5 py-3 align-items-center">
                <div className="col-md-12">
                  <div
                    className="how_it_works_text_img text-center"
                    data-aos="fade-up"
                    data-aos-anchor-placement="center-bottom"
                    data-aos-duration="2000"
                  >
                    <img src={howItWorksText} />
                  </div>
                </div>

                <div className="col-sm-12 col-lg-8">
                  <div className="how_it_works_left_part">
                    <div
                      className="how_it_works_left_img position-relative"
                      data-aos="flip-left"
                      data-aos-anchor-placement="center-bottom"
                      data-aos-duration="2000"
                    >
                      <img src={howItWorksImg} className="img-fluid" />
                    </div>

                    <div className="how_it_works_play_icon">
                      <img src={videoPlayIcon} />
                    </div>
                  </div>
                </div>

                <div className="col-sm-10 col-lg-4 mx-auto">
                  <div className="how_it_works_right_part">
                    <div
                      className="step_bk"
                      data-aos="flip-down"
                      data-aos-anchor-placement="center-bottom"
                      data-aos-duration="2000"
                    >
                      <div className="step_main">
                        <p className="step_number m-0">step: 1</p>

                        <p className="how_it_works_right_part_para how_work_p1 m-0">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the
                        </p>

                        <p className="text_with_stroke">step: 1</p>
                      </div>
                    </div>

                    <div className="row pt-2">
                      <div className="col-md-6">
                        <div
                          className="step_main_2"
                          data-aos="flip-right"
                          data-aos-anchor-placement="center-bottom"
                          data-aos-duration="2000"
                        >
                          <p className="step_number m-0">step: 2</p>

                          <p className="how_it_works_right_part_para m-0">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the
                          </p>

                          <p className="text_with_stroke">step: 2</p>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div
                          className="step_main_2"
                          data-aos="flip-left"
                          data-aos-anchor-placement="center-bottom"
                          data-aos-duration="2000"
                        >
                          <p className="step_number m-0">step: 3</p>

                          <p className="how_it_works_right_part_para m-0">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the
                          </p>
                        </div>

                        <p className="text_with_stroke">step: 3</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row pb-md-4 pb-0">
                <div className="col-md-12">
                  <div className="for_clients_main mb-3">
                    <div
                      className="for_client_text_img text-center"
                      data-aos="fade-down"
                      data-aos-anchor-placement="center-bottom"
                      data-aos-duration="2000"
                    >
                      <img src={forClientText} />
                    </div>
                  </div>
                </div>

                <div className="col-sm-12 col-lg-6 mx-auto mb-3">
                  <div
                    className="for_client_left_img"
                    data-aos="fade-up"
                    data-aos-anchor-placement="center-bottom"
                    data-aos-duration="2000"
                  >
                    <img src={forClientImg} className="img-fluid" />
                  </div>
                </div>

                <div className="col-sm-12 col-lg-6 align-self-center">
                  <div
                    className="for_client_right_part"
                    data-aos="fade-left"
                    data-aos-anchor-placement="center-bottom"
                    data-aos-duration="2000"
                  >
                    <p className="for_client_first_para">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industrys
                      standard dummy text ever since
                    </p>

                    <p className="for_client_second_para">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book. It has survived not
                    </p>

                    <p className="for_client_first_para text-white">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </p>

                    <p className="for_client_second_para text-muted">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industrys
                      standard dummy text ever since
                    </p>

                    <p className="for_client_second_para">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </p>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div
                    className="for_models_text text-center mb-3"
                    data-aos="fade-up"
                    data-aos-anchor-placement="center-bottom"
                    data-aos-duration="2000"
                  >
                    <img src={forModelsText} />
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="for_model_main">
                    <div
                      className="for_models_main_img"
                      data-aos="fade-right"
                      data-aos-anchor-placement="center-bottom"
                      data-aos-duration="2000"
                    >
                      <img src={formodelImg01} className="img-fluid" />
                    </div>

                    <div
                      className="for_models_right_content my-auto"
                      data-aos="fade-down"
                      data-aos-anchor-placement="center-bottom"
                      data-aos-duration="2000"
                    >
                      <h4 className="for_models_heading_here">heading here</h4>

                      <p className="for_models_right_para">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s
                      </p>

                      <p className="for_models_right_para">
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries,
                      </p>

                      <p className="for_models_right_para">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s
                      </p>

                      <div class="text-left">
                        <button onClick={handleclick} class="sign_actionBtn">
                          View More
                        </button>
                      </div>
                    </div>

                    <div
                      className="for_model_most_right_img"
                      data-aos="fade-left"
                      data-aos-anchor-placement="center-bottom"
                      data-aos-duration="2000"
                    >
                      <img src={formodelImg02} />
                    </div>
                  </div>
                </div>

                {/* <div className='col-md-6 align-self-center'>
                  <div className='for_models_right_content'>
                    <h4 className='for_models_heading_here'>heading here</h4>
  
                    <p className='for_models_right_para'>
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                    </p>
  
                    <p className='for_models_right_para'>
                      when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,
                    </p>
  
                    <p className='for_models_right_para'>
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                    </p>
  
                    <div class="text-left">
                      <button class="sign_actionBtn">View More</button>
                    </div>
  
                  </div>
                </div> */}
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div
                    className="faq_heading_text_img text-center"
                    data-aos="fade-left"
                    data-aos-anchor-placement="center-bottom"
                    data-aos-duration="2000"
                  >
                    <img src={faqText} />
                  </div>
                </div>

                <div className="col-sm-12 col-lg-6 mx-auto">
                  <div className="accordion" id="accordionExample">
                    {getFaqs?.map((item, index) => (
                      <div className="card" key={index}>
                        <div className="card-header" id={`heading${index}`}>
                          <h2 className="mb-0">
                            <button
                              className="btn faq_button btn-link btn-block text-left"
                              type="button"
                              data-toggle="collapse"
                              data-target={`#collapse${index}`}
                              aria-expanded={index === 0} // Only the first one is expanded by default
                              aria-controls={`collapse${index}`}
                            >
                              {item?.question}
                            </button>
                          </h2>
                        </div>

                        <div
                          id={`collapse${index}`}
                          className={`collapse ${index === 0 ? "show" : ""}`} // Only show the first one by default
                          aria-labelledby={`heading${index}`}
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">{item?.answer}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Modal
            show={modalShow}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <div className="carousel-modal popUp_modal_1">
              <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                  ARE YOU OVER 18?
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p className="text-capitalize">
                  By{" "}
                  <span className="text-lowercase">
                    continuing, you confirm that you are over 18 years old and
                    that you agree to our
                  </span>
                </p>
                <p>
                  <Link to="/">Terms of Use.</Link>
                  {/* <a href="https://anotherwebsite.com">Oops, I’m under 18....</a> */}
                </p>
                <button
                  onClick={() => setModalShow(false)}
                  className="popUp_modal_1-btn paybtn"
                >
                  <span>I am over 18,</span>
                  <h3>LET’S PARTY!</h3>
                </button>

                <p className="mb-0 mt-5 ">
                  <Link to="https://www.google.com">
                    Oops,
                    <span className="text-lowercase">I’m under 18....</span>
                  </Link>
                </p>
              </Modal.Body>
            </div>
          </Modal>
          <div>
            <Footer />
          </div>
        </div>
      )}
    </>
  );
};

export default Home;
