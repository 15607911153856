import React from "react";
import Header from '../../Components/Layout/Header';
// import Footer from '../../Components/Layout/Footer';
import { mainLogo, top, btm } from "../../Asserts/images/index";
import CommonInput from "../../Components/CustomInput";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { ForgetRequest3 } from "../../api";
import { toastAlert } from "../../utils";
import { ALERT_TYPES } from "../../constants";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../../redux/slicers/user";
import CustomInput from "../../Components/CustomInput";
const Forgot_Pass3 = () => {
  //CONST VALS
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const email = localStorage.getItem("email");
  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   const formData = new FormData(event.currentTarget);
  //   let payload = {};
  //   for (let [key, value] of formData.entries()) {
  //     payload[key] = value;
  //   }
  //   console.log(payload);
  //   try {
  //     const response = await ForgetRequest2(payload);

  //     if (response && response?.status === true) {
  //       await new Promise((resolve) => {
  //         // notifys();
  //         setTimeout(resolve, 3000); // Assuming the toast display duration is 3 seconds
  //       });
  //       console.log("response", response?.status);

  //       navigate("/forget-password2");
  //     } else {
  //       // toastAlert(response.statusText, ALERT_TYPES.ERROR);
  //     }
  //   } catch (error) {
  //     console.error("Error in logging in:", error);

  //     toastAlert(error, ALERT_TYPES.ERROR);
  //   }
  // };

  const email = localStorage.getItem("email");
  const code = localStorage.getItem("code");


  console.log("local code" , code)

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    let payload = {};

    for (let [key, value] of formData.entries()) {
      payload[key] = value;
    }

    // Add the email from localStorage to the payload
    if (email) {
      payload.email = email;
    }  
     if (code) {
      payload.otp = code;
    }

 

    try {
      const response = await ForgetRequest3(payload);

      if (response && response?.status === true) {
        await new Promise((resolve) => {
          // notifys();
          toastAlert(response?.message, ALERT_TYPES.SUCCESS);
          setTimeout(resolve, 3000); // Assuming the toast display duration is 3 seconds
        });
        console.log("response", response?.status);

        navigate("/login-page");
      } else {
        // toastAlert(response.statusText, ALERT_TYPES.ERROR);
      }
    } catch (error) {
      console.error("Error in logging in:", error);

      toastAlert("Otp is Wrong", ALERT_TYPES.ERROR);
    }
  };

  return (
    <div>
         <Header />
      <section className="log">
        <div className="black mx-auto">
          <div className="container">
            <div className="gold">
              <img className="img-fluid top" src={top} />
              <div className="row">
                <div className="col-md-12">
                  <div className="imagerow">
                    <img className="img-fluid " src={mainLogo} />
                  </div>
                  <h4 className="sign">Password Recovery</h4>
                  {/* <p className="txt">
                    Please Check Your Email For Verification Code. Your Code is
                    4 digits in Length{" "}
                  </p> */}

                  {/* <form className="login-form " onSubmit={handleSubmit}>
 
                    <label required class="user">
                      {" "}
                      Email{" "}
                    </label>
                    <input
                      class="mail"
                      type="text"
                      placeholder="Enter Your Email "
                      name="email"
                      required
                    />

                    <label class="pass" required>
                      {" "}
                      Password{" "}
                    </label>
                    <input
                      class="pass"
                      type="password"
                      placeholder=" Enter Your Password "
                      name="password"
                      required
                    />

                    <CommonInput
                      inputClass="mail"
                      type="text"
                      placeholder="Enter Your Email "
                      name="email"
                      label="Email"
                      labelClass="user"
                      required
                    />
                    <CommonInput
                      inputClass="pass"
                      type="password"
                      placeholder="Enter Your Password"
                      name="password"
                      label="Password"
                      labelClass="pass"
                      required
                    />
                    <input type="submit" value="Login" />
                  </form> */}

                  <form className="signup-form " onSubmit={handleSubmit}>
                    <span className="otp">
                      {" "}
                      {/* <CommonInput
                        // inputClass="mail"
                        type="text"
                        placeholder="Enter Verification Code*"
                        name="otp"
                        label="Verification Code
"
                        labelClass="user"
                        // onChange={handleChange}
                        required
                      /> */}
                      {/* <CommonInput
                        inputClass="mail"
                        type="text"
                        placeholder="Enter Code "
                        name="otp"
                        label="Verification Code*"
                        labelClass="user"
                        // onChange={handleChange}
                        required
                      /> */}
                      <CommonInput
                        inputClass="pass"
                        type="password"
                        placeholder="Enter new Password"
                        name="password"
                        label="New Password"
                        labelClass="pass"
                        required
                        // onChange={handleChange}
                      />
                      <CommonInput
                        inputClass="pass"
                        type="password"
                        placeholder="Enter confirm Password"
                        name="password_confirmation"
                        label="Confirm Password"
                        labelClass="pass"
                        required
                        // onChange={handleChange}
                      />
                    </span>

                    {/* <CommonInput
                      inputClass="mail"
                      type="text"
                      placeholder="Enter Your Email "
                      name="email"
                      label="Verification Code*
"
                      labelClass="user"
                      // onChange={handleChange}
                      required
                    />
                    otp */}
                    {/* <CommonInput
                      inputClass="pass"
                      type="password"
                      placeholder="Enter Your Password"
                      name="password"
                      label="Password"
                      labelClass="pass"
                      required
                      // onChange={handleChange}
                    /> */}
                    <input type="submit" value="Continue " />
                  </form>

                  <div className="for">
                    {/* <p className="forgot">Forgot Password?</p> */}
                  </div>
                  {/* <div className="divider">
                    {" "}
                    <span className="line"> </span>{" "}
                    <span className="or"> OR </span>{" "}
                    <span className="line1"> </span>
                  </div> */}

                  {/* <div className="create">
                    <button
                      className="account"
                      onClick={() => navigate("/signup-page")}
                    >
                      Create Account
                    </button>
                  </div> */}
                </div>
              </div>
              <img className="img-fluid btm" src={btm} />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Forgot_Pass3;
