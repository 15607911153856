import React, { useEffect, useState, useRef } from "react";
import Header from "../../Components/Layout/Header";
import Footer from "../../Components/Layout/Footer";
import {
  modelprofileview,
  Getmodelpostlist,
  Getmodelpost,
  Deletepost,
  Purchase,
  profileviewbyidmodel,
  UserUnfavouritemodel,
  Group_list
} from "../../api";
import { Swiper, SwiperSlide } from "swiper/react";
import { toastAlert } from "../../utils";
import { ALERT_TYPES } from "../../constants";
import { Link, useSearchParams } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import dummy from "../../Asserts/images/dummy.jpg";
import Loader from "../../Components/loader";
import { Navigation } from "swiper/modules";
// Import Swiper React components

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import {
  modelCardTopCorner,
  modelCardBottomCorner,
  Cancel
} from "../../Asserts/images/index";

import "./style.css";

import Pushers from "../profilePage/pucher";
import Aos from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";

const ModelProfile = () => {
  const [searchParams] = useSearchParams();
  const userId = searchParams.get("user_id");
  console.log("userId", userId);

  const [uselist, setUserlist] = useState([]);

  const messagesEndRef = useRef(null);
  const chatDivRef = useRef(null);

  const scrollToBottom = () => {
    console.log("scrolltobottom");

    if (chatDivRef.current) {
      chatDivRef.current.scrollTop = chatDivRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, []);
  const [getreceverid, setgetreceverid] = useState();
  const user_id = localStorage.getItem("user_id");
  const apiUrl = process.env.REACT_APP_BASE_URL;

  const post_limit = localStorage.getItem("post_limit");
  const [filterid, setfilterid] = useState();

  const [modellistsprofileview, setModelprofileview] = useState({});
  const handleid = (id) => {
    const datafilter = userprofilelist.filter((items) => items.id == id);
    console.log("datafilter", datafilter);
    setfilterid(datafilter);
  };
  // const handleDelete = async (id) => {
  //   // navigate("/");
  //   try {
  //     const response = await Deletepost(id);
  //     console.log("response", response);

  //     if (response?.status == true) {
  //       const data = response?.data;
  //       setTimeout(() => {
  //         setLoading(true);
  //       }, 3000);
  //       setLoading(false);
  //       toastAlert(response?.msg, ALERT_TYPES.SUCCESS);

  //       modelprofileview();
  //       // setModelprofileview(data);
  //     } else if (response?.status == false) {
  //       const data = response?.data;
  //       // setTimeout(() => {
  //       //   setLoading(true);

  //       // }, 3000);
  //       setLoading(false);
  //       toastAlert(response?.msg, ALERT_TYPES.ERROR);

  //       modelprofileview();
  //       // setModelprofileview(data);
  //     } else {
  //       // toastAlert(response.statusText, ALERT_TYPES.ERROR);
  //       console.log("packege ", response.statusText);
  //     }
  //     setprofilebyid(response?.data);
  //     setLoadingdetail(false);
  //   } catch (error) {
  //     console.error("Error in logging in:", error);

  //     // toastAlert(error, ALERT_TYPES.ERROR);
  //   }
  // };

  const handleDelete = async (id) => {
    setLoading(true); // Set loading to true when the API call starts

    try {
      const response = await Deletepost(id);
      console.log("response", response);

      if (response?.status === true) {
        setTimeout(() => {
          setLoading(false); // Set loading to false after 3 seconds
          toastAlert(response?.msg, ALERT_TYPES.SUCCESS);
          modelprofileview();
        }, 3000);
      } else if (response?.status === false) {
        setTimeout(() => {
          setLoading(false); // Set loading to false after 3 seconds
          toastAlert(response?.msg, ALERT_TYPES.ERROR);
          modelprofileview();
        }, 3000);
      } else {
        console.log("package ", response.statusText);
        setLoading(false); // Ensure loading is turned off in case of unexpected response structure
      }

      setprofilebyid(response?.data);
      setLoadingdetail(false);
    } catch (error) {
      console.error("Error in deleting post:", error);
      toastAlert(error.message, ALERT_TYPES.ERROR);
      setLoading(false); // Reset loading state on error
      setLoadingdetail(false);
    }
  };

  const dispatch = useDispatch();

  const [fileName, setFileName] = useState("");

  const plane_limit = localStorage.getItem("post_limit");

  const [formData, setFormData] = useState([]);
  const [userdata, setUserdata] = useState({
    name: ""
  });
  const [userprofilelist, setprofilelist] = useState([]);
  const baseurl = `${process.env.REACT_APP_BASE_URL}/public/`;

  const [message, setMessage] = useState({
    message: "",
    image: null
  });
  const modelprofile = async () => {
    try {
      const response = await modelprofileview();
      console.log("response1", response);

      setUserdata(response?.data);

      // setTimeout(() => {
      //   setLoading(true);

      // }, 3000);

      setLoading(false);
    } catch (error) {
      console.error("Error in logging in:", error);
    }
  };
  const getmodelprofile = async () => {
    try {
      const response = await Getmodelpost();
      console.log("response", response);

      // setTimeout(() => {
      //   setLoading(true);

      // }, 3000);

      setprofilelist(response?.data);
    } catch (error) {
      console.error("Error in logging in:", error);

      // toastAlert(error, ALERT_TYPES.ERROR);
    }
  };

  const modelprofilelist = async () => {
    try {
      const response = await Getmodelpostlist(id);
      console.log("response", response);

      setprofilelist(response?.data);
    } catch (error) {
      console.error("Error in logging in:", error);

      // toastAlert(error, ALERT_TYPES.ERROR);
    }
  };

  useEffect(() => {
    modelprofilelist();
  }, []);

  useEffect(() => {
    Aos.init();
    // modelprofilelist()
    modelprofile();
    getmodelprofile();
  }, []);
  const navigate = useNavigate();
  const handleroute = () => {
    navigate("/add-post-page");
  };
  const [hearts, setHeart] = useState(false);

  // const handleHeart = () => {
  //   setHeart(!hearts);
  // };

  const handleHeart = async (id) => {
    try {
      const response = await UserUnfavouritemodel(id);
      console.log("response", response);

      if (response?.status == true) {
        const data = response?.data;
        setHeart(data);

        modelprofile();
      } else {
        // toastAlert(response.statusText, ALERT_TYPES.ERROR);
        console.log("packege ", response.statusText);
      }
      setHeart(response?.data);
    } catch (error) {
      console.error("Error in logging in:", error);

      // toastAlert(error, ALERT_TYPES.ERROR);
    }
  };

  const [isLoading, setLoading] = useState(false);
  const [isLoadingdetail, setLoadingdetail] = useState(true);

  const [modellist, setmodellisting] = useState(true);
  const [followers, setfollowers] = useState(false);

  const [follow, setFollowing] = useState(false);
  const [sendmessages, setSendmessage] = useState(false);
  const [transactions, setTransactions] = useState(false);
  const [Recivedtips, setRecivedtips] = useState(false);
  const [profilebyid, setprofilebyid] = useState({});
  const [givestip, setGivestip] = useState(false);
  const following = () => {
    setFollowing(!follow);
    setProfilepreview(false);
    setmodellisting(false);
    setSendmessage(false);
    setTransactions(false);
    setGivestip(false);
    setfollowers(false);
    setRecivedtips(false);
  };
  const sendmessage = () => {
    scrollToBottom();
    setSendmessage(true);
    setFollowing(false);
    setmodellisting(false);
    setTransactions(false);
    setGivestip(false);
    setRecivedtips(false);
    setfollowers(false);
    setProfilepreview(false);
  };

  const transaction = () => {
    setTransactions(!transactions);
    setRecivedtips(false);
    setFollowing(false);
    setSendmessage(false);
    setmodellisting(false);
    setfollowers(false);
    setGivestip(false);
    setProfilepreview(false);
  };
  const showprofile = () => {
    setmodellisting(true);
    setTransactions(false);
    setFollowing(false);
    setSendmessage(false);
    setfollowers(false);
    setGivestip(false);
    setProfilepreview(false);
  };
  const givetip = () => {
    setTransactions(false);
    setFollowing(false);
    setSendmessage(false);
    setmodellisting(false);
    setGivestip(!givestip);
  };

  const updateprofile = () => {
    navigate("/update-profile");
  };

  const [isChecked, setIsChecked] = useState(null);

  const handleRadioChange = (id) => {
    // setIsChecked(!isChecked);
    if (id === isChecked) {
      setIsChecked(null);
      alert("hello");
      return;
    }
  };

  const recived_tips = () => {
    setTransactions(false);
    setRecivedtips(!Recivedtips);
    setFollowing(false);
    setSendmessage(false);
    setmodellisting(false);
    setfollowers(false);
    setGivestip(false);
    setProfilepreview(false);
  };

  const follower = () => {
    setTransactions(false);
    setfollowers(true);
    setFollowing(false);
    setProfilepreview(false);
    setSendmessage(false);
    setmodellisting(false);
    setGivestip(false);
  };

  console.log("givestip", givestip);

  const model_listpicbyid = async (id) => {
    try {
      const response = await profileviewbyidmodel(id);
      console.log("response", response);

      if (response?.status == true) {
        const data = response?.data;
        console.log("data", data);
        setModelprofileview(data);
      } else {
        // toastAlert(response.statusText, ALERT_TYPES.ERROR);
        console.log("packege ", response.statusText);
      }
      setprofilebyid(response?.data);
      setLoadingdetail(false);
    } catch (error) {
      console.error("Error in logging in:", error);

      // toastAlert(error, ALERT_TYPES.ERROR);
    }
  };

  const [showinput, setShowinput] = useState(false);

  // const [inputValue, setInputValue] = useState(userdata.name);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handletext = () => {
    setShowinput(!showinput);
  };

  const handlefile = (event) => {
    const file = event.target.files[0];

    if (file) {
      setUserdata((prevData) => ({
        ...prevData,
        image: file
      }));

      const logoutData = localStorage.getItem("userToken");
      // document.querySelector(".loaderBox")?.classList?.remove("d-none");

      const formDataMethod = new FormData();
      formDataMethod.append("image", file);

      fetch(`${apiUrl}/public/api/user/profile-add-edit`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${logoutData}`
        },
        body: formDataMethod
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          console.log("datas", data.data);
          // document.querySelector('.loaderBox').classList.add("d-none");

          if (data?.status === true) {
            // setLoading(true)
            modelprofile();
          } else {
            // Handle other responses
          }
        })
        .catch((error) => {
          // document.querySelector(".loaderBox").classList.add("d-none");
          console.error("Error:", error);
        });
    }
  };

  const handletexts = (event) => {
    const value = event.target.value;

    if (value) {
      setUserdata((prevData) => ({
        ...prevData,
        name: value
      }));

      const logoutData = localStorage.getItem("userToken");
      // document.querySelector(".loaderBox")?.classList?.remove("d-none");

      const formDataMethod = new FormData();
      formDataMethod.append("name", value);

      fetch(`${apiUrl}/public/api/user/profile-add-edit`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${logoutData}`
        },
        body: formDataMethod
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          console.log("datas", data.data);
          // document.querySelector('.loaderBox').classList.add("d-none");

          if (data?.status === true) {
            // setLoading(true)
            modelprofile();
          } else {
            // Handle other responses
          }
        })
        .catch((error) => {
          // document.querySelector(".loaderBox").classList.add("d-none");
          console.error("Error:", error);
        });
    }
  };

  const stylesForSidebar = {
    // marginTop: "0px",
    // height: "100%",
    // borderRadius: "20px",
    // width: "80%",
    // padding: "34px 0px 12px",
    // maxHeight: "300px",
    // objectFit: "contain",
    // marginTop: "0px",
    height: "242px",
    "border-radius": "20px",
    width: "192px",
    padding: "12px 0px",
    maxHeight: "300px"
    // "objectFit": "contain",
  };

  const channel = Pushers.subscribe("mike-smith");

  const pusherhit = () => {
    // Event handler for new messages
    channel.bind("message-event", function (data) {
      const chatData = data?.chatData;
      const decodedMessage = {
        id: chatData.id,
        sender_id: chatData.sender_id,
        reciever_id: chatData.reciever_id,
        message: chatData.message,
        filePath: chatData.filePath,
        created_at: new Date(chatData.created_at),
        updated_at: new Date(chatData.updated_at)
      };

      user_list();
      setFormData((prevMessages) => {
        // Check if the message already exists
        const messageExists = prevMessages.some(
          (message) => message?.id === decodedMessage?.id
        );
        if (!messageExists) {
          return [...prevMessages, decodedMessage];
        }
        return prevMessages;
      });
    });

    // Clean up WebSocket subscription
    return () => {
      channel.unbind("message-event");
      channel.unsubscribe();
    };
  };
  useEffect(() => {
    pusherhit();
  }, []);

  const formatDate = (timestamp) => {
    const now = new Date();
    const date = new Date(timestamp);

    const diffMs = now - date;

    // If the difference is less than a minute (60000 milliseconds), return "just now"
    if (diffMs < 60000) {
      return "just now";
    }

    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];

    const day = date.getDate();
    const month = months[date.getMonth()];
    const hours = date.getHours();
    const minutes = date.getMinutes();

    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12; // Convert to 12-hour format

    return `${day} ${month} ${formattedHours}:${formattedMinutes} ${ampm}`;
  };

  const formattedDate = formatDate("2024-06-28T09:32:17.000000Z");

  const [progress, setProgress] = useState(0);

  const [isLoadingimg, setIsLoading] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith("image/")) {
      setFileName(file.name);

      setMessage((prevMessages) => ({
        ...prevMessages,
        image: file
      }));
      setIsLoading(true); // Start loading

      const xhr = new XMLHttpRequest();
      xhr.upload.addEventListener("progress", (e) => {
        if (e.lengthComputable) {
          const uploadProgress = (e.loaded / e.total) * 100;
          setProgress(uploadProgress);
        }
      });
      xhr.upload.addEventListener("load", () => {
        setProgress(100);
        setTimeout(() => {
          setIsLoading(false);
        }, 5000);
      });

      xhr.upload.addEventListener("error", () => {
        console.error("Error uploading file");
        setProgress(0); // Reset progress on error
        setIsLoading(false); // Stop loading on error
      });

      xhr.open("POST", "/your-upload-endpoint");
      const formData = new FormData();
      formData?.append("file", file);
      xhr.send(formData);
    }
  };

  const { id } = useParams();

  const sendMessage = async (event) => {
    event.preventDefault();

    // toast.success("Post Add Successfully");

    const formDataMethod = new FormData();
    // formDataMethod.append('reciever_id', id == getreceverid ? id : getreceverid);
    formDataMethod.append("receiver_id", id != null ? id : getreceverid);

    // id ? id : getreceverid
    // setFormData((prevMessages) => [...prevMessages, message?.message])
    const currentDateTime = new Date().toISOString();
    const lastMessage = formData[formData?.length - 1];
    const newId = lastMessage ? lastMessage?.id + 1 : 1;

    const optimisticMessage = {
      id: newId,
      reciever_id: id,
      message: message?.message,
      created_at: currentDateTime,
      sender_id: user_id
    };
    // setFormData(prevMessages => [...prevMessages, optimisticMessage]);

    // formDataMethod.append('id', message?.image)
    formDataMethod.append("image", message?.image);
    formDataMethod.append("message", message?.message);
    setMessage("");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/public/api/sendchat`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("userToken")}`
          },
          body: formDataMethod
        }
      );

      const data = await response.json();
      console.log("data?.status", data?.status);
      // const newMessage = data?.data
      if (data?.status == true) {
        scrollToBottom();
        setFormData((prevMessages) =>
          prevMessages.map((msg) =>
            msg.id === optimisticMessage?.id ? data?.data : msg
          )
        );

        // setFormData(prevMessages => prevMessages.map(msg => msg.id === optimisticMessage?.id ? newMessage : msg));

        setMessage({
          message: "",
          image: null
        });
        setFileName("");
        // ProductData()
        // toast.success("Message sent successfully");
      } else {
        setMessage("");
        // toast.error(data?.message || "Failed to send message");
      }
    } catch (error) {
      setFormData((prevMessages) =>
        prevMessages.filter((msg) => msg.id !== optimisticMessage?.id)
      );

      console.error("Error in sending message:", error);
      // toast.error(error.message || "An error occurred");
    }
  };

  const handlechanges = (e) => {
    const { name, value } = e.target;
    setMessage((prevdata) => ({
      ...prevdata,
      [name]: value
    }));
  };

  const ProductData = async (ids = userId) => {
    console.log("ids", ids);
    setgetreceverid(ids);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/public/api/loadchat/${ids}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("userToken")}`
          }
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      if (data?.status == true) {
        scrollToBottom();
      }
      console.log(data);

      // Assuming setFormData is a function to set the state
      setFormData(data?.data);
    } catch (error) {
      console.error("Error fetching product data:", error);
    }

    scrollToBottom();
  };
  const [receiverId, setReceiverId] = useState(null);

  const [purchasedata, setPurchaseData] = useState([]);

  const packagePurchase = async () => {
    try {
      const response = await Purchase();
      console.log("response", response);

      if (response?.status == true) {
        const data = response?.data;
        console.log("data", data);
        setPurchaseData(data);
        setLoading(false);
      } else {
        // toastAlert(response.statusText, ALERT_TYPES.ERROR);
        setLoading(false);
        console.log("packege ", response.statusText);
      }
      // setModelprofileview(response?.data);
    } catch (error) {
      console.error("Error in logging in:", error);

      // toastAlert(error, ALERT_TYPES.ERROR);
    }
  };

  useEffect(() => {
    ProductData();
    packagePurchase();
  }, []);

  const user_list = async () => {
    try {
      const response = await Group_list();

      if (response?.status == true) {
        scrollToBottom();

        const data = response?.data;
        console.log("datadata", data);

        const firstUserId = userId || data[0]?.id;
        setReceiverId(firstUserId);
        setUserlist(data);
        setuserProfile(data[0]);
        ProductData(firstUserId);
      } else {
        // toastAlert(response.statusText, ALERT_TYPES.ERROR);
        console.log("packege ", response.statusText);
      }
      setprofilebyid(response?.data);
    } catch (error) {
      console.error("Error in logging in:", error);

      // toastAlert(error, ALERT_TYPES.ERROR);
    }
  };

  useEffect(() => {
    user_list();
  }, []);

  const [inputValue, setInputValue] = useState("");
  const handleChange = (e) => {
    setInputValue(e.target.value);
  };
  const filteredList = uselist.filter((item) =>
    item?.name?.toLowerCase().includes(inputValue.toLowerCase())
  );

  const [useProfile, setuserProfile] = useState({});

  const [profilepreview, setProfilepreview] = useState(false);

  const HandleProfile = () => {
    setProfilepreview(true);

    setTransactions(false);
    setfollowers(false);
    setFollowing(false);

    setSendmessage(false);
    setmodellisting(false);
    setGivestip(false);
  };

  console.log("useProfile", useProfile);
  useEffect(() => {
    sendmessage();
    scrollToBottom();
  }, [formData, userId]);

  return (
    <div>
      <div>
        <Header />
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="model_profile_section_main">
          {userdata ? (
            <div className="container">
              <div className="row">
                <div className="col-md-3">
                  <div className="profile_left_part">
                    <div className="user_profile_main position-relative">
                      {/* <div className="user_profile_bk_img">
                    <img src={userProfilePicBackground} className="img-fluid" />
                  </div> */}

                      <div className="user_profile_info text-center  ">
                        <div
                          type="button"
                          onClick={showprofile}
                          className="user_profile_picture"
                          data-aos="flip-left"
                          data-aos-anchor-placement="center-bottom"
                          data-aos-duration="3000"
                        >
                          {/* <img src={(baseurl + userdata?.profile_pic) && (modelImg01)} style={stylesForSidebar} /> */}
                          <img
                            onClick={updateprofile}
                            // src={
                            //   userdata?.profile_pic instanceof File
                            //     ? URL.createObjectURL(userdata?.profile_pic)
                            //     : baseurl + userdata?.profile_pic
                            // }
                            src={
                              userdata?.profile_pic
                                ? baseurl + userdata?.profile_pic
                                : dummy
                            }
                            style={stylesForSidebar}
                          />
                          <div
                            className="profile_edit_icon"
                            type="button"
                            onClick={updateprofile}
                          >
                            {" "}
                            {/* <input
                            type="file"
                            className="edit_icon_input"
                            name="image"
                            // onChange={handlefile}
 
                          />{" "} */}
                            {/* <button onClick={givetip}> */}
                            <i
                              type="button"
                              onClick={updateprofile}
                              class="fa-regular fa-pen-to-square "
                            ></i>
                            {/* </button> */}
                            {/* <button
                            data-toggle="modal"
                            data-target=".exampleModaltip"
                            className="followers_numbers mb-3"
                          >
                            {" "}
                          <i type="button" onClick={givetip} class="fa-regular fa-pen-to-square "></i> 
                          </button> */}
                          </div>
                        </div>

                        <div className="user_info">
                          <div
                            type="button"
                            onClick={showprofile}
                            className="name_with_status pt-3"
                            data-aos="fade-right"
                            data-aos-anchor-placement="center-bottom"
                            data-aos-duration="1000"
                          >
                            <span className="online_circle">
                              <i class="fa-solid fa-circle"></i>
                            </span>

                            {/* {showinput == true ? (
                            <span>
                              <input
                                type="text"
                                className="edit_icon_input"
                                name="name"
                                // value={userdata?.name}
                                onChange={handletexts}
                              />
                              <span
                                className="checkicon "
                                type="button"
                                onClick={handletext}
                              >
                                <i class="fa-solid fa-check"></i>
                              </span>
                            </span>
                          ) : (
                            <span className="hot_model_name">
                              {userdata?.name || "HOTMODEL1234"}
                            </span>
                          )} */}
                            <span className="hot_model_name">
                              {userdata?.name || "HOTMODEL1234"}
                            </span>
                            {/* {!showinput && (
                            <div
                              className="name-edit-icon "
                              type="button"
                              onClick={handletext}
                            >
                              <i class="fa-regular fa-pen-to-square "></i>
                            </div>
                          )} */}
                          </div>

                          <div
                            type="button"
                            onClick={showprofile}
                            className="mb-3"
                            data-aos="fade-right"
                            data-aos-anchor-placement="center-bottom"
                            data-aos-duration="2000"
                          >
                            <span className="user_access">
                              {userdata?.email || "HOTMODEL1234@gmail.com"}
                            </span>
                          </div>

                          <div className="user_about_icons">
                            <span className="facebook_icon">
                              <Link to={userdata?.facebook_link}>
                                {/* <FontAwesomeIcon icon="fa-brands fa-facebook-f" /> */}
                                <i class="fa-brands fa-facebook-f"></i>
                              </Link>
                            </span>

                            <span className="facebook_icon">
                              {/* <FontAwesomeIcon icon="fa-brands fa-square-instagram" /> */}
                              <Link to={userdata?.instagram_link}>
                                <i class="fa-brands fa-instagram"></i>
                              </Link>
                            </span>

                            <span className="facebook_icon">
                              <Link to={userdata?.youtube_link}>
                                {/* <FontAwesomeIcon icon="fa-brands fa-square-instagram" /> */}
                                <i class="fa-brands fa-youtube"></i>
                              </Link>
                            </span>

                            <span className="facebook_icon">
                              <Link to={userdata?.twitter_link}>
                                {/* <FontAwesomeIcon icon="fa-brands fa-square-instagram" /> */}
                                <i class="fa-brands fa-twitter"></i>
                              </Link>
                            </span>
                          </div>
                        </div>

                        <div
                          className="followers_div "
                          data-aos="fade-left"
                          data-aos-anchor-placement="center-bottom"
                          data-aos-duration="3000"
                        >
                          {/* <span className="followers_title">followers</span> */}
                          <div
                            data-aos="fade-right"
                            data-aos-anchor-placement="center-bottom"
                            data-aos-duration="3000"
                          >
                            <button
                              className=" followers_numbers text-center mb-2"
                              onClick={HandleProfile}
                            >
                              {" "}
                              View Profile
                            </button>
                          </div>
                          {/* <span className="followers_number">
                            <span className="no_of_follows">
                           
                            </span>
                          </span> */}
                        </div>

                        <div
                          className="followers_div "
                          data-aos="fade-left"
                          data-aos-anchor-placement="center-bottom"
                          data-aos-duration="3000"
                        >
                          {/* <span className="followers_title">followers</span> */}
                          <div
                            data-aos="fade-right"
                            data-aos-anchor-placement="center-bottom"
                            data-aos-duration="3000"
                          >
                            <button
                              className=" followers_numbers text-center mb-2"
                              onClick={follower}
                            >
                              {" "}
                              Fans
                            </button>
                          </div>
                          <span className="followers_number mb-2">
                            <span className="no_of_follows">
                              {userdata?.follower || 0}
                            </span>
                          </span>
                        </div>

                        <div
                          className="followers_div"
                          data-aos="fade-right"
                          data-aos-anchor-placement="center-bottom"
                          data-aos-duration="3000"
                        >
                          {/* <span className="followers_title">following</span> */}
                          <div
                            data-aos="fade-right"
                            data-aos-anchor-placement="center-bottom"
                            data-aos-duration="3000"
                          >
                            <button
                              className=" followers_numbers text-center  "
                              onClick={following}
                            >
                              {" "}
                              Following
                            </button>
                          </div>
                        </div>

                        <div className="d-flex justify-content-between align-items-center pt-2 sec-rqst-btns">
                          <button
                            className="followers_numbers text-center text-capitalize"
                            data-aos="fade-left"
                            data-aos-anchor-placement="center-bottom"
                            data-aos-duration="3000"
                            onClick={sendmessage}
                          >
                            Receive message
                          </button>
                          <button
                            className="followers_numbers text-center text-capitalize"
                            data-aos="fade-left"
                            data-aos-anchor-placement="center-bottom"
                            data-aos-duration="3000"
                            onClick={transaction}
                          >
                            Transaction
                          </button>
                        </div>

                        {/* <div
                          data-aos="fade-right"
                          data-aos-anchor-placement="center-bottom"
                          data-aos-duration="3000"
                        > 
                          <button
                            className="followers_numbers text-center text-capitalize"
                            onClick={recived_tips}
                          >
                            {" "}
                            Received Tips
                          </button>
                        </div> */}

                        <div
                          data-aos="fade-right"
                          data-aos-anchor-placement="center-bottom"
                          data-aos-duration="3000"
                        ></div>
                      </div>

                      <div class="model_card_top_corner_img">
                        <img src={modelCardTopCorner} />
                      </div>

                      <div class="model_card_bottom_corner_img">
                        <img src={modelCardBottomCorner} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-9">
                  <div className="feet_container_main">
                    {modellist && (
                      <div className="row  ">
                        {userprofilelist?.map((items, index) => (
                          <div
                            className="col-sm-6 col-lg-4"
                            onClick={() => handleid(items?.id)}
                          >
                            <div className="first_model_card">
                              <Swiper
                                spaceBetween={30}
                                slidesPerView={1}
                                onSlideChange={() =>
                                  console.log("slide change")
                                }
                                onSwiper={(swiper) => console.log(swiper)}
                              >
                                {items?.post_data?.map((data) => (
                                  <SwiperSlide
                                    onClick={() => model_listpicbyid(items?.id)}
                                  >
                                    <div
                                      className="model_card_img position-relative first_model_card"
                                      data-toggle="modal"
                                      data-target=".exampleModal"
                                    >
                                      <img
                                        src={
                                          data?.file
                                            ? baseurl + data.file
                                            : "dummy"
                                        }
                                        className="img-fluid"
                                      />

                                      <span className="edit_icon_img"></span>

                                      <span className="share_icon_img"></span>

                                      <span className="boost_icon_img"></span>
                                    </div>
                                  </SwiperSlide>
                                ))}
                              </Swiper>

                              <div className="model_card_top_corner_img">
                                <img src={modelCardTopCorner} />
                              </div>

                              <div
                                className="model_card_bottom_corner_img"
                                id=""
                              >
                                <img src={modelCardBottomCorner} />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}

                    {follow && (
                      <div className="row tabs_box">
                        <div className="col-md-12">
                          <h3
                            className="following_heading"
                            data-aos="fade-right"
                            data-aos-anchor-placement="center-bottom"
                            data-aos-duration="3000"
                          >
                            Following
                          </h3>
                          <div className="divider_row"></div>
                        </div>
                        {userdata?.user_following?.map((data) => (
                          <div
                            className="col-lg-3 col-md-4 col-sm-12 pt-4"
                            data-aos="fade-up"
                            data-aos-duration="3000"
                          >
                            <div className="follow_img_div">
                              <Link
                                to={`/profile-page/${data?.friend_detail?.id}`}
                              >
                                <img
                                  className="img-fluid follow_img"
                                  src={
                                    data?.friend_detail?.profile_pic
                                      ? baseurl +
                                        data?.friend_detail?.profile_pic
                                      : dummy
                                  }
                                  alt="Profile"
                                />
                              </Link>{" "}
                              <p className="image_text">
                                {data?.friend_detail?.name}
                              </p>
                              <div className="locked_div">
                                {/* <p className="free_text">
                                  <span className="icon_unlock">
                                    <i className="fa-solid fa-unlock"></i>
                                  </span>
                                  Free
                                </p>
                                <p className="lock_text">
                                  <span className="icon_lock">
                                    <i className="fa-solid fa-lock"></i>
                                  </span>
                                  Locked
                                </p> */}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}

                    {followers && (
                      <div className="row tabs_box">
                        <div className="col-md-12">
                          <h3
                            className="following_heading"
                            data-aos="fade-right"
                            data-aos-anchor-placement="center-bottom"
                            data-aos-duration="3000"
                          >
                            Followers
                          </h3>
                          <div className="divider_row"></div>
                        </div>

                        {userdata?.user_follower?.map((data) => (
                          <div
                            className="col-lg-3 col-md-4 col-sm-12 pt-4"
                            data-aos="fade-up"
                            data-aos-duration="3000"
                          >
                            <div className="follow_img_div">
                              {console.log(
                                "userdata?.user_follower?",
                                data?.user_detail.name
                              )}
                              <Link
                                to={`/profile-page/${data?.user_detail?.id}`}
                              >
                                <img
                                  className="img-fluid follow_img"
                                  src={
                                    data?.user_detail?.profile_pic
                                      ? baseurl + data?.user_detail?.profile_pic
                                      : dummy
                                  }
                                  alt="Profile"
                                />
                              </Link>{" "}
                              <p className="image_text">
                                {data?.user_detail?.name}
                              </p>
                              <div className="locked_div">
                                {/* <p className="free_text">
                                  <span className="icon_unlock">
                                    <i className="fa-solid fa-unlock"></i>
                                  </span>
                                  Free
                                </p>
                                <p className="lock_text">
                                  <span className="icon_lock">
                                    <i className="fa-solid fa-lock"></i>
                                  </span>
                                  Locked
                                </p> */}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}

                    {sendmessages &&
                      (formData?.length <= 0 ? (
                        <h1 className=" inbox_message "> No Message Found</h1>
                      ) : (
                        <div className="tabs_box box_height">
                          <div className="row">
                            <div className="col-md-12 ">
                              <h3
                                className="inbox_heading"
                                data-aos="fade-right"
                                data-aos-anchor-placement="center-bottom"
                                data-aos-duration="3000"
                              >
                                Inbox
                              </h3>
                              <div className="divider_row"></div>
                            </div>
                          </div>
                          {/* No Message Found */}
                          <div className="row inbox_container">
                            <div className="col-lg-4 col-sm-12 right_divider ">
                              <div className="example example_one">
                                <div className="example example_one">
                                  <input
                                    onChange={handleChange}
                                    type="text"
                                    placeholder="Search Message"
                                    name="name"
                                    value={inputValue}
                                  />
                                </div>
                              </div>
                              {/* <div className="profile_div">
                            <div> <img className="img-fluid profile_img" src={userProfilePic} /></div>
                            <div>
                              <p className="profile_name"> Brittanyvues <sup className="profile_message_date"> 29 May 07:55 AM </sup> </p>
                              <p className="message_text">I am</p>
                            </div>
                          </div> */}
                              {filteredList?.map((data) => (
                                <div
                                  className="profile_div mt-2"
                                  id={
                                    data?.id == getreceverid
                                      ? "userprofilelist"
                                      : ""
                                  }
                                >
                                  <div>
                                    {" "}
                                    <img
                                      className="img-fluid profile_img  "
                                      src={
                                        data?.profile_pic
                                          ? baseurl + data?.profile_pic
                                          : dummy
                                      }
                                    />
                                  </div>
                                  <div
                                    onClick={() => (
                                      navigate(
                                        `/model-profile-page?user_id=${data?.id}`
                                      ),
                                      ProductData(data?.id),
                                      setuserProfile(data)
                                    )}
                                    type="button"
                                  >
                                    {/*  <sup className="profile_message_date"> 29 May 07:55 AM </sup>  */}
                                    <p className="profile_name">
                                      {" "}
                                      {data?.name}{" "}
                                    </p>
                                    <p className="message_text">
                                      {/* {data?.last_message?.message} */}
                                      {`${data.last_message?.message?.slice(
                                        0,
                                        10
                                      )}...`}
                                    </p>
                                  </div>
                                </div>
                              ))}
                            </div>

                            <div className="col-lg-8 col-sm-12 p-0 ">
                              <div className="inbox_header_row">
                                {/* <div className="profile_div pl-3">
                              <div> <img className="img-fluid profile_img" src={userProfilePic} /></div>

                              <div>
                                <p className="profile_name"> Brittanyvues </p>

                              </div>
                            </div> */}
                                <div className="profile_div pl-3">
                                  <div>
                                    {" "}
                                    <img
                                      className="img-fluid profile_img"
                                      src={
                                        useProfile?.profile_pic
                                          ? baseurl + useProfile?.profile_pic
                                          : dummy
                                      }
                                    />
                                  </div>

                                  <div>
                                    <p className="profile_name">
                                      {" "}
                                      {useProfile?.name}{" "}
                                    </p>
                                  </div>
                                </div>

                                <div className="custom_dropdown_div">
                                  <div class="dropdown">
                                    <button
                                      class=" custom_btn_secondary"
                                      type="button"
                                      data-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <i class="fa-solid fa-ellipsis"></i>
                                    </button>
                                    <div class="dropdown-menu custom_dropdown-menu">
                                      <Link
                                        class="dropdown-item custom_dropdown_item"
                                        to="/model-profile-page"
                                      >
                                        View Profile
                                      </Link>
                                      {/* <a
                                    class="dropdown-item custom_dropdown_item"
                                    href="#"
                                  >
                                    Delete Chat
                                  </a> */}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="divider_row"></div>

                              <div className="main_chat_div" ref={chatDivRef}>
                                {formData &&
                                  formData
                                    // .filter(data => data?.sender_id == id || data?.reciever_id == id)
                                    .filter(
                                      (data) =>
                                        data?.sender_id == user_id ||
                                        data?.reciever_id == user_id ||
                                        data?.reciever_id == getreceverid
                                    )
                                    .map((data, index) => {
                                      return (
                                        <div
                                          key={index}
                                          className={
                                            data?.sender_id == user_id
                                              ? "chat_box"
                                              : "chat_box_reply"
                                          }
                                        >
                                          <div
                                            ref={
                                              index === formData?.length - 1
                                                ? messagesEndRef
                                                : null
                                            }
                                          />
                                          {data?.filePath && (
                                            // <img
                                            //   src={baseurl + data?.filePath}
                                            //   className="imagemessage img-fluid mb-1"
                                            // />
                                            <img
                                              src={
                                                data?.filePath
                                                  ? baseurl + data?.filePath
                                                  : dummy
                                              }
                                              className="imagemessage img-fluid mb-1"
                                              style={{
                                                height: "auto",
                                                maxHeight: "500px",
                                                width: "auto",
                                                maxWidth: "100%"
                                              }}
                                            />
                                          )}
                                          <br />
                                          {data?.message && (
                                            <p
                                              className={
                                                data?.sender_id == user_id
                                                  ? "message_para"
                                                  : data?.sender_id !=
                                                      user_id && data.is_request
                                                  ? "message_para_request"
                                                  : "message_para_reply"
                                              }
                                            >
                                              {data?.sender_id != user_id &&
                                              data.is_request ? (
                                                <span className="request_text">
                                                  {" "}
                                                  Request :{" "}
                                                </span>
                                              ) : (
                                                ""
                                              )}
                                              {data?.message}
                                            </p>
                                          )}
                                          <p className="message_date">
                                            {formatDate(data?.created_at)}
                                          </p>
                                        </div>
                                      );
                                    })}
                              </div>

                              <div className="message_sent_box">
                                {/* {progress > 0 && progress < 100 && ( */}

                                {isLoadingimg == true ? (
                                  <progress
                                    id="progressBar"
                                    value={progress}
                                    max="100"
                                    class="blue-progress-bar"
                                    style={{ width: "140px", height: "9px" }}
                                  ></progress>
                                ) : (
                                  <p className="message_file">{fileName}</p>
                                )}
                                {/* )} */}
                                {/* {progress === 100 && <p className="message_file">{fileName}</p>} */}
                                {/* <div>{progress > 0 && progress < 100 && `${Math.round(progress)}%`}</div> */}
                                <div className="main_btn_input_div">
                                  <input
                                    type="file"
                                    id="inputFile"
                                    name="image"
                                    onChange={handleFileChange}
                                    style={{ display: "none" }}
                                  />
                                  <label htmlFor="inputFile">
                                    <i className="fa-regular fa-images"></i>
                                  </label>
                                  <input
                                    type="text"
                                    name="message"
                                    onChange={handlechanges}
                                    className="message_type_box"
                                    value={message?.message}
                                    placeholder="Write Text"
                                    id="name"
                                    required
                                  />
                                  <button
                                    disabled={message.message == ""}
                                    onClick={sendMessage}
                                    className="message_type_box_icon"
                                  >
                                    <i
                                      className=" fa fa-paper-plane"
                                      aria-hidden="true"
                                      id="sendicon"
                                    ></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}

                    {profilepreview && (
                      <div className="tabs_box box_height">
                        <div className="row">
                          <div className="col-md-12 ">
                            <h3
                              className="inbox_heading"
                              data-aos="fade-right"
                              data-aos-anchor-placement="center-bottom"
                              data-aos-duration="3000"
                            >
                              Profile Preview
                            </h3>
                            <div className="divider_row mb-4"></div>
                          </div>
                        </div>

                        <div className="row  image_profile">
                          <div className="col-lg-12 mb-2">
                            {/* <div
                              className="user_profile_picture mt-4"
                              onClick={showprofile}
                              data-aos="flip-left"
                              data-aos-anchor-placement="center-bottom"
                              data-aos-duration="3000"
                              role="button"
                            >
                              <div className="image_wrapper">
                                <img
                                  onClick={updateprofile}
                                  src={
                                    userdata?.profile_pic_request !== 0
                                      ? userdata?.profile_pic
                                        ? baseurl + userdata?.profile_pic
                                        : dummy
                                      : userdata?.old_profile_pic
                                      ? baseurl + userdata?.old_profile_pic
                                      : dummy
                                  }
                                  alt="User Profile"
                                  className="profile_image"
                                />
                              </div>

                              <div
                                className="profile_edit_icon"
                                onClick={updateprofile}
                                role="button"
                              >
                                <i className="fa-regular fa-pen-to-square edit_icon"></i>
                              </div>
                            </div> */}
                            <div
                              type="button"
                              onClick={showprofile}
                              className="user_profile_picture"
                              data-aos="flip-left"
                              data-aos-anchor-placement="center-bottom"
                              data-aos-duration="3000"
                            >
                              {/* <img src={(baseurl + userdata?.profile_pic) && (modelImg01)} style={stylesForSidebar} /> */}
                              <img
                                onClick={updateprofile}
                                // src={
                                //   userdata?.profile_pic instanceof File
                                //     ? URL.createObjectURL(userdata?.profile_pic)
                                //     : baseurl + userdata?.profile_pic
                                // }
                                src={
                                  userdata?.profile_pic
                                    ? baseurl + userdata?.profile_pic
                                    : dummy
                                }
                                style={stylesForSidebar}
                              />
                              <div
                                className="profile_edit_icon"
                                type="button"
                                onClick={updateprofile}
                              >
                                {" "}
                                {/* <input
                            type="file"
                            className="edit_icon_input"
                            name="image"
                            // onChange={handlefile}
 
                          />{" "} */}
                                {/* <button onClick={givetip}> */}
                                <i
                                  type="button"
                                  onClick={updateprofile}
                                  class="fa-regular fa-pen-to-square "
                                ></i>
                                {/* </button> */}
                                {/* <button
                            data-toggle="modal"
                            data-target=".exampleModaltip"
                            className="followers_numbers mb-3"
                          >
                            {" "}
                          <i type="button" onClick={givetip} class="fa-regular fa-pen-to-square "></i> 
                          </button> */}
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="row mb-4">
                              <div className="col-lg-6 mb-3">
                                <h4 className="secondaryLabel hot_model_name">
                                  Genger
                                </h4>
                                <p className="secondaryText user_profile_about">
                                  {" "}
                                  {userdata?.build_and_gender
                                    ? userdata?.build_and_gender
                                    : "Not Available"}
                                </p>
                              </div>

                              <div className="col-lg-6 mb-3">
                                <h4 className="secondaryLabel hot_model_name">
                                  Hair Colour
                                </h4>
                                <p className="secondaryText user_profile_about">
                                  {" "}
                                  {userdata?.hair_colour
                                    ? userdata?.hair_colour
                                    : "Not Available"}
                                </p>
                              </div>
                              <div className="col-lg-6 mb-3">
                                <h4 className="secondaryLabel hot_model_name">
                                  Eyes Colour
                                </h4>
                                <p className="secondaryText user_profile_about">
                                  {" "}
                                  {userdata?.eyes_colour
                                    ? userdata?.eyes_colour
                                    : "Not Available"}
                                </p>
                              </div>
                              <div className="col-lg-6 mb-3">
                                <h4 className="secondaryLabel hot_model_name">
                                  Foot Size
                                </h4>
                                <p className="secondaryText user_profile_about">
                                  {" "}
                                  {userdata?.foot_size
                                    ? userdata?.foot_size
                                    : "Not Available"}
                                </p>
                              </div>

                              <div className="col-lg-6 mb-3">
                                <h4 className="secondaryLabel hot_model_name">
                                  Skin Tone
                                </h4>
                                <p className="secondaryText user_profile_about">
                                  {" "}
                                  {userdata?.skin_tone
                                    ? userdata?.skin_tone
                                    : "Not Available"}
                                </p>
                              </div>
                              <div className="col-lg-6 mb-3">
                                <h4 className="secondaryLabel hot_model_name">
                                  About
                                </h4>
                                <p className="secondaryText user_profile_about">
                                  {" "}
                                  {userdata?.bio
                                    ? userdata?.bio
                                    : "Not Available"}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {transactions &&
                      (purchasedata.length < 0 ? (
                        <h1 className=" inbox_message ">
                          {" "}
                          Transaction Not Available
                        </h1>
                      ) : (
                        <div className="row tabs_box">
                          <div className="col-md-12">
                            <h3
                              className="following_heading"
                              data-aos="fade-right"
                              data-aos-anchor-placement="center-bottom"
                              data-aos-duration="3000"
                            >
                              Transactions
                            </h3>
                            <div className="divider_row"></div>
                          </div>
                          <div
                            className="col-md-12"
                            data-aos="fade-up"
                            data-aos-anchor-placement="center-bottom"
                            data-aos-duration="3000"
                          >
                            <div class="table-responsive">
                              <table class="table bg-black">
                                <thead class="table_header_bg">
                                  <tr className="  text-white">
                                    <th
                                      scope="col"
                                      className=" text-white table_header_row"
                                    >
                                      Date
                                    </th>
                                    <th
                                      scope="col"
                                      className="table_header_row text-white"
                                    >
                                      Amount
                                    </th>
                                    <th
                                      scope="col"
                                      className="table_header_row text-white"
                                    >
                                      Seller
                                    </th>
                                    <th
                                      scope="col"
                                      className="table_header_row text-white"
                                    >
                                      Purchased
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {purchasedata?.map((items) => (
                                    // Purchased

                                    <tr className="teansection">
                                      <td class="order_history text-white">
                                        {items?.Date}
                                      </td>
                                      <td class="order_history  text-white">
                                        ${items?.Amount}
                                      </td>
                                      <td class="order_history text-white">
                                        <a href="#" className="seller_text">
                                          {" "}
                                          {items?.Seller}{" "}
                                        </a>
                                      </td>
                                      <td class="order_history text-white">
                                        {items?.Purchased}
                                        <span>
                                          <a href="#" className="view_links">
                                            {" "}
                                            {/* View{" "} */}
                                          </a>
                                        </span>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      ))}

                    {Recivedtips && (
                      <div className="row tabs_box">
                        <div className="col-md-12">
                          <h3
                            className="following_heading"
                            data-aos="fade-right"
                            data-aos-anchor-placement="center-bottom"
                            data-aos-duration="3000"
                          >
                            Tips History
                          </h3>
                          <div className="divider_row"></div>
                        </div>
                        <div
                          className="col-md-12"
                          data-aos="fade-up"
                          data-aos-anchor-placement="center-bottom"
                          data-aos-duration="3000"
                        >
                          <div class="table-responsive">
                            <table class="table">
                              <thead class="table_header_bg">
                                <tr>
                                  <th scope="col" className="table_header_row">
                                    Date
                                  </th>
                                  <th scope="col" className="table_header_row">
                                    Amount
                                  </th>
                                  <th scope="col" className="table_header_row">
                                    Name
                                  </th>
                                  <th scope="col" className="table_header_row">
                                    Purchased
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td class="order_history">25.05.2024</td>
                                  <td class="order_history">$80.00</td>
                                  <td class="order_history">
                                    <a href="#" className="seller_text">
                                      {" "}
                                      Brittanyvues{" "}
                                    </a>
                                  </td>
                                  <td class="order_history">
                                    Unlocked collection: Lime green high heels{" "}
                                    <span>
                                      <a href="#" className="view_links">
                                        {" "}
                                        View{" "}
                                      </a>
                                    </span>
                                  </td>
                                </tr>
                                <tr className="bg_table_row">
                                  <td class="order_history">29.05.2024</td>
                                  <td class="order_history">$7.00</td>
                                  <td class="order_history">
                                    <a href="#" className="seller_text">
                                      {" "}
                                      Brittanyvues{" "}
                                    </a>
                                  </td>
                                  <td class="order_history">
                                    Unlocked collection: Lime green high heels{" "}
                                    <span>
                                      <a href="#" className="view_links">
                                        {" "}
                                        View{" "}
                                      </a>
                                    </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td class="order_history">26.05.2024</td>
                                  <td class="order_history">$9.00</td>
                                  <td class="order_history">
                                    <a href="#" className="seller_text">
                                      {" "}
                                      Brittanyvues{" "}
                                    </a>
                                  </td>
                                  <td class="order_history">
                                    Unlocked collection: Lime green high heels{" "}
                                    <span>
                                      <a href="#" className="view_links">
                                        {" "}
                                        View{" "}
                                      </a>
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    )}

                    {givestip && (
                      <div className="row paytab">
                        <div className="col-md-19">
                          <h3
                            className="following_heading"
                            data-aos="fade-right"
                            data-aos-anchor-placement="center-bottom"
                            data-aos-duration="3000"
                          >
                            Payment Information
                          </h3>
                          <div className="divider_row"></div>
                        </div>
                        <div
                          className="col-md-12"
                          data-aos="fade-up"
                          data-aos-anchor-placement="center-bottom"
                          data-aos-duration="3000"
                        >
                          <h4 className="  text-white mt-2 mb-2">
                            Choose Amount{" "}
                          </h4>
                          <div className="tipgap row  mx-auto    ">
                            <div className="paytips col-md-3    mb-4 justify-content-center">
                              <input
                                className=""
                                type="radio"
                                id="ti"
                                checked={isChecked}
                                onClick={() => handleRadioChange(0)}
                              />{" "}
                              <label for="t1">$25</label>
                            </div>

                            <div className="paytips col-md-3    mb-4 justify-content-center">
                              <input
                                className=""
                                type="radio"
                                checked={isChecked}
                                onClick={() => handleRadioChange(1)}
                              />{" "}
                              $50
                            </div>

                            <div className="paytips col-md-3    mb-4 justify-content-center">
                              <input
                                className=""
                                type="radio"
                                checked={isChecked}
                                onClick={() => handleRadioChange(2)}
                              />{" "}
                              $100
                            </div>
                            <div className="paytips col-md-3  mb-4 ">
                              <input
                                className=""
                                type="radio"
                                checked={isChecked}
                                onClick={() => handleRadioChange(3)}
                              />{" "}
                              $120
                            </div>
                            <div className="paytips col-md-3  mb-4 ">
                              <input
                                className=""
                                type="radio"
                                checked={isChecked}
                                onClick={() => handleRadioChange(4)}
                              />{" "}
                              $150
                            </div>
                            <div className="paytips col-md-3  mb-4 ">
                              <input
                                className=""
                                type="radio"
                                checked={isChecked}
                                onClick={() => handleRadioChange(5)}
                              />{" "}
                              $200
                            </div>
                          </div>

                          <h4 className="  text-white mt-2 mb-2">
                            Custom Amount{" "}
                          </h4>
                          <div className="custompay mb-4">
                            {" "}
                            $
                            <input className="custom" type="number" />
                          </div>
                          <button className="paybtn mt -4">Pay Now</button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-12">
                  <div
                    class="modal fade exampleModal"
                    // id="exampleModal"
                    tabindex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog modal-dialog-centered my-modal">
                      <div class="modal-content">
                        <div className="carousel-modal">
                          <div class="carousel-header">
                            <div className="carousel-icons">
                              <div className="caarousel-icons_inner">
                                <a
                                  href="javaScript:;"
                                  className="cancel"
                                  data-dismiss="modal"
                                >
                                  <img src={Cancel} />
                                </a>
                                {/* <a
                                type="button"
                                onClick={() => handleHeart(profilebyid?.id)}
                                className="heart"
                              >
                                <i
                                  className={`fa ${
                                    hearts ? "fa-solid" : "fa-regular"
                                  } fa-heart`}
                                ></i>
                              </a> */}
                              </div>
                            </div>
                          </div>

                          <div class="carousel-header mb-5">
                            <div className="carousel-icons">
                              <div className="caarousel-icons_inner">
                                <a
                                  href="javaScript:;"
                                  className="cancel"
                                  data-dismiss="modal"
                                >
                                  <img src={Cancel} />
                                </a>
                              </div>
                            </div>
                          </div>
                          <Swiper
                            slidesPerView={"auto"}
                            centeredSlides={true}
                            spaceBetween={15}
                            navigation={true}
                            // pagination={{
                            //   clickable: true,
                            // }}
                            modules={[Navigation]}
                            className="mySwiper mt-5"
                          >
                            {" "}
                            {profilebyid?.post_data?.map((data) => (
                              <SwiperSlide>
                                {/* <div> */}
                                <img
                                  src={
                                    data?.file ? baseurl + data?.file : dummy
                                  }
                                  className="modalpic"
                                />
                                {/* </div> */}
                              </SwiperSlide>
                            ))}
                          </Swiper>

                          <div className="carousel-footer">
                            <h4 className="carousel-footer_title">
                              <h3 className="modal-title mt-3">
                                {profilebyid?.post_title} :)
                              </h3>
                            </h4>
                            <p className="carousel-footer_body">
                              {profilebyid?.post_description}
                            </p>
                            <p className="carousel-footer_price">
                              ${profilebyid?.price}{" "}
                            </p>
                            <button
                              onClick={() => handleDelete(profilebyid?.id)}
                              className="carousel-footer_button "
                              data-dismiss="modal"
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="not-found">Profile Not Found</div>
          )}
        </div>
      )}

      <div>
        <Footer />
      </div>
    </div>
  );
};

export default ModelProfile;
// userdata?.user_follower
