import React, { useEffect, useState } from "react";
import Header from "../../Components/Layout/Header";
import { ALERT_TYPES } from "../../constants/index";
import Select from "react-select";
import { SelectBox } from "../../Components/CustomSelect";
import { ToastContainer, toast } from "react-toastify";
// import { toast } from 'react-toastify';

import Footer from "../../Components/Layout/Footer";

import {
  modelprofileview,
  modelprofillist,
  Getmodelpostlist,
  Getmodelpost,
  EditUserprofile,
  UserUnflowmodel,
  profileviewbyidmodel,
  Group_list,
  Verifymessage
} from "../../api";
import { modelpackagelist, modelpurchaseplane } from "../../api";
import { toastAlert } from "../../utils/index";
import "./style.css";
import Loader from "../../Components/loader";
import { line } from "../../Asserts/images";
import { Cancel } from "../../Asserts/images";
import { json, useNavigate } from "react-router-dom";

// import {
//   CardElement,
//   useStripe,
//   useElements,
//   Elements,
// } from "@stripe/react-stripe-js";

import "react-toastify/dist/ReactToastify.css";

const notifys = () => toast("Plan Purchase Successfully");

export function Verifyaccountprofile() {
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();
  //   const stripe = useStripe();
  //   const elements = useElements();
  const [isLoading, setLoading] = useState(true);
  const getusertype = localStorage.getItem("userrole");
  const [notify, setNotify] = useState("");

  const [stiprtoken, setstiprtoken] = useState();
  const handlechanges = (e) => {
    const { name, value } = e.target;
    setFormData((prevdata) => ({
      ...prevdata,
      [name]: value
    }));
  };

  //   const handleSubmit = async (event) => {
  //     event.preventDefault();

  // if (!stripe || !elements) {
  //   return;
  // }

  // const { token, error } = await stripe.createToken(
  //   elements.getElement(CardElement)
  // );

  // if (token) {
  //   setstiprtoken(token?.id);
  //   handleclick(token?.id);
  // } else {
  // }
  //   };

  console.log("selectedformformData", formData);

  const planeid = localStorage.getItem("planeid");
  const price = localStorage.getItem("price");
  const id = localStorage.getItem("id ");

  // const notifys = () => toast("Plan Purchase Successfully");

  //   const handleSubmit = async (event) => {
  //     event.preventDefault();
  //     const formDataMethod = new FormData();
  //     for (const key in formData) {
  //       formDataMethod.append(key, formData[key]);
  //     }

  //     try {
  //       const response = await modelpurchaseplane(planeid, formDataMethod);

  //       if (response?.status == true) {
  //         localStorage.removeItem("planeid");
  //         localStorage.removeItem("price");
  //         localStorage.removeItem("id ");
  //         setNotify(response?.mgs);

  //         notifys();

  //         toastAlert(response.statusText, ALERT_TYPES.ERROR);
  //         notifys();
  //       } else {
  //         toastAlert(response.statusText, ALERT_TYPES.ERROR);
  //       }
  //     } catch (error) {
  //       console.error("Error in adding model post:", error);
  //       toastAlert(error.message || "An error occurred", ALERT_TYPES.ERROR);
  //     }
  //   };

  const [getmessage, setGetmessage] = useState("");

  // const [loading, setLoading] = useState(false); // State to track loading

  // console.log("getmessage" , getmessage?.profile_pic_request)

  const handleSubmit = async (event) => {
    event.preventDefault();

    console.log("ss", isDriving);
    if (formData?.profile_pic && !isDriving) {
      // alert("Please upload the image with the driving license.");
      return;
    }
    // foot_size build_and_gender
    const formDataMethod = new FormData();
    for (const key in formData) {
      if (key == "height") {
        formDataMethod.append(key, JSON.stringify(formData[key]));
      } else if (key == "foot_size") {
        formDataMethod.append(key, JSON.stringify(formData[key]));
      } else if (key == "build_and_gender") {
        formDataMethod.append(key, JSON.stringify(formData[key]));
      } else {
        formDataMethod.append(key, formData[key]);
      }
    }

    setLoading(true); // Set loading to true when API request starts

    // Create FormData object

    setLoading(true);

    try {
      const response = await EditUserprofile(formDataMethod);

      if (response?.status == true) {
        // alert("jjjjjjjjjjjjjjjj")
        // Success handling
        toastAlert(response.msg, ALERT_TYPES.SUCCESS);

        if (response?.status == true) {
          // navigate("/user-profile");
          Getmodalvarify();
        } else {
          //     // Handle other responses
          //     // alert(data.message || "Something went wrong");
          Getmodalvarify();
        }
        // })
      } else {
        // Error handling
        toastAlert(response.msg || response.statusText, ALERT_TYPES.ERROR);
      }
    } catch (error) {
      console.error("Error in adding model post:", error);
      toastAlert(error.message || "An error occurred", ALERT_TYPES.ERROR);
    } finally {
      // Always set loading to false at the end
      setLoading(false);
    }
  };

  const [Userdata, setUserdata] = useState({});
  const Getmodalvarify = async () => {
    try {
      const response = await Verifymessage();
      console.log("response1", response?.data?.profile_pic_request);
      // const responsemesg = response.json();

      if (response?.data?.profile_pic_request == "Approved") {
        localStorage.removeItem("profile_pic_request");
        localStorage.setItem("profile_pic_request", "Approved");

        navigate("/packages-page");
      } else {
        setGetmessage(response);
      }

      setLoading(false);
    } catch (error) {
      console.error("Error in logging in:", error);
    }
  };

  useEffect(() => {
    Getmodalvarify();
  }, []);

  const [isDriving, setIsDriving] = useState(false);

  const handlefile = (event) => {
    const file = event.target.files[0];
    const { name } = event.target;
    if (name == "driving_pic") {
      const FileName = file;
      if (FileName) {
        setIsDriving(true);
      } else {
        setIsDriving(false);
      }
    }
    if (file) {
      const FileName = file;
      setFormData((prev) => ({
        ...prev,
        [name]: FileName
      }));
    }
  };

  const SelectOptions = [
    { id: "5 inches", name: "5 inches" },
    { id: "5.2 inches", name: "5.2 inches" },
    { id: "5.3 inches", name: "5.3 inches" },
    { id: "5.4 inches", name: "5.4 inches" },
    { id: "5.5 inches", name: "5.5 inches" }
  ].map((option) => ({ value: option.id, label: option.name }));

  const Haircolour = [
    { id: "Green", name: "Green" },
    { id: "Black", name: "Black" },
    { id: "White", name: "White" }
  ].map((option) => ({ value: option.id, label: option.name }));

  const Eyeecolour = [
    { id: "Green", name: "Green" },
    { id: "Black", name: "Black" },
    { id: "White", name: "White" }
  ].map((option) => ({ value: option.id, label: option.name }));

  const SelectOptionsman = [
    { id: "Man", name: "Man" },
    { id: "Woman", name: "Woman" }
  ].map((option) => ({ value: option.id, label: option.name }));

  const SelectOptionsfootsize = [
    { id: 8.5, name: "8.5" },
    { id: 9.5, name: "9.5" },
    { id: 10.5, name: "10.5" },
    { id: 11.5, name: "11.5" },
    { id: 812.5, name: "12.5" }
  ].map((option) => ({ value: option.id, label: option.name }));

  const apiUrl = process.env.REACT_APP_BASE_URL;
  setTimeout(() => {
    setLoading(false);
  }, 1000);

  const handleChangeSelect = (e, name) => {
    const { value } = e;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value // Use the name and value from the selected option
    }));
  };
console.log('formDataformData',formData);

  return (
    <div>
      <Header />
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          {getmessage?.data?.profile_pic_request == "Pending" ? (
            <section className="payment">
              <div class="container">
                <div class="row">
                  <h1 className="verifytext">
                    Please wait until the verification is complete.
                  </h1>
                </div>
              </div>
            </section>
          ) : (
            <section className="payment">
              <div class="container">
                <div class="row">
                  <div class="col-md-10 mx-auto">
                    <h5 className="pay">Verify Profile</h5>
                    <form
                      className="login-forms  justify-content-center mx-auto  d-flex"
                      onSubmit={handleSubmit}
                    >
                      <div className="row justify-content-center d-flex">
                        {/* <div className="col-md-5">
                          <label className="namin  "> Profile Image </label>
                          <span className="updateprofile">
                            <input
                              className="nam"
                              type="file"
                              placeholder="Name"
                              // required
                              name="profile_pic"
                              onChange={handlefile}
                            />
                          </span>
                        </div> */}

                        <div className="col-md-5 ">
                          <label className="namin">
                            {" "}
                            Upload Image With Driving license{" "}
                          </label>
                          <span className="updateprofile">
                            <input
                              className="nam"
                              type="file"
                              placeholder="Name"
                              // required
                              name="driving_pic"
                              // value={formData}
                              onChange={handlefile}
                            />
                          </span>
                        </div>

                        <br />

                        <div className="col-md-5 mb-2">
                          <label className="namin"> Height </label>

                          {/* <span className="updateprofile"> */}
                          {/* <Select
                              className="updateselect"
                              value={formData?.user_features_detail?.height}
                              options={SelectOptions}
                              name="height"
                              onChange={(e) => handleChangeSelect(e, "height")}
                            /> */}

                          {/* <SelectBox
                              selectClass="mainInput"
                              name="height"
                              id="height"
                              className="updateselect"
                              // label="Height"
                              labelClass="mainLabel"
                              required
                              value={formData?.user_features_detail?.height}
                              option={SelectOptions} // Use the dynamic options
                              onChange={(e) => handleChangeSelect(e, "height")}
                            /> */}

                          <span className="updateprofile">
                            <Select
                              className="updateselect"
                              value={formData?.user_features_detail?.height}
                              options={SelectOptions}
                              name="height"
                              onChange={(e) => handleChangeSelect(e, "height")}
                            />
                          </span>
                        </div>

                        <div className="col-md-5 mb-2">
                          <label className="namin"> Build Gender </label>

                          <span className="updateprofile">
                            <Select
                              className="updateselect"
                              value={
                                formData?.user_features_detail?.build_and_gender
                              }
                              options={SelectOptionsman}
                              name="build_and_gender"
                              onChange={(e) =>
                                handleChangeSelect(e, "build_and_gender")
                              }
                            />
                          </span>
                        </div>

                        <div className="col-md-5 mb-2">
                          <label className="namin"> Foot size </label>

                          <span className="updateprofile">
                            {/* <input
                              className="nam"
                              type="number"
                              value={formData?.user_features_detail?.foot_size}
                              // required
                              placeholder="Enter Foot size"
                              name="foot_size"
                              onChange={handlechanges}
                            /> */}

                            <Select
                              className="updateselect"
                              value={formData?.user_features_detail?.foot_size}
                              options={SelectOptionsfootsize}
                              name="foot_size"
                              // onChange={handleChangeSelect}

                              onChange={(e) =>
                                handleChangeSelect(e, "foot_size")
                              }
                            />
                          </span>
                        </div>

                        <div className="col-md-5 mb-2 ">
                          <label className="namin"> Weight </label>

                          <span className="updateprofile">
                            <input
                              className="nam"
                              type="number"
                              value={formData?.user_features_detail?.weight}
                              // required
                              placeholder="Enter Weight"
                              name="weight"
                              onChange={handlechanges}
                            />
                          </span>
                        </div>

                        <div className="col-md-5 mb-2">
                          <label className="namin"> Hair Colour </label>

                          <span className="updateprofile">
                            {/* <input
                              className="nam"
                              type="text"
                              value={
                                formData?.user_features_detail?.hair_colour
                              }
                              // required
                              placeholder="Enter Hair Colour"
                              name="hair_colour"
                              onChange={handlechanges}
                            /> */}

                            <Select
                              className="updateselect"
                              value={
                                formData?.user_features_detail?.hair_colour
                              }
                              options={Haircolour}
                              name="hair_colour"
                              // onChange={handleChangeSelect}

                              onChange={(e) =>
                                handleChangeSelect(e, "hair_colour")
                              }
                            />
                          </span>
                        </div>

                        <div className="col-md-5">
                          <label className="namin"> Skin Tone </label>

                          <span className="updateprofile">
                            <input
                              className="nam"
                              type="text"
                              value={formData?.user_features_detail?.skin_tone}
                              // required
                              placeholder="Enter Skin Tone"
                              name="skin_tone"
                              onChange={handlechanges}
                            />
                          </span>
                        </div>

                        <div className="col-md-5">
                          <label className="namin"> Eyes Colour </label>

                          <span className="updateprofile">
                            {/* <input
                              className="nam"
                              type="text"
                              value={
                                formData?.user_features_detail?.eyes_colour
                              }
                              // required
                              placeholder="Enter Eyes Colour"
                              name="eyes_colour"
                              onChange={handlechanges}
                            /> */}

                            <Select
                              className="updateselect"
                              value={
                                formData?.user_features_detail?.eyes_colour
                              }
                              options={Eyeecolour}
                              name="eyes_colour"
                              // onChange={handleChangeSelect}

                              onChange={(e) =>
                                handleChangeSelect(e, "eyes_colour")
                              }
                            />
                          </span>
                        </div>

                        <div className="col-md-5">
                          <label className="namin"> Email </label>

                          <span className="updateprofile">
                            <input
                              disabled
                              value={formData?.email}
                              className="nam"
                              type="email"
                              required
                              placeholder="email"
                              name="email"
                              onChange={handlechanges}
                            />
                          </span>
                        </div>

                        <div className="col-md-5">
                          <label className="namin"> facebook </label>

                          <span className="updateprofile">
                            <input
                              className="nam"
                              type="text"
                              // required
                              placeholder="Enter Facebook Link"
                              name="facebook_link"
                              value={formData?.facebook_link}
                              onChange={handlechanges}
                            />
                          </span>
                        </div>

                        <div className="col-md-5">
                          <label className="namin"> instagram </label>

                          <span className="updateprofile">
                            <input
                              className="nam"
                              type="text"
                              // required
                              placeholder="Enter instagram Link"
                              name="instagram_link"
                              value={formData?.instagram_link}
                              onChange={handlechanges}
                            />
                          </span>
                        </div>

                        <div className="col-md-5">
                          <label className="namin"> youtube </label>

                          <span className="updateprofile">
                            <input
                              className="nam"
                              type="text"
                              // required
                              placeholder="Enter Youtube Link"
                              name="youtube_link"
                              value={formData?.youtube_link}
                              onChange={handlechanges}
                            />
                          </span>
                        </div>

                        <div className="col-md-5  ">
                          <label className="namin"> twitter </label>

                          <span className="updateprofile">
                            <input
                              className="nam"
                              type="text"
                              // required
                              placeholder="Enter Facebook Link"
                              name="twitter_link"
                              value={formData?.twitter_link}
                              onChange={handlechanges}
                            />
                          </span>
                        </div>

                        <div className="col-md-5">
                          <label className="namin"> About Us </label>

                          <span className="updateprofile">
                            <textarea
                              value={formData?.bio}
                              name="bio"
                              placeholder="Enter description"
                              type="text"
                              className="nam"
                              onChange={handlechanges}
                              maxlength="300"
                            >
                              {" "}
                            </textarea>
                          </span>
                        </div>

                        <div class="slct">
                          <div className="slct-first"></div>
                          <div class="drop">
                            {/* <input type="submit" class="sub" value="CONFIRM"/> */}
                            <button type="submit" class="sub">
                              Verify
                            </button>
                          </div>
                        </div>
                      </div>
                      <br />
                    </form>
                  </div>
                </div>
              </div>
            </section>
          )}

          <Footer />
          <ToastContainer />
        </div>
      )}
    </div>
  );
}

// export default UpdateProfile;
{
  /* if user upload profile_pic must required  driving_pic */
}

// const handleSubmit = async (event) => {
//     event.preventDefault();

//     // Validation: If profile_pic is uploaded, driving_pic must also be uploaded
//     if (formData.profile_pic && !formData.driving_pic) {
//       alert('Please upload the image with the driving license.');
//       return; // Prevent form submission if validation fails
//     }

//     // Prepare payload for submission
//     const payload = new FormData();
//     payload.append('profile_pic', formData.profile_pic);
//     payload.append('driving_pic', formData.driving_pic);

//     try {
//       const response = await ForgetRequest2(payload);
//       if (response?.status === true) {
//         // Handle success
//         navigate("/forget-password2");
//       } else {
//         // Handle error
//       }
//     } catch (error) {
//       console.error('Error in submission:', error);
//       // Handle error
//     }
//   };
