import React from "react";
import {
  mainLogo,
  footerSocialIcon01,
  footerSocialIcon02,
  footerSocialIcon03,
  footerSocialIcon04,
  footerSocialIcon05,
  footerSocialIcon06,
  payementImg,
  masterCard,
  paypal,
  americanExpress
} from "../../../Asserts/images/index";

import cardimage from "../../../Asserts/images/cardimage.png";
import "./style.css";
import { Link } from "react-router-dom";

const Footer = () => {
  const userToken = localStorage.getItem("userToken");
  console.log("quick links", userToken);

  return (
    <div className="main_footer">
      <div className="gradient_line"></div>

      <div className="container pt-5">
        <div className="row">
          <div className="col-md-5">
            <div className="footer_logo_with_content">
              <div className="footer_logo">
                <Link to="/">
                  <img src={mainLogo} />
                </Link>
              </div>

              {/* <p className="footer_logo_content">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to
              </p> */}
            </div>
          </div>

          <div className="col-md-2">
            <div className="quick_links">
              <h6 className="footer_list_heading">quick links</h6>

              <ul className="footer_list_main">
                <li>
                  <Link to="/">home</Link>
                </li>
                {!userToken && (
                  <li>
                    <Link to="/login-page">sign up</Link>
                  </li>
                )}
                <li>
                  <Link to="/aboutus">About Us</Link>
                </li>

                <li>
                  <Link to="/contact">Contact</Link>
                </li>
                {/* <li>
                  <Link to="/packages-page">Contact</Link>
                </li> */}
                <li>
                  <Link to="/affiliates">Affiliates</Link>
                </li>
                <li>
                  <Link to="/howitwork">How It Works</Link>
                </li>
                <li>
                  <Link to="/faqs">FAQ</Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-md-2">
            <div className="quick_links">
              <h6 className="footer_list_heading">useful links</h6>

              <ul className="footer_list_main">
                {userToken && (
                  <li>
                    <Link to="/model-page">Models</Link>
                  </li>
                )}
                {/* {userrole == 2 && (
                  <li>
                    <Link to="/add-post-page">Add Post</Link>
                  </li>
                )}{" "} */}

                {/* <li>
                  <Link to="/request-board">Request Board</Link>
                </li> */}
                <li>
                  <Link to="/termcondition">Terms OF Use</Link>
                </li>

                <li>
                  <Link to="/privacypolicy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/2257statement">2257 statement </Link>
                </li>
                <li>
                  <Link to="/dmcapolicy">DMCA Policy</Link>
                </li>
                <li>
                  <Link to="/ccpa">CCPA</Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-2 col-md-3">
            <div className="quick_links">
              <h6 className="footer_list_heading">social media</h6>

              <div className="footer_social_icons">
                <div className="footer_icon">
                  <img src={footerSocialIcon01} />
                </div>

                <div className="footer_icon">
                  <img src={footerSocialIcon02} />
                </div>

                <div className="footer_icon">
                  <img src={footerSocialIcon03} />
                </div>

                <div className="footer_icon">
                  <img src={footerSocialIcon04} />
                </div>

                {/* <div className="footer_icon">
                  <img src={footerSocialIcon05} />
                </div> */}

                <div className="footer_icon">
                  <img src={footerSocialIcon06} />
                </div>
                {/* <div className="footer_icon_card">
                  <img src={cardimage} />
                </div> */}
              </div>

              {/* <ul className='footer_list_main'>
              <li>careers</li>
              <li>term of use</li>
              <li>privacy policy</li>
              <li>2257 Statement</li>
              <li>DMCA Policy</li>
              <li>CCPA</li>
            </ul> */}
            </div>
          </div>
        </div>
      </div>
      <div class=" container" bis_skin_checked="1">
        <div class="align-items-center row" bis_skin_checked="1">
          <div class="col-lg-6 col-sm-6 col-12" bis_skin_checked="1">
            <div bis_skin_checked="1">
              <p class="footerbar_para m-0 text-white">
                Copyright 2024 All right reserved.{" "}
                <span class="footerBar_bold_text fw-bold">Platinum Feet </span>
              </p>
            </div>
          </div>
          <div class="col-lg-6 col-sm-6 col-12" bis_skin_checked="1">
            <div class="payments_img text-right  d-flex justify-content-end   gap-2" bis_skin_checked="1">
              {" "}
              <img src={payementImg} alt="payement-img" height={25} />{" "}
              <img src={masterCard} alt="payement-img" height={25} />{" "}
              <img src={paypal} alt="payement-img" height={25} />{" "}
              <img src={americanExpress} alt="payement-img" height={25} />{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
