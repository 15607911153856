import React, { useEffect, useState } from "react";
import Header from "../../Components/Layout/Header";
import Footer from "../../Components/Layout/Footer";

import { Swiper, SwiperSlide } from "swiper/react";
import Loader from "../../Components/loader";
import { useLocation, useSearchParams } from "react-router-dom";

import {
  Addmodelpost,
  modellist,
  Postlisting,
  UserUnfavouritemodel,
  searchpost,
} from "../../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "swiper/css";

import {
  platinumFeetText,
  headerSearchIcon,
  legsImage01,
  imgTopCorner,
  imgBottomCorner,
  hundredPercentage,
  homeImg01,
  modelText,
  modelImg01,
  modelCardTopCorner,
  userProfilePic,
  modelCardBottomCorner,
  modelImg02,
  modelImg03,
  modelImg04,
  framePic,
  hoverableImg,
  mainFrameImg,
  videoPlayIcon,
  modelImg05,
  modelImg06,
  modelImg07,
  howItWorksText,
  howItWorksImg,
  forClientText,
  forClientImg,
  forModelsText,
  formodelImg01,
  formodelImg02,
  faqText,
  hotModelImg01,
} from "../../Asserts/images/index";


import dummy from "../../Asserts/images/dummy.jpg";
import "./style.css";
import { useNavigate, useParams } from "react-router-dom";
import { faHeart } from "@fortawesome/free-solid-svg-icons";

const listingCard = [
  {
    swipperData: [
      {
        modelName: "HOTMODEL1234",
        address: "38-USA",
        cardImage: modelImg01,
      },
      {
        modelName: "HOTMODEL1234",
        address: "38-USA",
        cardImage: modelImg02,
      },
    ],
  },
  {
    swipperData: [
      {
        modelName: "HOTMODEL1234",
        address: "38-USA",
        cardImage: modelImg02,
      },
      {
        modelName: "HOTMODEL1234",
        address: "38-USA",
        cardImage: modelImg01,
      },
    ],
  },
  {
    swipperData: [
      {
        modelName: "HOTMODEL1234",
        address: "38-USA",
        cardImage: modelImg04,
      },
      {
        modelName: "HOTMODEL1234",
        address: "38-USA",
        cardImage: modelImg05,
      },
    ],
  },
  {
    swipperData: [
      {
        modelName: "HOTMODEL1234",
        address: "38-USA",
        cardImage: modelImg05,
      },
      {
        modelName: "HOTMODEL1234",
        address: "38-USA",
        cardImage: modelImg04,
      },
    ],
  },
];

const SearchPost = () => {
  const [isLoading, setLoading] = useState(true);

  const token = localStorage.getItem("userToken");
  const [heart, setHearts] = useState(false);
  const [hearts, setHeart] = useState(false);
  const { id } = useParams();
  const jhk = useParams();
  const { search} = useLocation();
  const valued = search.split("=")[1]
  console.log("location", valued);

  let [searchParams, setSearchParams] = useSearchParams();
  let [query, setQuery] = useState(searchParams.get("query") || "");

  console.log("search", query);

  function handleSubmit() {
    setSearchParams({ query });
    // setQuery(id);
  }

  console.log("searchpara", searchParams);

  console.log("id", id);
  // const handleHeart = () => {
  //   setHeart(!hearts);
  // }

  const handleHeart = async (id) => {
    try {
      const response = await UserUnfavouritemodel(id);
      console.log("response", response);

      if (response?.status == true) {
        const data = response?.data;
        setHearts(data);

        modesllist();
      } else {
        // toastAlert(response.statusText, ALERT_TYPES.ERROR);
        console.log("packege ", response.statusText);
      }
      setModellists(response?.data);
    } catch (error) {
      console.error("Error in logging in:", error);

      // toastAlert(error, ALERT_TYPES.ERROR);
    }
  };

  const baseurl = `${process.env.REACT_APP_BASE_URL}/public/`;
  console.log("baseurl", baseurl);
  const [modellists, setModellists] = useState([]);
  const [inputValue, setInputValue] = useState("");

  console.log("modellists", modellists);

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const filterData = modellists?.filter((item) =>
    item?.post_title?.toLowerCase().includes(inputValue.toLowerCase())
  );

  const modesllist = async () => {
    try {
      const response = await Postlisting();
      console.log("response", response);

      setModellists(response?.data);
      setLoading(false);
    } catch (error) {
      console.error("Error in logging in:", error);

      // toastAlert(error, ALERT_TYPES.ERROR);
    }
  };
  const navigate = useNavigate();

  const handleclick = (id) => {
    // navigate('/profile-page')
    navigate(`/profile-detail-page/${id}`);
  };
  useEffect(() => {
    modesllist();
  }, []);

  const [GetSearchdata, setGetSearchdata] = useState([]);

  const HandleSrearch = async () => {
    // Create FormData object
    const formDataMethod = new FormData();

    formDataMethod.append("search_value", valued);
    // setLoading(true);

    try {
      const response = await searchpost(formDataMethod);

      if (response?.status == true) {
        // Success handling
        // toastAlert(response.msg, ALERT_TYPES.SUCCESS);
        setGetSearchdata(response?.data);

        // setIsCheckeds(false);
      } else {
        // Error handling
        // toastAlert(response.msg || response.statusText, ALERT_TYPES.ERROR);
      }
    } catch (error) {
      console.error("Error in adding model post:", error);
      // toastAlert(error.message || "An error occurred", ALERT_TYPES.ERROR);
    } finally {
      // Always set loading to false at the end
      // setLoading(false);
    }
  };

  useEffect(() => {
    HandleSrearch();
  }, [valued]);

  console.log("GetSearchdata", GetSearchdata);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <div className="model_main_section">
            <div>
              <Header />
            </div>

            <div className="model_section">
              <div className="container">
                <div className="row">
                  {/* <div className="col-md-12 pb-4">
                    <div
                      className="model_text_img text-center pb-5"
                      data-aos="fade-right"
                      data-aos-anchor-placement="center-bottom"
                      data-aos-duration="3000"
                    >
                      <img src={modelText} />
                    </div>

                    <div className="all_filters_with_search">
                      <div className="main_searchBar">
                        <input
                          value={inputValue}
                          inputClass="mainInput"
                          onChange={handleChange}
                          className="searchbar_input_field"
                          type="text"
                          placeholder="search post here"
                        />
                        <button className="searchbar_actionBtn">
                          <img src={headerSearchIcon} />
                        </button>
                      </div>
                    </div>
                  </div> */}
                  {GetSearchdata && GetSearchdata.length > 0 ? (
                    GetSearchdata.map((item, index) => (
                      <div
                        key={index}
                        className="col-10 col-sm-6 col-lg-3 mx-auto"
                      >
                        <div className="first_model_card">
                          <Swiper
                            spaceBetween={30}
                            slidesPerView={1}
                            onSlideChange={() => console.log("slide change")}
                            onSwiper={(swiper) => console.log(swiper)}
                          >
                            <SwiperSlide key={index}>
                              <div className="model_card_img position-relative">
                                <img
                                  src={item?.post_image  ?  baseurl + item?.post_image : dummy}
                                  className="img-fluid"
                                />
                                {token && (
                                  <span
                                    type="button"
                                    onClick={() => handleHeart(item?.id)}
                                    className="heart_icon"
                                  >
                                    <i
                                      className={`${
                                        item?.favourite
                                          ? "fa-solid"
                                          : "fa-regular"
                                      } fa-heart`}
                                    ></i>
                                  </span>
                                )}
                              </div>

                              <div className="model_card_desc">
                                <div className="description_box">
                                  <div className="image_with_text_row_two justify-content-between">
                                    <a className="product_heading" href="#">
                                      {item?.post_title}
                                    </a>
                                    <span className="gap-4 d-flex">
                                      {/* <p className="free_locked_text">
                                        <span className="unlocked_icon">
                                          <i className="fa-solid fa-unlock"></i>
                                        </span>
                                        Free
                                      </p>
                                      <p className="lock_text_clr free_locked_text">
                                        <span className="locked_icon">
                                          <i className="fa-solid fa-lock"></i>
                                        </span>
                                        Locked
                                      </p> */}
                                    </span>
                                  </div>
                                  <p className="product_description product_description_cardText">
                                    {item?.post_description}
                                  </p>
                                </div>
                                <div className="view_collection_btn_div">
                                  <button
                                    className="view_collection_btn"
                                    onClick={() => handleclick(item?.id)}
                                  >
                                    View Collection
                                  </button>
                                </div>
                              </div>
                            </SwiperSlide>
                          </Swiper>

                          <div className="model_card_top_corner_img">
                            <img src={modelCardTopCorner} />
                          </div>

                          <div className="model_card_bottom_corner_img">
                            <img src={modelCardBottomCorner} />
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="not-found">Post Not Found</div>
                  )}

             
                </div>
              </div>
            </div>
          </div>

          <div>
            <Footer />
          </div>
        </div>
      )}
    </>
  );
};

export default SearchPost;
