import Loader from "../../Components/loader";
import React, { useState, useEffect } from "react";
import Header from "../../Components/Layout/Header";
// import Footer from '../../Components/Layout/Footer';
import { mainLogo, top, btm } from "../../Asserts/images/index";
import CommonInput from "../../Components/CustomInput";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { userLoginRequest } from "../../api";
import { toastAlert } from "../../utils";
import { ALERT_TYPES } from "../../constants";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../../redux/slicers/user";
import CustomInput from "../../Components/CustomInput";
const Login = () => {
  const [isLoading, setLoading] = useState(false);
  //CONST VALS
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // useEffect(() => {
  //   // Simulate loading for 2 seconds (adjust as needed)
  //   const timer = setTimeout(() => {
  //     setLoading(true);
  //   }, 2000);

  //   // Cleanup timer on unmount
  //   return () => clearTimeout(timer);
  // }, []);

  //HANDLERS
  // setLoading(true)

  // const handleSubmit = async (event) => {

  //   event.preventDefault();
  //    const formData = new FormData(event.currentTarget);
  //   let payload = {};
  //   for (let [key, value] of formData.entries()) {
  //     payload[key] = value;
  //   }
  //   console.log(payload);
  //   try {
  //     const response = await userLoginRequest(payload);

  //     if (response && response.success === true) {
  //       await new Promise((resolve) => {
  //         // notifys();
  //         setTimeout( (() =>{
  //           setLoading(true)
  //         }  , 3000)     );
  //         setLoading(false)
  //       });
  //       const userToken = response?.data;
  //       // toastAlert('you have successfully logged in', ALERT_TYPES.SUCCESS);/
  //       localStorage.setItem("userToken", userToken?.token);
  //       localStorage.setItem("userToken", userToken?.token);
  //       localStorage.setItem("user_id", userToken?.id);
  //       localStorage.setItem("userrole", userToken?.user_role);

  //       localStorage.setItem("email", response.data?.email);
  //       localStorage.setItem("plan", response.data?.plan);
  //       localStorage.setItem("post_limit", response.data?.post_limit);
  //       localStorage.setItem("boost_limit", response.data?.boost_limit);
  //       dispatch(loginSuccess(response.data));
  //       const usertype = localStorage.getItem("userrole");
  //       console.log("usertype", usertype);
  //       if (usertype == 2) {
  //         navigate("/model-profile-page");
  //       } else {
  //         navigate("/model-page");
  //       }
  //       // navigate("/");
  //     } else if (response && response.success === false) {
  //       setTimeout( (() =>{
  //         setLoading(true)
  //       }  , 3000)     );
  //       setLoading(false)
  //       toastAlert(response.message, ALERT_TYPES.ERROR);
  //     } else {
  //       toastAlert(response.statusText, ALERT_TYPES.ERROR);
  //     }
  //   } catch (error) {
  //     console.error("Error in logging in:", error);

  //     toastAlert(error, ALERT_TYPES.ERROR);
  //   }
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    let payload = {};
    for (let [key, value] of formData.entries()) {
      payload[key] = value;
    }

    console.log(payload);

    try {
      // Start loading before the API call
      setLoading(true);

      const response = await userLoginRequest(payload);



      console.log("response get" , response)
      if (response && response.success == true) {
        // Simulate a delay for 3 seconds with loader active
        setTimeout(async () => {
          // Stop loading after 3 seconds
          setLoading(false);

          const userToken = response?.data;

          localStorage.setItem("userToken", userToken?.token);
          localStorage.setItem("profile_pic_request", userToken?.profile_pic_request);
           
          localStorage.setItem("user_id", userToken?.id);
          localStorage.setItem("userrole", userToken?.user_role);
          localStorage.setItem("email", response.data?.email);
          localStorage.setItem("plan", response.data?.plan);
          localStorage.setItem("post_limit", response.data?.post_limit);
          localStorage.setItem("boost_limit", response.data?.boost_limit);

          dispatch(loginSuccess(response.data));

          const usertype = localStorage.getItem("userrole");
          console.log("userToken", userToken);
          if (userToken?.profile_pic_request == "Pending") {
            navigate("/verify-account-profile");
          } else {
            if (usertype == 2) {
              navigate("/model-profile-page");
            } else if (usertype == 3) {
              navigate("/client-profile");
            }
          }
        }, 3000);
      } else if (response && response.success == false) {
        // Stop loading after 3 seconds for unsuccessful login
        setTimeout(() => {
          setLoading(false);
          toastAlert(response.message, ALERT_TYPES.ERROR);
        }, 3000);
      } else {
        // Handle unexpected errors
        toastAlert(response.statusText, ALERT_TYPES.ERROR);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error in logging in:", error);
      setLoading(false); // Stop loading in case of an error
      toastAlert(error.message || error, ALERT_TYPES.ERROR);
    }
  };

  return (
    <>
      <div style={{height:"100vh"}}>
        <Header />

        <div></div>

        {isLoading ? (
          <Loader />
        ) : (
          <section className="log">
            <div className="black mx-auto">
              <div className="container">
                <div className="gold">
                  <img className="img-fluid top" src={top} />
                  <div className="row">
                    <div className="col-md-12">
                      <div className="imagerow">
                        <img className="img-fluid " src={mainLogo} />
                      </div>
                      <h4 className="sign">Welcome! Sign in to continue</h4>
                      {/* <p className="txt">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's{" "}
                      </p> */}

                      {/* <form className="login-form " onSubmit={handleSubmit}>
 
                    <label required class="user">
                      {" "}
                      Email{" "}
                    </label>
                    <input
                      class="mail"
                      type="text"
                      placeholder="Enter Your Email "
                      name="email"
                      required
                    />

                    <label class="pass" required>
                      {" "}
                      Password{" "}
                    </label>
                    <input
                      class="pass"
                      type="password"
                      placeholder=" Enter Your Password "
                      name="password"
                      required
                    />

                    <CommonInput
                      inputClass="mail"
                      type="text"
                      placeholder="Enter Your Email "
                      name="email"
                      label="Email"
                      labelClass="user"
                      required
                    />
                    <CommonInput
                      inputClass="pass"
                      type="password"
                      placeholder="Enter Your Password"
                      name="password"
                      label="Password"
                      labelClass="pass"
                      required
                    />
                    <input type="submit" value="Login" />
                  </form> */}

                      <form className="signup-form " onSubmit={handleSubmit}>
                        <CommonInput
                          inputClass="mail"
                          type="text"
                          placeholder="Enter Your Email "
                          name="email"
                          label="Email"
                          labelClass="user"
                          // onChange={handleChange}
                          required
                        />
                        <CommonInput
                          inputClass="pass"
                          type="password"
                          placeholder="Enter Your Password"
                          name="password"
                          label="Password"
                          labelClass="pass"
                          required
                          // onChange={handleChange}
                        />

                        <input type="submit" value="Login  " />
                      </form>

                      <div className="for">
                        {/* <p className="forgot">Forgot Password?</p> */}
                      </div>
                      <div className="divider">
                        {" "}
                        <span className="line"> </span>{" "}
                        <span className="or"> OR </span>{" "}
                        <span className="line1"> </span>
                      </div>
                      <div className="forget-pass">
                        <button
                          className="account-pass"
                          onClick={() => navigate("/forgot-password")}
                        >
                          Forgot Password
                        </button>
                      </div>

                      <div className="create">
                        <button
                          className="account"
                          onClick={() => navigate("/signup-page")}
                        >
                          Create Account
                        </button>
                      </div>
                    </div>
                  </div>
                  <img className="img-fluid btm" src={btm} />
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
    </>
  );
};

export default Login;
