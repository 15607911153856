import { useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js"; // Make sure Bootstrap's JS is imported
import { useSearchParams } from "react-router-dom";
import { useLocation } from "react-router-dom";

// import { useHistory } from 'react-router-dom';

import React, { useEffect, useState } from "react";
import {
  headerBurgerIcon,
  mainLogo,
  headerSearchIcon,
  CLoseMenu,
  Cancel
} from "../../../Asserts/images/index";
import "./style.css";
import { Link, useNavigate } from "react-router-dom";

import Aos from "aos";
import "aos/dist/aos.css";
import { logoutRequest } from "../../../redux/slicers/user";
import { useDispatch } from "react-redux";
import { toastAlert } from "../../../utils";
import { ALERT_TYPES } from "../../../constants";
import { userLogoutRequest, Verifymessage, searchpost } from "../../../api";

const Header = (props) => {
  let [searchParams, setSearchParams] = useSearchParams();
  let [query, setQuery] = useState(searchParams.get("query") || "");
  const menuRef = useRef(null);

  const [ismenu, setIsMenu] = useState(false);

  const post_limit = localStorage.getItem("post_limit");
  const profile_pic_request = localStorage.getItem("profile_pic_request");

  console.log("profile_pic_request", profile_pic_request);

  const LogoutData = localStorage.getItem("userToken");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const usertype = localStorage.getItem("userrole");

  const openMenu = () => {
    setIsMenu(!ismenu);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside the menuRef element
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenu(false); // Close the menu
      }
    };

    // Add event listener to document
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  console.log("LogoutData", LogoutData);
  const handleLogout = async () => {
    try {
      navigate("/login-page");
      const response = await userLogoutRequest();

      if (response && response.status === true) {
        // Remove specific items from localStorage
        localStorage.removeItem("userToken");
        localStorage.removeItem("userrole");
        localStorage.removeItem("profile_pic_request");
        localStorage.removeItem("plan");
        localStorage.removeItem("email");

        localStorage.clear();

        // dispatch(logoutRequest());
        navigate("/login-page");
      } else {
        navigate("/login-page");
      }
    } catch (error) {}
  };

  useEffect(() => {
    Aos.init();
  }, []);

  const [formData, setFromdata] = useState();

  console.log("formData", formData);

  const handleChange = (e) => {
    setFromdata(e.target.value);

    // setQuery(e.target.value)
  };
  // const HandleSrearch = () => {
  //   navigate(`/formData`);
  // };

  // const HandleSrearch = () => {
  //   // Navigate to the formData page with the search value as a query parameter
  //   navigate(`/${encodeURIComponent(formData)}`);
  // };

  const modalRef = useRef(null);
  // const history = useHistory();
  const location = useLocation();

  const HandleSrearch = () => {
    const params = new URLSearchParams({ query: formData });
    navigate({ pathname: "/search/", search: params.toString() });
  };

  // const handlenavigate = () => {
  //   navigate("/model-page");
  // };

  const [Getmessage, setGetmessage] = useState("");

  const Getmodalvarify = async () => {
    try {
      const response = await Verifymessage();
      // console.log("response1", response?.data?.profile_pic_request);
      // const responsemesg = response.json();

      if (response?.data?.profile_pic_request == "Approved") {
        // navigate("/packages-page");
      } else {
        setGetmessage(response);
      }

      // setLoading(false);
    } catch (error) {
      console.error("Error in logging in:", error);
    }
  };

  useEffect(() => {
    Getmodalvarify();
  }, []);

  console.log("Getmessage", Getmessage);

  return (
    <>
      <div className="header_top">
        <div className="main_header">
          <div>
            <button className="header_menu_icon" onClick={openMenu}>
              <img src={headerBurgerIcon} alt="headerleftIcon" />
            </button>
          </div>

          <div className="" onClick={() => navigate("/")}>
            <img src={mainLogo} alt="main_logo" onClick={() => navigate("/")} />
          </div>

          <div>
            {!LogoutData ||
              (profile_pic_request === "Pending" ? null : (
                <button
                  // onClick={handlenavigate}
                  className="header_search_icon"
                  data-toggle="modal"
                  data-target=".search-modal"
                >
                  <img src={headerSearchIcon} alt="search_icon" />
                </button>
              ))}{" "}
          </div>

          <div id="mySidenav" class="sidenav">
            <button className="closebtn">close</button>
            <h6>gggggggg</h6>
            <h6>gggggggg</h6>
            <h6>gggggggg</h6>
            <h6>gggggggg</h6>
            <h6>gggggggg</h6>
          </div>
        </div>
      </div>

      <div
        class="modal fade search-modal"
        // id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        ref={modalRef}
      >
        <div class="modal-dialog modal-dialog-centered my-modal">
          <div class="modal-content">
            <div class="search-modal_content">
              <div className="search-modal_close">
                <a
                  href="javaScript:;"
                  className="cancel-a"
                  data-dismiss="modal"
                >
                  <img src={Cancel} />
                </a>
              </div>
              <div className="main_searchBar">
                <input
                  className="searchbar_input_field"
                  type="text"
                  value={formData} // Ensure props.value is managed in the parent component or local state
                  placeholder="Search post here"
                  onChange={handleChange}
                  name="search_value"
                />
                <button
                  onClick={HandleSrearch}
                  className="searchbar_actionBtn"
                  // className="cancel-a"
                  data-dismiss="modal"
                >
                  <img src={headerSearchIcon} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {ismenu && (
        <div
          ref={menuRef}
          className="menuSection"
          data-aos="fade-right"
          data-aos-anchor-placement="center-bottom"
          data-aos-duration="1000"
        >
          <div className="nav">
            <div className="offcanvas-logo">
              <Link className="offcanvas-logo_img">
                <img src={mainLogo} />
              </Link>
              <div className="closeBtn">
                <button className="header_menu_icon" onClick={openMenu}>
                  <img src={CLoseMenu} alt="headerleftIcon" />
                </button>
              </div>
            </div>
            <nav className="offcanvas-nav">
              <ul>
                {/* {usertype === 3 && ( */}
                {/* <li>
                  <Link to={"/#!"}>Messages</Link>
                </li> */}
                {/* )} */}
                {/* {usertype  == 2 ? "<li>
                  <Link to={"/"}> My Dashboard</Link>
                </li>" : ''}  */}
                {/* {usertype === 2 && ( */}
                {/* {!LogoutData || (




                  profile_pic_request == "Pending" ?  ( ) : (
                  <li>
                    <Link to={"/"}>My Dashboard</Link>
                  </li>)
                )}
 */}
                {!LogoutData ||
                  (profile_pic_request === "Pending" ? null : (
                    <li>
                      <Link to={"/"}>My Dashboard</Link>
                    </li>
                  ))}

                {!LogoutData ||
                  (profile_pic_request === "Pending" ? (
                    <li>
                      <Link to={"/verify-account-profile"}>Verify</Link>
                    </li>
                  ) : null)}

                {/* )} */}
                {/* {usertype == 2 && (
                <li>
                  <Link to={"/model-profile-page"}>My Profile</Link>
                </li>
                  )}  */}

                {/* {!LogoutData ||
                  (usertype == 2 && (
                    <li>
                      <Link to={"/model-profile-page"}>My Profile</Link>
                    </li>
                  ))} */}

                {!LogoutData ||
                  (profile_pic_request === "Pending"
                    ? null
                    : usertype == 2 && (
                        <li>
                          <Link to={"/model-profile-page"}>My Profile</Link>
                        </li>
                      ))}

                {/* {usertype == 3 && (
                <li>
                  <Link to={"/user-profile"}>My Profile</Link>
                </li>
              )}   */}

                {/* {!LogoutData ||
                  (usertype == 3 && (
                    <li>
                      <Link to={"/client-profile"}>My Profile</Link>
                    </li>
                  ))} */}

                {!LogoutData ||
                  (profile_pic_request === "Pending"
                    ? null
                    : usertype == 3 && (
                        <li>
                          <Link to={"/client-profile"}>My Profile</Link>
                        </li>
                      ))}

                {/* {usertype == 3 && (
                  <li>
                    <Link to={"/profile-page"}>My Profile</Link>
                  </li>
                )} */}

                {/* <li>
                  <Link to={"/add-post-page"}>Search posts</Link>
                </li> */}

                {/* post_limit !== 0 ? (  */}

                {!LogoutData ||
                  (profile_pic_request === "Pending" ? null : (
                    <li>
                      <Link to="/request-board">Request board</Link>{" "}
                    </li>
                  ))}
                {!LogoutData ||
                  (profile_pic_request === "Pending"
                    ? null
                    : usertype == 2 && (
                        <li>
                          <Link to="/add-post-page">Create post</Link>{" "}
                        </li>
                      ))}

                {/* 
                {usertype == 2 && (
                  <li>
                    <Link to="/add-post-page">Create post</Link>{" "}
                  </li>
                )} */}
                {/* ) : null */}

                <li>{/* <Link to={"/add-post-page"}>swag</Link> */}</li>
                {/* <li>
                  <Link to={"/signup-page"}>Sign up</Link>
                </li> */}
                {!LogoutData && (
                  <li>
                    <Link to={"/signup-page"}>Sign up</Link>
                  </li>
                )}
                {/* <li>
                  <Link to={"/"}>how it works</Link>
                </li> */}
                {/* <li>
                  <Link to={"/"}>FAQ</Link>
                </li> */}

                {/* {!LogoutData || (
                  <li>
                    <Link to={"/packages-page"}>Platinum bank</Link>
                  </li>
                )} */}

                {!LogoutData ||
                  (profile_pic_request === "Pending" ? null : (
                    <li>
                      <Link to={"/packages-page"}>Platinum bank</Link>
                    </li>
                  ))}

                {!LogoutData ||
                  (profile_pic_request === "Pending" ? null : (
                    <li>
                      <Link to={"/model-page"}>Models</Link>
                    </li>
                  ))}

                {/* {usertype === 2 && ( */}
                {/* <li>
                  <Link to={"/model-page"}>looking for page?</Link>
                </li> */}
                {/* )} */}

                {/* {!LogoutData && (
                  <li>
                      <Link to={"/faqs"}>Faqs</Link>
                  </li>
                )} */}

                {!LogoutData ||
                  (profile_pic_request === "Pending" ? null : (
                    <li>
                      <Link to={"/faqs"}>FAQs</Link>
                    </li>
                  ))}
              </ul>
            </nav>
          </div>
          <div className="offcanvas-logout">
            {LogoutData && <Link onClick={handleLogout}>Logout</Link>}

            {!LogoutData && <Link to={"/login-page"}>Sign In</Link>}
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
