import React, { useEffect, useState } from "react";
import { GetFaqs } from "../../api";
import Header from "../../Components/Layout/Header";
import Footer from "../../Components/Layout/Footer";
import Loader from '../../Components/loader'

import { Link, useNavigate } from "react-router-dom";

import {
  platinumFeetText,
  headerSearchIcon,
  legsImage01,
  imgTopCorner,
  imgBottomCorner,
  hundredPercentage,
  homeImg01,
  modelText,
  modelImg01,
  modelCardTopCorner,
  modelCardBottomCorner,
  modelImg02,
  modelImg03,
  modelImg04,
  framePic,
  hoverableImg,
  mainFrameImg,
  videoPlayIcon,
  modelImg05,
  modelImg06,
  modelImg07,
  howItWorksText,
  howItWorksImg,
  forClientText,
  forClientImg,
  forModelsText,
  formodelImg01,
  formodelImg02,
  faqText,
} from "../../Asserts/images/index";

import "./style.css";

import Aos from "aos";
import "aos/dist/aos.css";

const Faqs = () => {
  const [getFaqs, setgetFaqs] = useState();
const [isLoading , setLoading] = useState(false)
  const Faqs = async () => {
    try {
      const response = await GetFaqs();
      console.log("response", response);

      setgetFaqs(response?.data);
      setLoading(false);
    } catch (error) {
      console.error("Error in logging in:", error);

      // toastAlert(error, ALERT_TYPES.ERROR);
    }
  };

  console.log("getFaqs", getFaqs);
  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  useEffect(() => {
    Faqs();
  }, []);
  const navigate = useNavigate();
  const handleclick = () => {
    navigate("/model-page");
  };
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <div className="home_page_main">
      <div>
        <Header />
      </div>
      {isLoading ? (
        <Loader />
      ) : (
      <div className="models_section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div
                className="faq_heading_text_img text-center"
                data-aos="fade-left"
                data-aos-anchor-placement="center-bottom"
                data-aos-duration="2000"
              >
                <img src={faqText} />
              </div>
            </div>

            <div className="col-sm-12 col-lg-6 mx-auto">
              <div className="accordion" id="accordionExample">
              {getFaqs?.map((item, index) => (
        <div className="card" key={index}>
          <div className="card-header" id={`headingOne${index}`}>
            <h2 className="mb-0">
              <button
                className="btn faq_button btn-link btn-block text-left"
                type="button"
                onClick={() => handleToggle(index)}
                aria-expanded={openIndex === index}
                aria-controls={`collapseOne${index}`}
              >
                {item?.question}
              </button>
            </h2>
          </div>

          <div
            id={`collapseOne${index}`}
            className={`collapse ${openIndex === index ? 'show' : ''}`}
            aria-labelledby={`headingOne${index}`}
            data-parent="#accordionExample"
          >
            <div className="card-body">
              {item?.answer}
            </div>
          </div>
        </div>
      ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      )}
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Faqs;
