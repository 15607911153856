import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Header from "../../Components/Layout/Header";
import Footer from "../../Components/Layout/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTrash, faRemove } from "@fortawesome/free-solid-svg-icons";
import { Cancel } from "../../Asserts/images";
import { modelcatigorylist, Addmodelpost, modellist } from "../../api";
import dummy from "../../Asserts/images/dummy.jpg";
import { SelectBox } from "../../Components/CustomSelect";
import "./style.css";
import {
  SmallCancel,
  addPostImg1,
  addPostImg2,
  addPostImg3,
  pageBottomImg,
  uploadIcon,
  onfrontimage
} from "../../Asserts/images";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import Select from "react-select";
import Aos from "aos";
import "aos/dist/aos.css";
import CustomTextarea from "../../Components/CustomTextarea";
import { toastAlert } from "../../utils";
import { ALERT_TYPES } from "../../constants";
import { useNavigate } from "react-router-dom";
const AddPost = () => {
  const [file, setFile] = useState([]);

  const [files, setFiles] = useState([]);
  console.log("file", file);

  console.log("filesForm", files);

  const [profilebyid, setprofilebyid] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const handleclick = () => {
    navigate("/payment-page");
  };

  const [Formfile, setFormfile] = useState([]);
  const handleChange = (event) => {
    const selectedFiles = event.target.files;
    const filesArray = Array.from(selectedFiles);

    Promise.all(
      filesArray.map((file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      })
    ).then((dataUrls) => {
      setFiles((prevFiles) => [...prevFiles, ...filesArray]);
      setFile((prevFiles) => [...prevFiles, ...dataUrls]);
      setFormfile((prevFiles) => [...prevFiles, ...selectedFiles]);
    });
  };

  console.log("Formfile", Formfile);
  const navigate = useNavigate();

  const [userdata, setUserdata] = useState();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedPostType, setSelectedPostType] = useState(null);
  const [selectedPostOption, setSelectedPostOption] = useState(null);
  const [formData, setFormData] = useState({ friend: [] });
  const CATEGORY_OPTIONS = [
    { id: 0, title: "LOREM IPSUM" },
    { id: 1, title: "CLEAN" },
    { id: 2, title: "SKINNY" },
    { id: 3, title: "MIDDLE" },
    { id: 4, title: "MEDIUM" },
    { id: 5, title: "DIRTY" },
    { id: 6, title: "WIDE" }
  ];

  const POST_TYPES = [
    { id: 0, title: "FREE TEASE" },
    { id: 1, title: "PUBLIC" },
    { id: 2, title: "PRIVATE", subtext: "FOR CUSTOM ORDERS" }
  ];

 
const [SelectOptions, setSelectOptions] = useState([])
  const getModelList = async () => {
    try {
      const response = await modellist();
      console.log("response", response.data);
      const formattedModel = response.data.map((option) => ({ value: option.id, label: option.name }));
      setSelectOptions(formattedModel)
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    getModelList();
  }, []);

  const handlechanges = (e) => {
    const { name, value } = e.target;
    setFormData((prevdata) => ({
      ...prevdata,
      [name]: value
    }));
  };

  const [selectcatvalue, setselectcatvalue] = useState([]);
  console.log("file", file);

  const handleCategoryChange = (id, name) => {
    if (selectcatvalue.includes(name)) {
      // If the category is already selected, remove it
      setselectcatvalue(selectcatvalue.filter((catName) => catName !== name));
      // If it was the selected category, clear the selection
      if (selectedCategory === id) {
        setSelectedCategory(null);
      }
    } else {
      // If the category is not selected, add it
      setselectcatvalue([...selectcatvalue, name]);
      setSelectedCategory(id);
    }
  };

  console.log("selectcatvalue", selectcatvalue);

  const handlePostTypeChange = (id) => {
    if (id === selectedPostType) {
      setSelectedPostType(null);
      return;
    }
    setSelectedPostType(id);
  };
  const handlePostOptionChange = (id) => {
    if (id === selectedPostOption) {
      setSelectedPostOption(null);
      return;
    }
    setSelectedPostOption(id);
  };

  const boost_limit = localStorage.getItem("boost_limit");

  const [Postresponsemsg, setPostresponsemsg] = useState("");
  const [isblur, steIsblur] = useState(false);

  const [titleerror, setTitleError] = useState("");
  const [priceerror, setPriceError] = useState("");
  const handleSubmit = async (event) => {
    event.preventDefault();

    const formDataMethod = new FormData();
    formDataMethod.append("category_id", selectedCategory);
    formDataMethod.append("post_type", selectedPostOption);
    formDataMethod.append("type", selectedPostType);

    for (const key in formData) {
      if (key == "friend") {
        formDataMethod.append(key, JSON.stringify(formData[key]));
      } else {
        formDataMethod.append(key, formData[key]);
      }
    }
    files?.forEach((file, index) => {
      console.log(file, index);
      formDataMethod.append(`images[${index}][file]`, file);

      const isBlur = checkedIds.includes(index) ? "true" : "false";
      formDataMethod.append(`images[${index}][is_blur]`, isBlur);
    });

    try {
      const response = await Addmodelpost(formDataMethod);

      if (response?.status == true) {
        setPostresponsemsg(response?.msg);
        setModalShow(true);
      } else {
        // toastAlert(response.statusText, ALERT_TYPES.ERROR);
      }
    } catch (error) {
      console.error("Error in adding model post:", error); // Corrected the log message
    }

    // for (const key in formData) {
    //   if (key == "friend") {
    //     formDataMethod.append(key, JSON.stringify(formData[key]));
    //   } else {
    //     formDataMethod.append(key, formData[key]);
    //   }
    // }
    // files?.forEach((file, index) => {
    //   console.log(file, index);
    //   formDataMethod.append(`images[${index}][file]`, file);

    //   const isBlur = checkedIds.includes(index) ? "true" : "false";
    //   formDataMethod.append(`images[${index}][is_blur]`, isBlur);
    // });

    // try {
    //   const response = await Addmodelpost(formDataMethod);

    //   if (response?.status == true) {
    //     setPostresponsemsg(response?.msg);
    //     setModalShow(true);
    //   } else {
    //     // toastAlert(response.statusText, ALERT_TYPES.ERROR);
    //   }
    // } catch (error) {
    //   console.error("Error in adding model post:", error); // Corrected the log message
    // }
  };

  console.log("titleerror", titleerror);
  console.log("priceerror", priceerror);

  // what is callback function

  const handleGetcatigory = async () => {
    try {
      const response = await modelcatigorylist();
      console.log("response", response);
      setUserdata(response?.data);
    } catch (error) {
      console.error("Error in logging in:", error);
    }
  };

  useEffect(() => {
    Aos.init();
    handleGetcatigory();
  }, []);

  const handleChangeSelect = (selected) => {
    console.log('selected',selected);
    
    setFormData({
      ...formData,
      friend: selected
    });
  };

  const [checkedIds, setCheckedIds] = useState([]);
  const [imgblur, setImgblur] = useState(false);
  // const handleCheckboxChange = (id) => {
  //   setCheckedIds((prevCheckedIds) => {
  //     if (prevCheckedIds.includes(id)) {
  //       return prevCheckedIds.filter((checkedId) => checkedId !== id);
  //     } else {
  //       return [...prevCheckedIds, id];
  //     }
  //   });
  // };

  // const handleCheckboxChange = (id) => {
  //   setCheckedIds((prevCheckedIds) => {
  //     // Toggle the checkbox selection
  //     const newCheckedIds = prevCheckedIds.includes(id)
  //       ? prevCheckedIds.filter((checkedId) => checkedId !== id)
  //       : [...prevCheckedIds, id];

  //     // Set imgblur to true if any checkbox is checked, otherwise set it to false
  //     setImgblur(newCheckedIds.length > 0);

  //     return newCheckedIds;
  //   });
  // };

  console.log("checkedIds", checkedIds);
  const baseurl = `${process.env.REACT_APP_BASE_URL}/public/`;
  const handledelete = (indexToDelete) => {
    // Remove the file from all three states (files, file, formfile) by filtering them out based on the index
    setFiles((prevFiles) =>
      prevFiles.filter((_, index) => index !== indexToDelete)
    );
    setFile((prevFiles) =>
      prevFiles.filter((_, index) => index !== indexToDelete)
    );
    setFormfile((prevFiles) =>
      prevFiles.filter((_, index) => index !== indexToDelete)
    );
  };

  const [blurStates, setBlurStates] = useState({});

  // const handleCheckboxChange = (id) => {
  //   setCheckedIds((prevCheckedIds) => {
  //     const newCheckedIds = prevCheckedIds.includes(id)
  //       ? prevCheckedIds.filter((checkedId) => checkedId !== id)
  //       : [...prevCheckedIds, id];

  //     // Update blur state for the specific image related to the checkbox ID
  //     setBlurStates((prevBlurStates) => ({
  //       ...prevBlurStates,
  //       [id]: !prevBlurStates[id], // Toggle blur state for this specific ID
  //     }));

  //     return newCheckedIds;
  //   });
  // };

  const handleCheckboxChange = (id) => {
    setCheckedIds((prevCheckedIds) => {
      const isChecked = prevCheckedIds.includes(id);
      const newCheckedIds = isChecked
        ? prevCheckedIds.filter((checkedId) => checkedId !== id) // Uncheck
        : [...prevCheckedIds, id]; // Check

      // Update blur status based on checkbox state
      setBlurStates((prevBlurStatuses) => ({
        ...prevBlurStatuses,
        [id]: !isChecked // Set blur status for this ID
      }));

      return newCheckedIds;
    });
  };

  return (
    <>
      <section class="add-post-page">
        <div>
          <Header />
        </div>

        <section className="image-preview_sec">
          <div className="container">
            <div className="row">
              {file?.map((dataUrl, index) => (
                <div key={index} className="col-md-3">
                  <div className="image__preview">
                    <div className="d-flex justify-content-between   ">
                      <label className="custom_check-box">
                        <input
                          type="checkbox"
                          className="checkbox_input"
                          checked={checkedIds.includes(index)}
                          onChange={() => handleCheckboxChange(index)}
                        />
                        <span className="custom_check-text ml-2">Blur</span>
                      </label>

                      <label
                        className="custon_delete  btn"
                        onClick={() => handledelete(index)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                        {/* <span className="custom_check-text">Blur</span> */}
                        {/* <input
                        type="checkbox"
                        className="checkbox_input"
                        checked={checkedIds.includes(index)}
                        onChange={() => handleCheckboxChange(index)}
                      /> */}
                      </label>
                    </div>
                    {/* {imgblur == true ? (
                      <img src={onfrontimage} className="image__preview-img" />
                    ) : (
                      <img
                        src={dataUrl}
                        className="image__preview-img"
                        alt={`Image ${index}`}
                      />
                    )} */}

                    {blurStates[index] ? (
                      <img
                        src={dataUrl}
                        className="image__preview-img"
                        style={{ filter: "blur(20px)" }} // Apply blur filter
                        alt={`Blurred Image ${index}`}
                      />
                    ) : (
                      <img
                        src={dataUrl}
                        className="image__preview-img"
                        alt={`Image ${index}`}
                      />
                    )}
                  </div>
                </div>
              ))}

              <div className="col-md-3">
                <div class="image-preview_item">
                  <h3 className="image__preview-heading">Image/Video</h3>
                  <div class="upload__box">
                    <input
                      type="file"
                      accept="image/*"
                      name="image"
                      required
                      id="file1"
                      onChange={handleChange}
                      style={{ display: "none" }}
                    />

                    <label
                      for="file1"
                      onChange={handleChange}
                      className="upload__container-btn"
                      style={{ cursor: "pointer" }}
                    >
                      <div className="uoload-icon">
                        <img src={uploadIcon} />
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="add-post">
          <div className="container">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <div className="post-title">
                    <h3
                      className="title"
                      data-aos="fade-right"
                      data-aos-anchor-placement="center-bottom"
                      data-aos-duration="3000"
                    >
                      Post title
                    </h3>
                    <div className="input-group">
                      <input
                        required
                        name="post_title"
                        type="text"
                        onChange={handlechanges}
                        className="form-control post-title__form"
                        placeholder="Enter Title"
                      />
                      {/* {titleerror} */}
                    </div>
                    <div className="post__title-checkboxes">
                      <div className="price add__post-checboxes">
                        <h3>PRICE</h3>
                       
                        <div className="input-group">
                          <input
                            onChange={handlechanges}
                            name="price"
                            type="text"
                            className="form-control post-title__form price-amount"
                            placeholder=""
                            required
                          />
                          {/* {priceerror} */}
                        </div>
                      </div>

                      {POST_TYPES.map((item, index) => (
                        <div key={index} className="add__post-checboxes">
                          <label class="custom_check-box">
                            <span class="custom_check-text">
                              {item.title}
                              {item.subtext && (
                                <span className="private__span">
                                  {" "}
                                  (For custom orders)
                                </span>
                              )}
                            </span>
                            <input
                              required
                              type="checkbox"
                              class="chackbox_input"
                              // checked={selectedPostType === item.id}
                              onChange={() => handlePostTypeChange(item.id)}
                            />
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-6">
                  <CustomTextarea
                    label="Description"
                    labelClass="title"
                    placeholder="Description"
                    inputClass="form-control post-title__form"
                  />
                </div> */}
                <div className="col-md-6">
                  <h3
                    className="description-title"
                    data-aos="fade-left"
                    data-aos-anchor-placement="center-bottom"
                    data-aos-duration="3000"
                  >
                    DESCRIPTION
                  </h3>
                  <p className="description-text">
                    <textarea
                      name="post_description"
                      onChange={handlechanges}
                      className="form-control post-title__form"
                      required
                      placeholder="Enter Description"
                      id="des"
                    ></textarea>
                  </p>
                </div>
              </div>
              <div className="categories">
                <h3
                  className="categories_title"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-bottom"
                  data-aos-duration="3000"
                >
                  CATEGORIES
                </h3>
                <div className="categories_checkboxes">
                  {userdata?.map((item, index) => (
                    <label class="custom_check-box">
                      <span class="custom_check-text">{item.name}</span>
                      <input
                        // required
                        required={selectedCategory == null}
                        type="checkbox"
                        class="chackbox_input"
                        // checked={selectedCategory === item.id}
                        // onChange={() => handleCategoryChange(item.id)}
                        onChange={() =>
                          handleCategoryChange(item.id, item.name)
                        }
                      />
                    </label>
                  ))}
                </div>
              </div>
              <div className="tags-sec">
                <div className="row">
                  <div className="col-lg-4">
                    <h3
                      className="title"
                      data-aos="fade-right"
                      data-aos-anchor-placement="center-bottom"
                      data-aos-duration="3000"
                    >
                      TAGS MODELS
                    </h3>
                    <div className="tag-friends">
                      <Select
                        className="tags-btn"
                        value={formData?.friend}
                        isMulti
                        // required
                        options={SelectOptions}
                        onChange={handleChangeSelect}
                      />
                      {/* <a href="javascript:;" class="small-cancel">
                          <img src={SmallCancel} />
                        </a> */}
                    </div>

                    {/*   <button type="button"  >
                        2MANYKIDS{" "}
                        <a href="javascript:;" class="small-cancel">
                          <img src={SmallCancel} />
                        </a>
                      </button>
                      */}
                  </div>
                  <div className="col-lg-4">
                    <div className="post_options">
                      <h3
                        className="title"
                        data-aos="fade-left"
                        data-aos-anchor-placement="center-bottom"
                        data-aos-duration="3000"
                      >
                        POST OPTIONS
                      </h3>
                      <label class="custom_check-box">
                        <span class="custom_check-text">
                          Release for promotional use
                        </span>
                        <input
                          type="checkbox"
                          class="chackbox_input"
                          onChange={() => handlePostOptionChange(0)}
                        />
                      </label>
                    </div>
                  </div>
                  {boost_limit !== 0 ? (
                    <div className="col-lg-4">
                      <div className="post_options">
                        <h3 className="title d-lg-block d-none">&nbsp;</h3>
                        <label class="custom_check-box">
                          <span class="custom_check-text">
                            PIN AT TOP OF MY MEMBER PAGE
                          </span>
                          <input
                            type="checkbox"
                            class="chackbox_input"
                            // checked={selectedPostOption === 1}
                            onChange={() => handlePostOptionChange(1)}
                          />
                        </label>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="add-post_btns">
                    <button
                      class="  sign_actionBtn black-bg"
                      data-toggle="modal"
                      data-target=".exampleModal"
                    >
                      Preview{" "}
                    </button>
                    <button class="sign_actionBtn" type="submit">
                      SUBMIT
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <img src={pageBottomImg} className="page-bottom_img" />
      </section>

      <Modal
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="carousel-modal popUp_modal_1">
          {/* <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              {Postresponsemsg}
            </Modal.Title>
          </Modal.Header> */}
          <Modal.Body>
            {/* <p>
                  By continuing, you confirm that you are over 18 years old and
                  that you agree to our
                </p>
                <p>
                  <Link to="/">Terms of Use.</Link>
 

                </p>
                <button
                  onClick={() => setModalShow(false)}
                  className="popUp_modal_1-btn paybtn"
                >
                  <span>I am over 18,</span>
                  <h3>LET’S PARTY!</h3>
                </button>

                <p className="mb-0 mt-5">
                  <Link to="https://www.google.com">Oops, I’m under 18....</Link>
                </p> */}
            <h2 className="addpostmsgres">{Postresponsemsg}</h2>
          </Modal.Body>
          {/* <button onClick={() => {setModalShow(false) , navigate("/")}}>Ok </button> */}
          <button
            className="sign_actionBtn"
            onClick={() => {
              setModalShow(false);
              navigate("/");
            }}
          >
            Ok
          </button>
        </div>
      </Modal>

      <div className="col-md-12">
        <div
          class="modal fade exampleModal"
          // id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered my-modal">
            <div class="modal-content">
              <div className="carousel-modal">
                {/* <div class="carousel-header">
                                                <div className="carousel-icons">
                                                    <div className="caarousel-icons_inner">
                                                        <a
                                                            href="javaScript:;"
                                                            className="cancel"
                                                            data-dismiss="modal"
                                                        >
                                                            <img src={Cancel} />
                                                        </a>
                                                        <a type="button" onClick={handleHeart} className="heart">
                                                             <i className={`fa ${hearts ? 'fa-solid' : 'fa-regular'} fa-heart`}></i>
                                                        </a>

                                                    </div>
                                                </div>
                                                
                                            </div> */}

                {/* model preview modal */}
                <div class="carousel-header mb-5">
                  <div className="carousel-icons">
                    <div className="caarousel-icons_inner">
                      <a
                        href="javaScript:;"
                        className="cancel"
                        data-dismiss="modal"
                      >
                        <img src={Cancel} />
                      </a>
                      {/* 
                                {LogoutData && (
                                  <a
                                    type="button"
                                    onClick={() => handleHeart(profilebyid?.id)}
                                    className="heart"
                                  >
                                    <i
                                      className={`  ${
                                        modellists?.is_favourite == true ||
                                        hearts
                                          ? "fa-solid"
                                          : "fa-regular"
                                      } fa-heart`}
                                    ></i>
                                  </a>
                                )} */}
                    </div>
                  </div>
                </div>
                <Swiper
                  slidesPerView={"auto"}
                  centeredSlides={true}
                  spaceBetween={15}
                  navigation={true}
                  // pagination={{
                  //   clickable: true,
                  // }}
                  modules={[Navigation]}
                  className="mySwiper mt-5"
                >
                  {" "}
                  {/* {file?.map((dataUrl) => (
                    <SwiperSlide>
             
                      <img
                        src={dataUrl ? dataUrl : dummy}
                        className="modalpic"
                      />
                 
                    </SwiperSlide>
                  ))} */}
                  {/* {blurStates[index] ? (
                      <img
                        src={onfrontimage}
                        className="image__preview-img"
                        style={{ filter: "blur(5px)" }} // Apply blur filter
                        alt={`Blurred Image ${index}`}
                      />
                    ) : 
                    // (
                    //   <img
                    //     src={dataUrl}
                    //     className="image__preview-img"
                    //     alt={`Image ${index}`}
                    //   />
                    // )
                       (file?.map((dataUrl) => (
                    <SwiperSlide>
             
                      <img
                        src={dataUrl ? dataUrl : dummy}
                        className="modalpic"
                      />
                 
                    </SwiperSlide>
                  ))
                )} */}
                  {file?.map((dataUrl, index) => (
                    <SwiperSlide key={index}>
                      {" "}
                      {/* Add key to avoid warnings */}
                      <img
                        src={
                          blurStates[index]
                            ? onfrontimage
                            : dataUrl
                            ? dataUrl
                            : dummy
                        } // Show onfrontimage if index matches
                        className="modalpic"
                        style={{
                          filter: blurStates[index] ? "blur(5px)" : "none" // Conditionally apply blur
                        }}
                        alt={`Image ${index}`} // Add unique alt tag
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>

                <div className="carousel-footer">
                  <h4 className="carousel-footer_title">
                    <h3 className="modal-title mt-3">
                      {formData?.post_title} :)
                    </h3>
                  </h4>
                  <h2>Category : </h2>
                  {selectcatvalue?.map((item) => (
                    <p className=" d-flex justify-content-center mx-auto">
                      {item}
                    </p>
                  ))}

                  <p className="carousel-footer_body">
                    {formData?.post_description}
                  </p>
                  <p className="carousel-footer_price">${formData?.price} </p>

                  <div className=" post_detail_view d-flex g-4  ">
                    {/* <button
                      data-toggle="modal"
                      data-target=".exampleModaltip"
                      className="carousel-footer_button mb-3"
                    >
                      {" "}
                      {/* <i class="fas fa-donate profile_btn_icons"></i>{" "} */}
                    {/* Send Tip{" "} */}
                    {/* </button>   */}
                    {/* {profilebyid?.price !== 0 ? ( */}
                    <button
                      onClick={handleSubmit}
                      // className="carousel-footer_button "
                      data-dismiss="modal"
                      className="carousel-footer_button mb-3"
                    >
                      Submit
                    </button>
                  </div>
                  {/* ) : (
                              ""
                            )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <Footer />
      </div>
    </>
  );
};

export default AddPost;

// <Swiper
// // navigation={true}
// modules={[Navigation]}
// className="mySwiper"
// >
// <SwiperSlide>
//   <div class="upload__box">
//     <input
//       type="file"
//       accept="image/*"
//       name="image"
//       required
//       id="file1"
//       onChange={handleChange}
//       style={{ display: "none" }}
//     />

//     <label
//       for="file1"
//       onChange={handleChange}
//       className="upload__container-btn"
//       style={{ cursor: "pointer" }}
//     >
//       <div className="uoload-icon">
//         <img src={uploadIcon} />
//       </div>
//     </label>
//   </div>
// </SwiperSlide>
// <SwiperSlide>
//   <div class="upload__box"></div>
// </SwiperSlide>
// <SwiperSlide>
//   <div class="upload__box">
//     <label
//       for="file1"
//       onChange={handleChange}
//       className="upload__container-btn"
//       style={{ cursor: "pointer" }}
//     >
//       <div className="uoload-icon">
//         <img src={uploadIcon} />
//       </div>
//     </label>
//   </div>
// </SwiperSlide>
// </Swiper>
