import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Header from "../../Components/Layout/Header";
import Footer from "../../Components/Layout/Footer";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import Loader from "../../Components/loader";
import star from "../../Asserts/images/Star.png";
import mail from "../../Asserts/images/email.png";

import {
  modelcatigorylist,
  Addmodelpost,
  RequestBoardlist,
  socketsendmessage,
  SendMessageRequest,
  modellist
} from "../../api";
import { SelectBox } from "../../Components/CustomSelect";
import "./style.css";
import {
  SmallCancel,
  addPostImg1,
  addPostImg2,
  addPostImg3,
  pageBottomImg,
  platinumFeetText,
  uploadIcon
} from "../../Asserts/images";

import platinumlogo from "../../Asserts/images/platinumlogo.png";

import { Swiper, SwiperSlide } from "swiper/react";
// file[0] : (binary)
// file[2] : (binary)
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import Select from "react-select";
import Aos from "aos";
import "aos/dist/aos.css";
import CustomTextarea from "../../Components/CustomTextarea";
import { toastAlert } from "../../utils";
import { ALERT_TYPES } from "../../constants";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faChevronDown,
  faChevronUp,
  faHeart,
  faMessage,
  faStar
} from "@fortawesome/free-solid-svg-icons";
const RequestBoard = () => {
  const navigate = useNavigate();
  const [modellistsprofileview, setModelprofileview] = useState({});
  const [formDatamessage, setformDatamessage] = useState({ message: "" });
  const [isCheckeds, setIsCheckeds] = useState(true);
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(true);

  const [isExpanded, setIsExpanded] = useState(false);

  // const toggleReadMore = (id) => {
  //   setIsExpanded(!isExpanded);
  // };
  const handleChangeSelect = (selected) => {
    console.log("selected", selected);

    setFormData({
      ...formData,
      friend: selected
    });
  };
  const [Getboardlising, setGetboardlising] = useState([]);
  const handlePostOptionChange = (id) => {
    setIsCheckeds((prev) => !prev); // Toggle the checked state
    console.log("Checkbox ID:", id);
    console.log("Checkbox checked:", !isCheckeds); // Log the new state
  };

  const handlechangemessage = (e) => {
    const { name, value } = e.target;
    setformDatamessage((prevdata) => ({
      ...prevdata,
      [name]: value
    }));
  };

  const [SelectOptions, setSelectOptions] = useState([]);
  const getModelList = async () => {
    try {
      const response = await modellist();
      console.log("response", response.data);
      const formattedModel = response.data.map((option) => ({
        value: option.id,
        label: option.name
      }));
      setSelectOptions(formattedModel);
      console.log("response", response.data);
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    getModelList();
  }, []);
  const BoardList = async () => {
    try {
      const response = await RequestBoardlist();
      const data = response?.data;
      if (response?.status == true) {
        console.log("datadatail", data);
        setGetboardlising(data);
        setLoading(false);
      } else {
        // toastAlert(response.statusText, ALERT_TYPES.ERROR);
        setLoading(false);
        setGetboardlising(data);
        console.log("packege ", response.statusText);
      }
      // setModellists(response?.data);
    } catch (error) {
      console.error("Error in logging in:", error);

      // toastAlert(error, ALERT_TYPES.ERROR);
    }
  };

  console.log("Getboardlising", Getboardlising);
  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "long", // "September"
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZoneName: "short" // "GMT", "PST", etc.
    };

    const date = new Date(dateString);
    return date.toLocaleString("en-US", options);
  };

  const [expandedIds, setExpandedIds] = useState([]);

  const toggleReadMore = (id) => {
    setExpandedIds((prev) => {
      if (prev.includes(id)) {
        // If the ID is already expanded, remove it
        return prev.filter((itemId) => itemId !== id);
      } else {
        // If not, add it to the expanded list
        return [...prev, id];
      }
    });
  };
  useEffect(() => {
    BoardList();
  }, []);
  const userRole = localStorage.getItem("userrole");
  const handleNavigate = (user_id = false, model_id = false) => {
    if (userRole == 2) {
      navigate(`/model-profile-page?user_id=${user_id}`);
    } else if (userRole == 3) {
      navigate(`/client-profile?model_id=${model_id}`);
    }
  };
  const handleSubmit = (event) => {
    if (formDatamessage.message != "") {
      console.log(
        'formDatamessage.message != ""',
        formDatamessage.message != "",
        formDatamessage.message
      );
      if (isCheckeds == true) {
        handleSubmitmessage(event);
      } else {
        handlesendmessage(event);
      }
    }
  };

  const handleSubmitmessage = async (event) => {
    event.preventDefault();

    // Create FormData object
    const formDataMethod = new FormData();
    for (const key in formDatamessage) {
      formDataMethod.append(key, formDatamessage[key]);
    }

    setLoading(true);

    try {
      const response = await SendMessageRequest(
        formDataMethod,
        formData.friend.value
      );

      if (response?.status == true) {
        // Success handling
        toastAlert(response.msg, ALERT_TYPES.SUCCESS);

        setformDatamessage((prev) => ({
          ...prev,
          message: ""
        }));
        setIsCheckeds(false);
      } else {
        // Error handling
        toastAlert(response.msg || response.statusText, ALERT_TYPES.ERROR);
      }
    } catch (error) {
      console.error("Error in adding model post:", error);
      toastAlert(error.message || "An error occurred", ALERT_TYPES.ERROR);
    } finally {
      // Always set loading to false at the end
      setLoading(false);
    }
  };
  const handlesendmessage = async (event) => {
    event.preventDefault();

    // Create FormData object
    const formDataMethod = new FormData();
    for (const key in formDatamessage) {
      formDataMethod.append(key, formDatamessage[key]);
    }
    formDataMethod.append("receiver_id", modellistsprofileview?.id);

    setLoading(true);
    // groups();
    try {
      const response = await socketsendmessage(formDataMethod);

      if (response?.status == true) {
        // Success handling
        toastAlert(response.msg, ALERT_TYPES.SUCCESS);

        setformDatamessage((prev) => ({
          ...prev,
          message: ""
        }));

        setIsCheckeds(false);
      } else {
        // Error handling
        toastAlert(response.msg || response.statusText, ALERT_TYPES.ERROR);
      }
    } catch (error) {
      console.error("Error in adding model post:", error);
      toastAlert(error.message || "An error occurred", ALERT_TYPES.ERROR);
    } finally {
      // Always set loading to false at the end
      setLoading(false);
    }
  };
  return (
    <>
      <section class="add-post-page">
        <div>
          <Header />
        </div>
        {loading == true ? (
          <Loader />
        ) : (
          <section className="image-preview_sec">
            <div className="container">
              {userRole != 2 && (
                <div
                  class="packages-btns m-3"
                  data-toggle="modal"
                  data-target="#modalTwo"
                >
                  <button type="" class="gold-btn">
                    New Request
                  </button>
                </div>
              )}
              <div className="col-md-6">
                <div
                  className="modal fade"
                  tabindex="-1"
                  aria-labelledby="exampleModaltip"
                  aria-hidden="true"
                  id="modalTwo"
                >
                  <div className="modal-dialog modal-dialog-centered my-modal">
                    <div className="modal-content">
                      <div className="carousel-modal">
                        {/* <div className="carousel-header mb-3">
                            <div className="carousel-icons">
                              <div className="carousel-icons_inner">
                                <a
                                  href="javascript:void(0);"
                                  className="cancel"
                                  data-dismiss="modal"
                                >
                                  <img src={Cancel} alt="Cancel" />
                                </a>
                              </div>
                            </div>
                          </div> */}

                        <div className="row px-4">
                          {/* <div className="carousel-header mb-3">
                            <div className="carousel-icons">
                              <div className="carousel-icons_inner">
                                <a
                                  href="javascript:void(0);"
                                  className="cancel"
                                  data-dismiss="modal"
                                >
                                  <img src={Cancel} alt="Cancel" />
                                </a>
                              </div>
                            </div>
                          </div> */}

                          <div className="col-md-12 mt-4">
                            <h3
                              className="following_heading"
                              data-aos="fade-right"
                              data-aos-anchor-placement="center-bottom"
                              data-aos-duration="3000"
                            >
                              Send New Request
                            </h3>
                            <div className="divider_row"></div>
                          </div>

                          {/* <div
                              className="col-md-4 choose_amount_column payment_right_side"
                              data-aos="fade-up"
                              data-aos-anchor-placement="center-bottom"
                              data-aos-duration="3000"
                            >
                              <div className="payment_img_div">
                                <img
                                  className="img-fluid payment_img"
                                  src={modelImg02}
                                  alt="Brittanyvues"
                                />
                                <div className="model_div">
                                  <div className="image_with_text_row">
                                    <img
                                      className="img-fluid model_img"
                                      src={userProfilePic}
                                      alt="User Profile"
                                    />
                                    <p className="profile_name_one">
                                      Brittanyvues
                                    </p>
                                  </div>
                                  <div className="image_with_text_row_two">
                                    <p className="free_locked_text">
                                      <span className="unlocked_icon">
                                        <i className="fa-solid fa-unlock"></i>
                                      </span>
                                      Free
                                    </p>
                                    <p className="lock_text_clr free_locked_text">
                                      <span className="locked_icon">
                                        <i className="fa-solid fa-lock"></i>
                                      </span>
                                      Locked
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div> */}

                          <div
                            className="col-lg-12   choose_amount_column d-flex"
                            data-aos="fade-up"
                            data-aos-anchor-placement="center-bottom"
                            data-aos-duration="3000"
                          >
                            {/* <h4 className="text-white mt-2 mb-4">
                                Choose Amount
                              </h4> */}
                            {/* <div className="tipgap row mx-auto">
                                {[100, 200, 300, 400, 450, 500].map(
                                  (amount, index) => (
                                    <div
                                      key={index}
                                      className="paytips col-md-3 mb-4 justify-content-center"
                                    >
                                      <input
                                        type="radio"
                                        id={`t${index + 1}`}
                                        checked={selectedTip === index + 1}
                                        onChange={() =>
                                          handleRadioChange(index + 1)
                                        }
                                      />
                                      <label htmlFor={`t${index + 1}`}>
                                        ${amount}
                                      </label>
                                    </div>
                                  )
                                )}
                              </div> */}
                          </div>
                          <div className="col-lg-4">
                            <h6 className="text-start"> SELECT MODEL</h6>
                            <div className="tag-friends">
                              <Select
                                className="tags-btn"
                                value={formData?.friend}
                                isMulti={false}
                                // required
                                options={SelectOptions}
                                onChange={handleChangeSelect}
                              />
                              {/* <a href="javascript:;" class="small-cancel">
                          <img src={SmallCancel} />
                        </a> */}
                            </div>

                            {/*   <button type="button"  >
                        2MANYKIDS{" "}
                        <a href="javascript:;" class="small-cancel">
                          <img src={SmallCancel} />
                        </a>
                      </button>
                      */}
                            <div className="price add__post-checboxes mt-3">
                              <h6 className="text-start">
                                PRICE <span>{`($)`}</span>{" "}
                              </h6>

                              <div className="input-group">
                                <input
                                  name="price"
                                  type="number"
                                  className="form-control post-title__form price-amount bg-transparent"
                                  placeholder=""
                                  required
                                  onChange={handlechangemessage}
                                />
                                {/* {priceerror} */}
                              </div>
                            </div>
                          </div>
                          <div className="profile_detail_message col-md-12 mt-2">
                            <div className="d-flex justify-content-between flex-wrap">
                              <h6 className="namin"> Message </h6>{" "}
                              {/* <input type="checkbox" /> */}
                            </div>

                            <span className="messageprofiledetail">
                              <textarea
                                value={formData?.message}
                                placeholder="Enter description"
                                type="text"
                                name="message"
                                className="nam w-100"
                                onChange={handlechangemessage}
                                maxlength="300"
                              >
                                {" "}
                              </textarea>
                            </span>

                            <div className="mb-4">
                              <h4> Stay Classy! </h4>
                              <p>
                                Message Must be respectful and adide by the
                                messageing Terms if Use,
                              </p>
                              <p>Thank You!: </p>

                              <button
                                disabled={formDatamessage.message.trim() == ""}
                                onClick={handleSubmit}
                                data-dismiss="modal"
                                className="paybtn mt-4"
                              >
                                Send It !
                              </button>
                            </div>
                          </div>

                          {/* <button
                                data-dismiss="modal"
                                onClick={handlepay}
                                className="paybtn mt-4"
                              >
                                Pay Now
                              </button> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                {Getboardlising && Getboardlising.length > 0 ? (
                  Getboardlising?.map((items) => (
                    <div className="col-md-4 mb-4">
                      <div
                        className="platinum_cardd"
                        onClick={() =>
                          handleNavigate(items?.user_id, items.model_id)
                        }
                      >
                        <div className="platinum_cardd_header">
                          <div class="platinum_cardd-left">
                            <h3>{items?.detail.name}</h3>
                            <p> {formatDate(items?.created_at)} Minutes Ago</p>
                          </div>
                          <div className="platinum_cardd-right">
                            <i className=" fa-regular fa-heart"></i>
                            <img src={platinumlogo} alt="" />
                          </div>
                        </div>
                        <div className="platinum_cardd_body">
                          <div className="read-more-container">
                            <button
                              onClick={() => toggleReadMore(items?.id)}
                              className="read-more-button"
                            >
                              {/* {isExpanded ? "Read Less" : "Read More"} */}
                              <FontAwesomeIcon
                                // icon={isExpanded ? faChevronUp : faChevronDown

                                // }
                                icon={
                                  expandedIds.includes(items.id)
                                    ? faChevronUp
                                    : faChevronDown
                                }
                              />
                            </button>
                            <div
                              className={`text ${
                                // isExpanded ? "expanded" : "collapsed"
                                expandedIds.includes(items.id)
                                  ? "expanded"
                                  : "collapsed"
                              }`}
                            >
                              {/* {items?.message.split(0 , 20)} */}
                              {`${items?.message?.slice(0, 20)}...`}
                            </div>
                          </div>
                          <div className="platinum_cardd_body_star">
                            {/* <FontAwesomeIcon icon={faStar} /> */}
                            <img src={star} />
                          </div>
                        </div>
                        <div className="platinum_cardd_footer">
                          <div className="platinum_cardd_footer_left">
                            {/* <FontAwesomeIcon icon={faMessage} /> */}
                            <img src={mail} />
                            <p>Send Message</p>
                          </div>
                          <div className="platinum_cardd_footer_right">
                            <p>$12 USD</p>
                            <i className=" fa-regular fa-heart"></i>
                          </div>
                        </div>
                      </div>{" "}
                    </div>
                  ))
                ) : (
                  <div className="not-found">Request Not Available</div>
                )}
              </div>
            </div>
          </section>
        )}
        <img src={pageBottomImg} className="page-bottom_img" />
      </section>

      <div>
        <Footer />
      </div>
    </>
  );
};

export default RequestBoard;
