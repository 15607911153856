import React from "react";
import Header from "../../Components/Layout/Header";
// import Footer from '../../Components/Layout/Footer';
import { mainLogo, top, btm } from "../../Asserts/images/index";
import CommonInput from "../../Components/CustomInput";
import { SelectBox } from "../../Components/CustomSelect";
import "./style.css";
import { useState } from "react";
import { userSignUpRequest } from "../../api";
import { toastAlert } from "../../utils";
import { useNavigate } from "react-router-dom";
import { ALERT_TYPES } from "../../constants";
import Loader from "../../Components/loader";
import { loginSuccess } from "../../redux/slicers/user";
import { useDispatch } from "react-redux";

const SignUp = () => {
  //STATES
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [accountType, setAccountType] = useState("1");
  const [isLoading, setisLoading] = useState(false);
  const [porifilemage, setPorifilemage] = useState("");
  const [formDatas, setFormDatas] = useState({
    image: "",
  });
  //CONST VALS
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ACCOUNT_TYPE_OPTIONS = [
    { id: "1", name: "Client" },
    { id: "2", name: "Model" },
  ];
  console.log("porifilemage", porifilemage);
  //HANDLERS

  // const handlefile = (e) => {
  //   const file = e.target.file[0];
  //   if (file) {
  //     const filename = file;
  //     // setFormdata((prevfile) => ({
  //     //   ...prevfile,
  //     //   image: filename,
  //     // }));
  //     setPorifilemage(filename)
  //   }
  // };

  // const handlefile = (event) => {
  //   const file = event.target.files[0];
  //   // console.log(file.name)
  //   if (file) {
  //     const fileName = file;
  //     // setFormDatas((prevData) => ({
  //     //   ...prevData,
  //     //   image: fileName,
  //     // }));
  //     setPorifilemage(fileName);
  //   }
  //   // console.log(formData);
  // };
  const [formData, setFormData] = useState({
    image: "", // Initialize image as an empty string
  });

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    console.log(formData);
  };

  const filehandleChange = (event) => {
    const file = event.target.files[0];
    // console.log(file.name)
    if (file) {
      const fileName = file;
      setFormData((prevData) => ({
        ...prevData,
        image: fileName,
      }));
    }
    console.log(formData);
  };

  // const handlefile = (e) => {
  //   setPorifilemage(e.target.files[0]);
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (formData.password === formData.password_confirmation) {
      console.log("formData1", formData);
      const formDataMethod = new FormData();
      for (const key in formData) {
        formDataMethod.append(key, formData[key]);
      }

      setisLoading(true); // Start loading

      try {
        const response = await userSignUpRequest(
          accountType === "1" ? "user" : "model",
          formDataMethod
        );

        if (response && response.success === true) {
          const userToken = response.data.token;

          // Simulate a delay for 3 seconds with loader active
          setTimeout(() => {
            // Stop loading after 3 seconds
            setisLoading(false);

            if (response.data?.user_role == 2) {
              localStorage.setItem("userToken", userToken);

              localStorage.setItem(
                "profile_pic_request",
                response.data?.profile_pic_request
              );
              localStorage.setItem("userrole", response.data?.user_role);
              localStorage.setItem("email", response.data?.email);
              toastAlert(response.message, ALERT_TYPES.SUCCESS);
              navigate("/verify-account-profile");
            } else if (response.data?.user_role == 3) {
              localStorage.setItem("userToken", userToken);
              localStorage.setItem("userrole", response.data?.user_role);
              localStorage.setItem("email", response.data?.email);
              toastAlert(response.message, ALERT_TYPES.SUCCESS);
              localStorage.setItem(
                "profile_pic_request",
                response.data?.profile_pic_request
              );

              navigate("/model-page");
            }
            // else if (response.data?.user_role == 3) {
            //   toastAlert(response.message, ALERT_TYPES.SUCCESS);
            // }

            dispatch(loginSuccess(response.data));
          }, 3000);
        } else if (response.success === false) {
          toastAlert(response.message, ALERT_TYPES.SUCCESS);
          setisLoading(false);
        } else {
          setisLoading(false); // Stop loading if the response is not successful
          // toastAlert(response.statusText, ALERT_TYPES.ERROR);
        }
      } catch (error) {
        console.error("Error in signing up:", error);
        setisLoading(false); // Stop loading in case of an error
        toastAlert(error.message || "An error occurred", ALERT_TYPES.ERROR);
      }
    } else {
      setPasswordsMatch(false);
      // Handle password mismatch
    }
  };

  // const handleChangeSelect = (selected) => {
  //   setFormData({
  //     ...formData,
  //     friend: selected,
  //   });
  //   console.log(formData);
  // };
  return (
    <div>
      <Header />
      {isLoading ? (
        <Loader />
      ) : (
        <section className="log sign">
          <div className="black mx-auto">
            <div className="container">
              <div className="gold">
                <img className="img-fluid top" src={top} />
                <div className="row">
                  <div className="col-md-12">
                    <div className="imagerow">
                      <img className="img-fluid " src={mainLogo} />
                    </div>
                    <h4 className="sign">Welcome! Sign up to continue</h4>
                    {/* <p className="txt">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's{" "}
                    </p> */}

                    <form className="signup-form " onSubmit={handleSubmit}>
                      <CommonInput
                        inputClass="mail"
                        type="file"
                        placeholder="Upload Profile picture  "
                        name="image"
                        label="Upload Profile picture "
                        labelClass="user"
                        onChange={filehandleChange}
                        required
                      />

                      <CommonInput
                        inputClass="text"
                        type="text"
                        placeholder="Enter Your Name "
                        name="name"
                        label="User Name"
                        labelClass="user"
                        onChange={handleChange}
                        required
                      />

                      <CommonInput
                        inputClass="mail"
                        type="text"
                        placeholder="Enter Your Email "
                        name="email"
                        label="Email"
                        labelClass="user"
                        onChange={handleChange}
                        required
                      />
                      <CommonInput
                        inputClass="pass"
                        type="password"
                        placeholder="Enter Your Password"
                        name="password"
                        label="Password"
                        labelClass="pass"
                        required
                        onChange={handleChange}
                      />
                      <CommonInput
                        inputClass="pass"
                        type="password"
                        placeholder="Re-Enter Your Password"
                        name="password_confirmation"
                        label="Re-Enter Password"
                        labelClass="pass"
                        required
                        onChange={handleChange}
                      />

                      <SelectBox
                        option={ACCOUNT_TYPE_OPTIONS}
                        value={accountType}
                        keepSelected
                        selectClass="select"
                        onChange={(e) => {
                          console.log(e.target.value);
                          setAccountType(e.target.value);
                        }}
                        label="Account Type"
                        labelClass="pass"
                      />
                      {!passwordsMatch && (
                        <p style={{ color: "red" }}>Passwords do not match</p>
                      )}

                      <input type="submit" value="Sign Up" />
                    </form>
                    <div className="divider">
                      {" "}
                      <span className="line"> </span>{" "}
                      <span className="or"> OR </span>{" "}
                      <span className="line1"> </span>
                    </div>
                    <div className="create">
                      <button
                        className="account"
                        onClick={() => navigate("/login-page")}
                      >
                        Back To Login
                      </button>
                    </div>
                  </div>
                </div>
                <img className="img-fluid btm" src={btm} />
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default SignUp;
