import React, { useEffect, useState } from "react";
import Header from "../../Components/Layout/Header";
import Footer from "../../Components/Layout/Footer";

import { Swiper, SwiperSlide } from "swiper/react";
import dummy from "../../Asserts/images/dummy.jpg";
// Import Swiper styles
import { toastAlert } from "../../utils";
import { ALERT_TYPES } from "../../constants";
import Pushers from "./pucher";
import "swiper/css";
import "swiper/css/pagination";
import { Link, useParams } from "react-router-dom";
import Loader from "../../Components/loader";
import { Navigation } from "swiper/modules";
import {
  Getmodelpostlist,
  Userprogileview,
  UserUnflowmodel,
  UserPostUnflowmodel,
  profileunlockbyid,
  profileviewbyidmodel,
  socketsendmessage,
  Create_group,
  Group_list,
  SendMessageRequest
} from "../../api";

import {
  locked_icon,
  onfrontimage,
  modelCardTopCorner,
  modelCardBottomCorner,
  modelImg02,
  userProfilePic,
  Cancel
} from "../../Asserts/images/index";

import "./style.css";

import Aos from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
const ModelDetail = () => {
  const role = localStorage.getItem("userrole");
  const [uselist, setUserlist] = useState([]);
  const [getpostid, setgetpostid] = useState();
  const [unlock, setunlockshow] = useState(false);
  const [isCheckeds, setIsCheckeds] = useState(false);
  const user_id = localStorage.getItem("user_id");
  const [formData, setFormData] = useState([]);
  const [Recivemessage, setRecivemessage] = useState();
  const [fileName, setFileName] = useState("");

  const messagesEndRef = useRef(null);
  const chatDivRef = useRef(null);

  console.log("isCheckedsdtl", isCheckeds);

  const scrollToBottom = () => {
    if (chatDivRef.current) {
      chatDivRef.current.scrollTop = chatDivRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, []);

  const [message, setMessage] = useState({
    message: "",
    image: null
  });

  const channel = Pushers.subscribe("mike-smith");

  const pusherhit = () => {
    // Event handler for new messages
    channel.bind("message-event", function (data) {
      const chatData = data?.chatData;
      const decodedMessage = {
        id: chatData.id,
        sender_id: chatData.sender_id,
        reciever_id: chatData.reciever_id,
        message: chatData.message,
        filePath: chatData.filePath,
        created_at: new Date(chatData.created_at),
        updated_at: new Date(chatData.updated_at)
      };
      setFormData((prevMessages) => {
        // Check if the message already exists
        const messageExists = prevMessages.some(
          (message) => message.id === decodedMessage.id
        );
        if (!messageExists) {
          return [...prevMessages, decodedMessage];
        }
        return prevMessages;
      });
    });

    // Clean up WebSocket subscription
    return () => {
      channel.unbind("message-event");
      channel.unsubscribe();
    };
  };
  useEffect(() => {
    pusherhit();
  }, []);

  const [images, setImage] = useState({});

  const [inputValue, setInputValue] = useState("");
  const handleChange = (e) => {
    setInputValue(e.target.value);
  };
  const filteredList = uselist.filter((item) =>
    item?.name?.toLowerCase().includes(inputValue.toLowerCase())
  );

  const sendMessage = async (event) => {
    event.preventDefault();

    // toast.success("Post Add Successfully");

    const formDataMethod = new FormData();
    // formDataMethod.append('reciever_id', id == getreceverid ? id : getreceverid);
    formDataMethod.append(
      "receiver_id",
      getreceveridtrue != true ? id : getreceverid
    );

    // id ? id : getreceverid
    // setFormData((prevMessages) => [...prevMessages, message?.message])
    const currentDateTime = new Date().toISOString();
    const lastMessage = formData[formData.length - 1];
    const newId = lastMessage ? lastMessage.id + 1 : 1;

    const optimisticMessage = {
      id: newId,
      reciever_id: id,
      message: message?.message,
      created_at: currentDateTime,
      sender_id: user_id
    };
    // setFormData(prevMessages => [...prevMessages, optimisticMessage]);

    // formDataMethod.append('id', message?.image)
    formDataMethod.append("image", message?.image);
    formDataMethod.append("message", message?.message);
    setMessage("");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/public/api/sendchat`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("userToken")}`
          },
          body: formDataMethod
        }
      );

      const data = await response.json();
      console.log("data?.status", data?.status);
      // const newMessage = data?.data
      if (data?.status == true) {
        scrollToBottom();
        user_list();
        setFormData((prevMessages) =>
          prevMessages.map((msg) =>
            msg.id === optimisticMessage.id ? data?.data : msg
          )
        );

        // setFormData(prevMessages => prevMessages.map(msg => msg.id === optimisticMessage.id ? newMessage : msg));

        setMessage({
          message: "",
          image: null
        });
        setFileName("");
        // ProductData()
        // toast.success("Message sent successfully");
      } else {
        setMessage("");
        // toast.error(data?.message || "Failed to send message");
      }
    } catch (error) {
      setFormData((prevMessages) =>
        prevMessages.filter((msg) => msg.id !== optimisticMessage.id)
      );

      console.error("Error in sending message:", error);
      // toast.error(error.message || "An error occurred");
    }
  };

  const [getreceverid, setgetreceverid] = useState();
  const [getreceveridtrue, setgetreceveridtrue] = useState(false);

  const ProductData = async (ids = id) => {
    console.log("ids", ids);
    setgetreceverid(ids);
    setgetreceveridtrue(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/public/api/loadchat/${ids}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("userToken")}`
          }
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      // if (data?.status == true) {
      //     scrollToBottom()
      //     setFormData(data?.data);
      // }

      scrollToBottom();
      const data = await response.json();
      console.log(data);

      setFormData(data?.data);
    } catch (error) {
      console.error("Error fetching product data:", error);
    }
  };

  useEffect(() => {
    ProductData();
  }, []);

  const { id } = useParams();
  const navigate = useNavigate();
  // const handleclick = () => {
  //   data-dismiss:"modal"
  //   navigate('/payment-page')
  // }

  const modalRef = useRef(null);

  const handleclick = () => {
    navigate("/");
  };
  const [flow, setUnflow] = useState(false);

  const flowmodel = async () => {
    try {
      const response = await UserUnflowmodel(id);

      if (response?.status == true) {
        const data = response?.data;
        // setModellists(data)
        setUnflow(response?.msg);
        model_listview();
        // model_listview();
        // model_listview();
      } else {
        // toastAlert(response.statusText, ALERT_TYPES.ERROR);
        console.log("packege ", response.statusText);
      }
      // setModellists(response?.data)
    } catch (error) {
      console.error("Error in logging in:", error);

      // toastAlert(error, ALERT_TYPES.ERROR);
    }
  };

  const [modellists, setModellists] = useState([]);
  //   dqdqwdqwdqwdewfwe
  const model_list = async () => {
    try {
      const response = await Getmodelpostlist(id);

      if (response?.status == true) {
        const data = response?.data;
        console.log("datadatail", data);
        setModellists(data);
        setLoading(false);
      } else {
        // toastAlert(response.statusText, ALERT_TYPES.ERROR);
        console.log("packege ", response.statusText);
        setLoading(false);
      }
      // setModellists(response?.data);
    } catch (error) {
      console.error("Error in logging in:", error);

      // toastAlert(error, ALERT_TYPES.ERROR);
    }
  };

  console.log("modellistsaaaaa", modellists);

  // useEffect(() => {
  //   model_list();
  // }, [user_id]);
  console.log("modellistsdetail", modellists);

  const [modellistsprofileview, setModelprofileview] = useState({});

  const model_listview = async () => {
    try {
      const response = await Userprogileview(id);
      console.log("response", response);

      if (response?.status == true) {
        const data = response?.data;
        console.log("data", data);
        setModelprofileview(data);
      } else {
        // toastAlert(response.statusText, ALERT_TYPES.ERROR);
        console.log("packege ", response.statusText);
      }
      setModelprofileview(response?.data);
    } catch (error) {
      console.error("Error in logging in:", error);

      // toastAlert(error, ALERT_TYPES.ERROR);
    }
  };

  useEffect(() => {
    model_listview();
  }, [id]);

  console.log("modellistsprofileview", modellistsprofileview);

  const [isLoading, setLoading] = useState(true);

  const user_list = async () => {
    try {
      const response = await Group_list();

      if (response?.status == true) {
        const data = response?.data;
        console.log("data", data);
        setUserlist(data);

        setuserProfile(data[0]);
      } else {
        // toastAlert(response.statusText, ALERT_TYPES.ERROR);
        console.log("packege ", response.statusText);
      }
      setprofilebyid(response?.data);
    } catch (error) {
      console.error("Error in logging in:", error);

      // toastAlert(error, ALERT_TYPES.ERROR);
    }
  };

  useEffect(() => {
    user_list();
  }, []);

  const handleunlockid = async (getpostid) => {
    // if (unlock == true) {
    try {
      const response = await profileunlockbyid(getpostid);

      if (response?.status == true) {
        const data = response?.data;
        console.log("data", data);
        // setModelprofileview(data);
        model_list();
        setunlockshow(false);
        model_list();
      } else {
        // toastAlert(response.statusText, ALERT_TYPES.ERROR);
        console.log("packege ", response.statusText);
      }
      setprofilebyid(response?.data);
    } catch (error) {
      console.error("Error in logging in:", error);

      // toastAlert(error, ALERT_TYPES.ERROR);
    }
  };
  useEffect(() => {
    handleunlockid(getpostid);
  }, [unlock == true]);

  const LogoutData = localStorage.getItem("userToken");
  const [profilebyid, setprofilebyid] = useState({});

  const model_listpicbyid = async (id) => {
    try {
      const response = await profileviewbyidmodel(id);

      if (response?.status == true) {
        const data = response?.data;
        console.log("data", data);
        // setModelprofileview(data);
      } else {
        // toastAlert(response.statusText, ALERT_TYPES.ERROR);
        console.log("packege ", response.statusText);
      }
      setprofilebyid(response?.data);
    } catch (error) {
      console.error("Error in logging in:", error);

      // toastAlert(error, ALERT_TYPES.ERROR);
    }
  };

  console.log("post-detail");
  const [creategrouplist, setcreategrouplist] = useState();

  console.log("modellists?.user_data?.id", modellists);

  const groups = async () => {
    try {
      const response = await Create_group(modellists?.user_data?.id);

      if (response?.status == true) {
        const data = response?.data;
      } else {
        console.log("packege ", response.statusText);
      }
      setcreategrouplist(response?.data);
    } catch (error) {
      console.error("Error in logging in:", error);

      // toastAlert(error, ALERT_TYPES.ERROR);
    }
  };

  useEffect(() => {
    // groups();
  }, [isCheckeds == true]);

  useEffect(() => {
    // groups();
    model_list();
    // model_listview();
    Aos.init();
  }, [id]);
  const [hearts, setHeart] = useState(false);

  // const handleHeart = () => {
  //   setHeart(!hearts);
  // };

  const handleHeart = async (ide) => {
    try {
      const response = await UserPostUnflowmodel(ide);
      console.log("response", response);

      // if (response?.status == true || false) {
      const data = response?.data;
      console.log("responsefavourite", response?.status);
      setHeart(response?.status);

      model_list();
      // } else {
      //   // toastAlert(response.statusText, ALERT_TYPES.ERROR);
      //   console.log("packege ", response.statusText);
      // }
      // setHeart(response);
    } catch (error) {
      console.error("Error in logging in:", error);

      // toastAlert(error, ALERT_TYPES.ERROR);
    }
  };
  // console.log("hearts" , hearts)
  // console.log("user_id" , user_id)

  // useEffect(() =>{
  //   handleHeart()
  // } , [user_id])
  console.log("hearts", hearts);

  const [useProfile, setuserProfile] = useState({});

  const baseurl = `${process.env.REACT_APP_BASE_URL}/public/`;

  const [modellist, setmodellisting] = useState(true);

  const [follow, setFollowing] = useState(false);
  const [sendmessages, setSendmessage] = useState(false);
  const [transactions, setTransactions] = useState(false);
  const handlepay = () => {
    navigate("/payment-page");
  };

  const [givestip, setGivestip] = useState(false);
  const following = () => {
    setFollowing(true);
    setmodellisting(false);
    setSendmessage(false);
    setTransactions(false);
    setGivestip(false);
  };
  const sendmessage = async () => {
    const url = await "";

    scrollToBottom();

    setSendmessage(true);
    setFollowing(false);
    setmodellisting(false);
    setTransactions(false);
    setGivestip(false);
  };

  const handlechanges = (e) => {
    const { name, value } = e.target;
    setMessage((prevdata) => ({
      ...prevdata,
      [name]: value
    }));
  };

  const transaction = () => {
    setTransactions(!transactions);
    setFollowing(false);
    setSendmessage(false);
    setmodellisting(false);
    setGivestip(false);
  };
  const showprofile = () => {
    setmodellisting(true);
    setTransactions(false);
    setFollowing(false);
    setSendmessage(false);

    setGivestip(false);
  };

  const givetip = () => {
    setTransactions(false);
    setFollowing(false);
    setSendmessage(false);
    setmodellisting(false);
    setGivestip(!givestip);
  };

  const [isChecked, setIsChecked] = useState(null);

  const [profilepreview, setProfilepreview] = useState(false);
  const HandleProfike = () => {
    setProfilepreview(true);
    setmodellisting(false);
    setSendmessage(false);
    setTransactions(false);
    setGivestip(false);
  };

  // const handleRadioChange = (id) => {
  //   if (id === isChecked) {
  //     setIsChecked(null);
  //     return;
  //   }
  // };
  const [selectedTip, setSelectedTip] = useState(null);

  const handleRadioChange = (id) => {
    setSelectedTip(id === selectedTip ? null : id);
  };

  const stylesForSidebar = {
    // marginTop: "0px",
    height: "242px",
    "border-radius": "20px",
    width: "192px",
    padding: "12px 0px",
    maxHeight: "300px"
    // "objectFit": "contain",
  };

  const formatDate = (timestamp) => {
    const now = new Date();
    const date = new Date(timestamp);

    const diffMs = now - date;

    // If the difference is less than a minute (60000 milliseconds), return "just now"
    if (diffMs < 60000) {
      return "just now";
    }

    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];

    const day = date.getDate();
    const month = months[date.getMonth()];
    const hours = date.getHours();
    const minutes = date.getMinutes();

    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12; // Convert to 12-hour format

    return `${day} ${month} ${formattedHours}:${formattedMinutes} ${ampm}`;
  };

  const formattedDate = formatDate("2024-06-28T09:32:17.000000Z");

  const [progress, setProgress] = useState(0);

  const [isLoadingimg, setIsLoading] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith("image/")) {
      setFileName(file.name);

      setMessage((prevMessages) => ({
        ...prevMessages,
        image: file
      }));
      setIsLoading(true); // Start loading

      const xhr = new XMLHttpRequest();
      xhr.upload.addEventListener("progress", (e) => {
        if (e.lengthComputable) {
          const uploadProgress = (e.loaded / e.total) * 100;
          setProgress(uploadProgress);
        }
      });
      xhr.upload.addEventListener("load", () => {
        setProgress(100);
        setTimeout(() => {
          setIsLoading(false);
        }, 5000);
      });

      xhr.upload.addEventListener("error", () => {
        console.error("Error uploading file");
        setProgress(0); // Reset progress on error
        setIsLoading(false); // Stop loading on error
      });

      xhr.open("POST", "/your-upload-endpoint");
      const formData = new FormData();
      formData.append("file", file);
      xhr.send(formData);
    }
  };

  const [formDatamessage, setformDatamessage] = useState({ message: "" });

  const handleSubmitmessage = async (event) => {
    event.preventDefault();

    // Create FormData object
    const formDataMethod = new FormData();
    for (const key in formDatamessage) {
      formDataMethod.append(key, formDatamessage[key]);
    }

    setLoading(true);

    try {
      const response = await SendMessageRequest(
        formDataMethod,
        modellistsprofileview?.id
      );

      if (response?.status == true) {
        // Success handling
        toastAlert(response.msg, ALERT_TYPES.SUCCESS);

        setformDatamessage((prev) => ({
          ...prev,
          message: ""
        }));
        setIsCheckeds(false);
      } else {
        // Error handling
        toastAlert(response.msg || response.statusText, ALERT_TYPES.ERROR);
      }
    } catch (error) {
      console.error("Error in adding model post:", error);
      toastAlert(error.message || "An error occurred", ALERT_TYPES.ERROR);
    } finally {
      // Always set loading to false at the end
      setLoading(false);
    }
  };

  const handlesendmessage = async (event) => {
    event.preventDefault();

    // Create FormData object
    const formDataMethod = new FormData();
    for (const key in formDatamessage) {
      formDataMethod.append(key, formDatamessage[key]);
    }
    formDataMethod.append("receiver_id", modellistsprofileview?.id);

    setLoading(true);
    // groups();
    try {
      const response = await socketsendmessage(formDataMethod);

      if (response?.status == true) {
        // Success handling
        toastAlert(response.msg, ALERT_TYPES.SUCCESS);

        setformDatamessage((prev) => ({
          ...prev,
          message: ""
        }));

        setIsCheckeds(false);
      } else {
        // Error handling
        toastAlert(response.msg || response.statusText, ALERT_TYPES.ERROR);
      }
    } catch (error) {
      console.error("Error in adding model post:", error);
      toastAlert(error.message || "An error occurred", ALERT_TYPES.ERROR);
    } finally {
      // Always set loading to false at the end
      setLoading(false);
    }
  };

  const handleSubmit = (event) => {
    if (formDatamessage.message != "") {
      console.log(
        'formDatamessage.message != ""',
        formDatamessage.message != "",
        formDatamessage.message
      );
      if (isCheckeds == true) {
        handleSubmitmessage(event);
      } else {
        handlesendmessage(event);
      }
    }
  };

  const handlechangemessage = (e) => {
    const { name, value } = e.target;
    setformDatamessage((prevdata) => ({
      ...prevdata,
      [name]: value
    }));
  };

  const handlePostOptionChange = (id) => {
    setIsCheckeds((prev) => !prev); // Toggle the checked state
    console.log("Checkbox ID:", id);
    console.log("Checkbox checked:", !isCheckeds); // Log the new state
  };

  console.log("modellistsprofileview", modellistsprofileview);
  return (
    <>
      <Header />
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <div></div>

          <div className="profile_section_main">
            <div className="container">
              <div className="row">
                <div className="col-md-3">
                  <div className="profile_left_part">
                    <div className="user_profile_main position-relative">
                      {/* <div className="user_profile_bk_img">
                                        <img src={userProfilePicBackground} className="img-fluid" />
                                    </div> */}

                      <div className="user_profile_info text-center px-3">
                        <div
                          type="button"
                          onClick={showprofile}
                          className="user_profile_picture"
                          data-aos="flip-left"
                          data-aos-anchor-placement="center-bottom"
                          data-aos-duration="3000"
                        >
                          {/* <img src={(baseurl + modellistsprofileview?.profile_pic) && (modelImg01)} style={stylesForSidebar} /> */}
                          <img
                            src={
                              modellistsprofileview?.profile_pic
                                ? baseurl + modellistsprofileview?.profile_pic
                                : dummy
                            }
                            style={stylesForSidebar}
                          />
                        </div>

                        <div className="user_info">
                          <div
                            type="button"
                            onClick={showprofile}
                            className="name_with_status pt-3"
                            data-aos="fade-right"
                            data-aos-anchor-placement="center-bottom"
                            data-aos-duration="1000"
                          >
                            <span className="online_circle">
                              <i class="fa-solid fa-circle"></i>
                            </span>
                            <span className="hot_model_name">
                              {modellistsprofileview?.name || "HOTMODEL1234"}
                            </span>
                          </div>

                          <div
                            type="button"
                            onClick={showprofile}
                            className="mb-3"
                            data-aos="fade-right"
                            data-aos-anchor-placement="center-bottom"
                            data-aos-duration="2000"
                          >
                            {/* <span className="user_access">
                              {modellistsprofileview?.email ||
                                "HOTMODEL1234@gmail.com"}
                            </span> */}
                          </div>
                          {/* <div className="main_about_div">
                            <h6 className="hot_model_name  about_text">
                              About
                            </h6>
                            <p className="user_profile_about">
                              {modellistsprofileview?.bio}
                            </p>
                          </div> */}

                          <div className="user_about_icons">
                            <span className="facebook_icon">
                              <Link to={modellistsprofileview?.facebook_link}>
                                {/* <FontAwesomeIcon icon="fa-brands fa-facebook-f" /> */}
                                <i class="fa-brands fa-facebook-f"></i>
                              </Link>
                            </span>

                            <span className="facebook_icon">
                              {/* <FontAwesomeIcon icon="fa-brands fa-square-instagram" /> */}
                              <Link to={modellistsprofileview?.instagram_link}>
                                <i class="fa-brands fa-instagram"></i>
                              </Link>
                            </span>

                            <span className="facebook_icon">
                              <Link to={modellistsprofileview?.youtube_link}>
                                {/* <FontAwesomeIcon icon="fa-brands fa-square-instagram" /> */}
                                <i class="fa-brands fa-youtube"></i>
                              </Link>
                            </span>

                            <span className="facebook_icon">
                              <Link to={modellistsprofileview?.twitter_link}>
                                {/* <FontAwesomeIcon icon="fa-brands fa-square-instagram" /> */}
                                <i class="fa-brands fa-twitter"></i>
                              </Link>
                            </span>
                          </div>
                        </div>

                        {/* <button
                              className=" followers_numbers"
                              data-aos="fade-left"
                              data-aos-anchor-placement="center-bottom"
                              data-aos-duration="3000"
                              onClick={HandleProfike}
                            >
                              <i class="fa-solid fa-envelope profile_btn_icons"></i>
                              Profile Preview
                            </button>

 */}

                        <div
                          data-aos="fade-right"
                          data-aos-anchor-placement="center-bottom"
                          data-aos-duration="3000"
                        >
                          <button
                            data-toggle="modal"
                            data-target=".exampleModaltip"
                            className="followers_numbers mb-3"
                          >
                            {" "}
                            <i class="fas fa-donate profile_btn_icons"></i> Send
                            Tip{" "}
                          </button>
                        </div>

                        <div
                          data-aos="fade-right"
                          data-aos-anchor-placement="center-bottom"
                          data-aos-duration="3000"
                        >
                          <button className="followers_numbers mb-3">
                            {" "}
                            <i class="fa-solid fa-users-line profile_btn_icons"></i>{" "}
                            Fans {`(${modellistsprofileview?.follower || "0"})`}
                          </button>
                        </div>

                        <button
                          data-toggle="modal"
                          data-target="#modalTwo"
                          class="followers_numbers aos-init aos-animate"
                          data-aos="fade-left"
                          data-aos-anchor-placement="center-bottom"
                          data-aos-duration="3000"
                        >
                          <i class="fa-solid fa-envelope profile_btn_icons"></i>
                          Send Message{role === "3" ? " Or Request" : ""}
                        </button>
                        <button
                          onClick={flowmodel}
                          class="followers_numbers aos-init aos-animate mt-3"
                          data-aos="fade-left"
                          data-aos-anchor-placement="center-bottom"
                          data-aos-duration="3000"
                        >
                          <i class="fa-solid fa-user-plus profile_btn_icons"></i>
                          {modellistsprofileview?.follow == true
                            ? "UnFollow"
                            : "Follow"}
                        </button>
                      </div>

                      <div class="model_card_top_corner_img">
                        <img src={modelCardTopCorner} />
                      </div>

                      <div class="model_card_bottom_corner_img">
                        <img src={modelCardBottomCorner} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-9">
                  <div className="feet_container_main">
                    {modellists && (
                      <div className="row  ">
                        {modellists?.map((items, index) => (
                          <div
                            className="col-sm-6 col-lg-4"
                            // onClick={() => handleid(items?.id)}
                          >
                            {/* {items?.is_paid == false ? (
                                        <div
                                          type="btn"
                                          onClick={() =>
                                            model_listpicbyid(items?.id)
                                          }
                                          className="lock_icon_image"
                                          data-toggle="modal"
                                          data-target=".exampleModalunlock"
                                        >
                                          <img src={locked_icon} />
                                        </div>
                                      ) : (
                                        ""
                                      )} */}
                            <div className="first_model_card">
                              <Swiper
                                spaceBetween={30}
                                slidesPerView={1}
                                onSlideChange={() =>
                                  console.log("slide change")
                                }
                                onSwiper={(swiper) => console.log(swiper)}
                              >
                                {items?.post_data?.map((data) => (
                                  <SwiperSlide
                                    onClick={() => {
                                      model_listpicbyid(items?.id);
                                      setgetpostid(data?.post_id);
                                    }}
                                  >
                                    <div
                                      className="model_card_img position-relative first_model_card"
                                      data-toggle="modal"
                                      // data-target=".exampleModal"

                                      data-target={
                                        data?.is_blur !== true
                                          ? ".exampleModal"
                                          : undefined
                                      }
                                    >
                                      {data?.is_blur == true ? (
                                        <div
                                          type="btn"
                                          onClick={() =>
                                            model_listpicbyid(items?.id)
                                          }
                                          id="lock_img"
                                          className="lock_icon_image"
                                          data-toggle="modal"
                                          data-target=".exampleModalunlock"
                                        >
                                          <img
                                            src={locked_icon}
                                            id="lock_img"
                                          />
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      {data?.is_blur == true ? (
                                        <img
                                          src={onfrontimage}
                                          className="img-fluid"
                                        />
                                      ) : (
                                        <img
                                          src={
                                            data?.file
                                              ? baseurl + data.file
                                              : "dummy"
                                          }
                                          className="img-fluid"
                                        />
                                      )}
                                      <span className="edit_icon_img"></span>
                                      <span className="share_icon_img"></span>
                                      <span className="boost_icon_img"></span>
                                    </div>
                                  </SwiperSlide>
                                ))}
                              </Swiper>

                              <div className="model_card_top_corner_img">
                                <img src={modelCardTopCorner} />
                              </div>

                              <div
                                className="model_card_bottom_corner_img"
                                id=""
                              >
                                <img src={modelCardBottomCorner} />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}

                    {sendmessages && (
                      <div className="tabs_box box_height">
                        <div className="row">
                          <div className="col-md-12 ">
                            <h3
                              className="inbox_heading"
                              data-aos="fade-right"
                              data-aos-anchor-placement="center-bottom"
                              data-aos-duration="3000"
                            >
                              Inbox
                            </h3>
                            <div className="divider_row"></div>
                          </div>
                        </div>

                        <div className="row inbox_container">
                          <div className="col-lg-4 col-sm-12 right_divider ">
                            <div className="example example_one">
                              {/* <form class="" action="/action_page.php" >
                              <button type="submit"><i class="fa fa-search"></i></button>
                              <input type="text" placeholder="Search Message" name="search2" />
                            </form> */}
                              <div className="example example_one">
                                <input
                                  onChange={handleChange}
                                  type="text"
                                  placeholder="Search Message"
                                  name="name"
                                  value={inputValue}
                                />
                              </div>
                            </div>
                            {/* <div className="profile_div">
                            <div> <img className="img-fluid profile_img" src={userProfilePic} /></div>
                            <div>
                              <p className="profile_name"> Brittanyvues <sup className="profile_message_date"> 29 May 07:55 AM </sup> </p>
                              <p className="message_text">I am</p>
                            </div>
                          </div> */}
                            {filteredList?.map((data) => (
                              <div
                                className="profile_div mt-2"
                                id={
                                  data?.id == getreceverid
                                    ? "userprofilelist"
                                    : ""
                                }
                              >
                                <div>
                                  {" "}
                                  <img
                                    className="img-fluid profile_img  "
                                    src={
                                      data?.profile_pic
                                        ? baseurl + data?.profile_pic
                                        : dummy
                                    }
                                  />
                                </div>
                                <div
                                  onClick={() => (
                                    navigate(
                                      `/model-profile-page?user_id=${data?.id}`
                                    ),
                                    ProductData(data?.id),
                                    setuserProfile(data)
                                  )}
                                  type="button"
                                >
                                  {/*  <sup className="profile_message_date"> 29 May 07:55 AM </sup>  */}
                                  <p className="profile_name"> {data?.name} </p>
                                  <p className="message_text">
                                    {/* {data?.last_message?.message} */}
                                    {`${data.last_message?.message?.slice(
                                      0,
                                      10
                                    )}...`}
                                  </p>
                                </div>
                              </div>
                            ))}
                          </div>

                          <div className="col-lg-8 col-sm-12 p-0 ">
                            <div className="inbox_header_row">
                              {/* <div className="profile_div pl-3">
                              <div> <img className="img-fluid profile_img" src={userProfilePic} /></div>

                              <div>
                                <p className="profile_name"> Brittanyvues </p>

                              </div>
                            </div> */}
                              <div className="profile_div pl-3">
                                <div>
                                  {" "}
                                  <img
                                    className="img-fluid profile_img"
                                    src={
                                      useProfile?.profile_pic
                                        ? baseurl + useProfile?.profile_pic
                                        : dummy
                                    }
                                  />
                                </div>

                                <div>
                                  <p className="profile_name">
                                    {" "}
                                    {useProfile?.name}{" "}
                                  </p>
                                </div>
                              </div>

                              <div className="custom_dropdown_div">
                                <div class="dropdown">
                                  <button
                                    class=" custom_btn_secondary"
                                    type="button"
                                    data-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i class="fa-solid fa-ellipsis"></i>
                                  </button>
                                  <div class="dropdown-menu custom_dropdown-menu">
                                    <Link
                                      class="dropdown-item custom_dropdown_item"
                                      to="/model-profile-page"
                                    >
                                      View Profile
                                    </Link>
                                    {/* <a
                                    class="dropdown-item custom_dropdown_item"
                                    href="#"
                                  >
                                    Delete Chat
                                  </a> */}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="divider_row"></div>

                            <div className="main_chat_div" ref={chatDivRef}>
                              {formData &&
                                formData
                                  // .filter(data => data?.sender_id == id || data?.reciever_id == id)
                                  .filter(
                                    (data) =>
                                      data?.sender_id == user_id ||
                                      data?.reciever_id == user_id ||
                                      data?.reciever_id == getreceverid
                                  )
                                  .map((data, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className={
                                          data?.sender_id == user_id
                                            ? "chat_box"
                                            : "chat_box_reply"
                                        }
                                      >
                                        <div
                                          ref={
                                            index === formData.length - 1
                                              ? messagesEndRef
                                              : null
                                          }
                                        />
                                        {data?.filePath && (
                                          // <img
                                          //   src={baseurl + data?.filePath}
                                          //   className="imagemessage img-fluid mb-1"
                                          // />
                                          <img
                                            src={
                                              data?.filePath
                                                ? baseurl + data?.filePath
                                                : dummy
                                            }
                                            className="imagemessage img-fluid mb-1"
                                            style={{
                                              height: "auto",
                                              maxHeight: "500px",
                                              width: "auto",
                                              maxWidth: "100%"
                                            }}
                                          />
                                        )}
                                        <br />
                                        {data?.message && (
                                          <p
                                            className={
                                              data?.sender_id == user_id
                                                ? "message_para"
                                                : "message_para_reply"
                                            }
                                          >
                                            {data?.message}
                                          </p>
                                        )}
                                        <p className="message_date">
                                          {formatDate(data?.created_at)}
                                        </p>
                                      </div>
                                    );
                                  })}
                            </div>

                            <div className="message_sent_box">
                              {/* {progress > 0 && progress < 100 && ( */}

                              {isLoadingimg == true ? (
                                <progress
                                  id="progressBar"
                                  value={progress}
                                  max="100"
                                  class="blue-progress-bar"
                                  style={{ width: "140px", height: "9px" }}
                                ></progress>
                              ) : (
                                <p className="message_file">{fileName}</p>
                              )}
                              {/* )} */}
                              {/* {progress === 100 && <p className="message_file">{fileName}</p>} */}
                              {/* <div>{progress > 0 && progress < 100 && `${Math.round(progress)}%`}</div> */}
                              <div className="main_btn_input_div">
                                <input
                                  type="file"
                                  id="inputFile"
                                  name="image"
                                  onChange={handleFileChange}
                                  style={{ display: "none" }}
                                />
                                <label htmlFor="inputFile">
                                  <i className="fa-regular fa-images"></i>
                                </label>
                                <input
                                  type="text"
                                  name="message"
                                  onChange={handlechanges}
                                  className="message_type_box"
                                  value={message?.message}
                                  placeholder="Write Text"
                                  id="name"
                                  required
                                />
                                <button
                                  disabled={message.message == ""}
                                  onClick={sendMessage}
                                  className="message_type_box_icon"
                                >
                                  <i
                                    className=" fa fa-paper-plane"
                                    aria-hidden="true"
                                    id="sendicon"
                                  ></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {transactions && (
                      <div className="row tabs_box">
                        <div className="col-md-12">
                          <h3
                            className="following_heading"
                            data-aos="fade-right"
                            data-aos-anchor-placement="center-bottom"
                            data-aos-duration="3000"
                          >
                            Transactions
                          </h3>
                          <div className="divider_row"></div>
                        </div>
                        <div
                          className="col-md-12"
                          data-aos="fade-up"
                          data-aos-anchor-placement="center-bottom"
                          data-aos-duration="3000"
                        >
                          <div class="table-responsive">
                            <table class="table">
                              <thead class="table_header_bg">
                                <tr>
                                  <th scope="col" className="table_header_row">
                                    Date
                                  </th>
                                  <th scope="col" className="table_header_row">
                                    Amount
                                  </th>
                                  <th scope="col" className="table_header_row">
                                    Seller
                                  </th>
                                  <th scope="col" className="table_header_row">
                                    Purchased
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td class="order_history">25.05.2024</td>
                                  <td class="order_history">$80.00</td>
                                  <td class="order_history">
                                    <a href="#" className="seller_text">
                                      {" "}
                                      Brittanyvues{" "}
                                    </a>
                                  </td>
                                  <td class="order_history">
                                    Unlocked collection: Lime green high heels{" "}
                                    <span>
                                      <a href="#" className="view_links">
                                        {" "}
                                        View{" "}
                                      </a>
                                    </span>
                                  </td>
                                </tr>
                                <tr className="bg_table_row">
                                  <td class="order_history">29.05.2024</td>
                                  <td class="order_history">$7.00</td>
                                  <td class="order_history">
                                    <a href="#" className="seller_text">
                                      {" "}
                                      Brittanyvues{" "}
                                    </a>
                                  </td>
                                  <td class="order_history">
                                    Unlocked collection: Lime green high heels{" "}
                                    <span>
                                      <a href="#" className="view_links">
                                        {" "}
                                        {/* View{" "} */}
                                      </a>
                                    </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td class="order_history">26.05.2024</td>
                                  <td class="order_history">$9.00</td>
                                  <td class="order_history">
                                    <a href="#" className="seller_text">
                                      {" "}
                                      Brittanyvues{" "}
                                    </a>
                                  </td>
                                  <td class="order_history">
                                    Unlocked collection: Lime green high heels{" "}
                                    <span>
                                      <a href="#" className="view_links">
                                        {" "}
                                        View{" "}
                                      </a>
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* {Recivedtips && (
                      <div className="row tabs_box">
                        <div className="col-md-12">
                          <h3
                            className="following_heading"
                            data-aos="fade-right"
                            data-aos-anchor-placement="center-bottom"
                            data-aos-duration="3000"
                          >
                            Tips History
                          </h3>
                          <div className="divider_row"></div>
                        </div>
                        <div
                          className="col-md-12"
                          data-aos="fade-up"
                          data-aos-anchor-placement="center-bottom"
                          data-aos-duration="3000"
                        >
                          <div class="table-responsive">
                            <table class="table">
                              <thead class="table_header_bg">
                                <tr>
                                  <th scope="col" className="table_header_row">
                                    Date
                                  </th>
                                  <th scope="col" className="table_header_row">
                                    Amount
                                  </th>
                                  <th scope="col" className="table_header_row">
                                    Name
                                  </th>
                                  <th scope="col" className="table_header_row">
                                    Purchased
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td class="order_history">25.05.2024</td>
                                  <td class="order_history">$80.00</td>
                                  <td class="order_history">
                                    <a href="#" className="seller_text">
                                      {" "}
                                      Brittanyvues{" "}
                                    </a>
                                  </td>
                                  <td class="order_history">
                                    Unlocked collection: Lime green high heels{" "}
                                    <span>
                                      <a href="#" className="view_links">
                                        {" "}
                                        View{" "}
                                      </a>
                                    </span>
                                  </td>
                                </tr>
                                <tr className="bg_table_row">
                                  <td class="order_history">29.05.2024</td>
                                  <td class="order_history">$7.00</td>
                                  <td class="order_history">
                                    <a href="#" className="seller_text">
                                      {" "}
                                      Brittanyvues{" "}
                                    </a>
                                  </td>
                                  <td class="order_history">
                                    Unlocked collection: Lime green high heels{" "}
                                    <span>
                                      <a href="#" className="view_links">
                                        {" "}
                                        View{" "}
                                      </a>
                                    </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td class="order_history">26.05.2024</td>
                                  <td class="order_history">$9.00</td>
                                  <td class="order_history">
                                    <a href="#" className="seller_text">
                                      {" "}
                                      Brittanyvues{" "}
                                    </a>
                                  </td>
                                  <td class="order_history">
                                    Unlocked collection: Lime green high heels{" "}
                                    <span>
                                      <a href="#" className="view_links">
                                        {" "}
                                        View{" "}
                                      </a>
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    )} */}

                    {/*                     

 {profilepreview && (
                        <div className="tabs_box box_height">
                          <div className="row">
                            <div className="col-md-12 ">
                              <h3
                                className="inbox_heading"
                                data-aos="fade-right"
                                data-aos-anchor-placement="center-bottom"
                                data-aos-duration="3000"
                              >
                                Profile Preview
                              </h3>
                              <div className="divider_row mb-4"></div>
                            </div>
                          </div>

                          <div className="row  image_profile">
                            <div className="col-lg-12 mb-2">
                              <div
                                className="user_profile_picture"
                                onClick={showprofile}
                                data-aos="flip-left"
                                data-aos-anchor-placement="center-bottom"
                                data-aos-duration="3000"
                                role="button"
                              >
                                <div className="image_wrapper">
                                  <img
                                    // onClick={updateprofile}
                                    src={
                                      modellistsprofileview?.profile_pic_request !==
                                      0
                                        ? modellistsprofileview?.profile_pic
                                          ? baseurl +
                                            modellistsprofileview?.profile_pic
                                          : dummy
                                        : modellistsprofileview?.old_profile_pic
                                        ? baseurl +
                                          modellistsprofileview?.old_profile_pic
                                        : dummy
                                    }
                                    alt="User Profile"
                                    className="profile_image"
                                    style={stylesForSidebar}
                                  />
                                </div>
 
                              </div>
                            </div>

                            <div className="col-lg-6">
                              <div className="row mb-4">
                                <div className="col-lg-6 mb-3">
                                  <h4 className="secondaryLabel hot_model_name">
                                    Genger
                                  </h4>
                                  <p className="secondaryText user_profile_about">
                                    {" "}
                                    {modellistsprofileview?.build_and_gender
                                      ? modellistsprofileview?.build_and_gender
                                      : "Not Available"}
                                  </p>
                                </div>

                                <div className="col-lg-6 mb-3">
                                  <h4 className="secondaryLabel hot_model_name">
                                    Hair Colour
                                  </h4>
                                  <p className="secondaryText user_profile_about">
                                    {" "}
                                    {modellistsprofileview?.hair_colour
                                      ? modellistsprofileview?.hair_colour
                                      : "Not Available"}
                                  </p>
                                </div>
                                <div className="col-lg-6 mb-3">
                                  <h4 className="secondaryLabel hot_model_name">
                                    Eyes Colour
                                  </h4>
                                  <p className="secondaryText user_profile_about">
                                    {" "}
                                    {modellistsprofileview?.eyes_colour
                                      ? modellistsprofileview?.eyes_colour
                                      : "Not Available"}
                                  </p>
                                </div>
                                <div className="col-lg-6 mb-3">
                                  <h4 className="secondaryLabel hot_model_name">
                                    Foot Size
                                  </h4>
                                  <p className="secondaryText user_profile_about">
                                    {" "}
                                    {modellistsprofileview?.foot_size
                                      ? modellistsprofileview?.foot_size
                                      : "Not Available"}
                                  </p>
                                </div>

                                <div className="col-lg-6 mb-3">
                                  <h4 className="secondaryLabel hot_model_name">
                                    Skin Tone
                                  </h4>
                                  <p className="secondaryText user_profile_about">
                                    {" "}
                                    {modellistsprofileview?.skin_tone
                                      ? modellistsprofileview?.skin_tone
                                      : "Not Available"}
                                  </p>
                                </div>
                                <div className="col-lg-6 mb-3">
                                  <h4 className="secondaryLabel hot_model_name">
                                    About
                                  </h4>
                                  <p className="secondaryText user_profile_about">
                                    {" "}
                                    {modellistsprofileview?.bio
                                      ? modellistsprofileview?.bio
                                      : "Not Available"}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )} */}

                    {givestip && (
                      <div className="row paytab">
                        <div className="col-md-19">
                          <h3
                            className="following_heading"
                            data-aos="fade-right"
                            data-aos-anchor-placement="center-bottom"
                            data-aos-duration="3000"
                          >
                            Payment Information
                          </h3>
                          <div className="divider_row"></div>
                        </div>
                        <div
                          className="col-md-12"
                          data-aos="fade-up"
                          data-aos-anchor-placement="center-bottom"
                          data-aos-duration="3000"
                        >
                          <h4 className="  text-white mt-2 mb-2">
                            Choose Amount{" "}
                          </h4>
                          <div className="tipgap row  mx-auto    ">
                            <div className="paytips col-md-3    mb-4 justify-content-center">
                              <input
                                className=""
                                type="radio"
                                id="ti"
                                checked={isChecked}
                                onClick={() => handleRadioChange(0)}
                              />{" "}
                              <label for="t1">$25</label>
                            </div>

                            <div className="paytips col-md-3    mb-4 justify-content-center">
                              <input
                                className=""
                                type="radio"
                                checked={isChecked}
                                onClick={() => handleRadioChange(1)}
                              />{" "}
                              $50
                            </div>

                            <div className="paytips col-md-3    mb-4 justify-content-center">
                              <input
                                className=""
                                type="radio"
                                checked={isChecked}
                                onClick={() => handleRadioChange(2)}
                              />{" "}
                              $100
                            </div>
                            <div className="paytips col-md-3  mb-4 ">
                              <input
                                className=""
                                type="radio"
                                checked={isChecked}
                                onClick={() => handleRadioChange(3)}
                              />{" "}
                              $120
                            </div>
                            <div className="paytips col-md-3  mb-4 ">
                              <input
                                className=""
                                type="radio"
                                checked={isChecked}
                                onClick={() => handleRadioChange(4)}
                              />{" "}
                              $150
                            </div>
                            <div className="paytips col-md-3  mb-4 ">
                              <input
                                className=""
                                type="radio"
                                checked={isChecked}
                                onClick={() => handleRadioChange(5)}
                              />{" "}
                              $200
                            </div>
                          </div>

                          <h4 className="  text-white mt-2 mb-2">
                            Custom Amount{" "}
                          </h4>
                          <div className="custompay mb-4">
                            {" "}
                            $
                            <input className="custom" type="number" />
                          </div>
                          <button className="paybtn mt -4">Pay Now</button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-12">
                  <div
                    class="modal fade exampleModal"
                    // id="exampleModal"
                    tabindex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog modal-dialog-centered my-modal">
                      <div class="modal-content">
                        <div className="carousel-modal">
                          {/* <div class="carousel-header">
                                                <div className="carousel-icons">
                                                    <div className="caarousel-icons_inner">
                                                        <a
                                                            href="javaScript:;"
                                                            className="cancel"
                                                            data-dismiss="modal"
                                                        >
                                                            <img src={Cancel} />
                                                        </a>
                                                        <a type="button" onClick={handleHeart} className="heart">
                                                             <i className={`fa ${hearts ? 'fa-solid' : 'fa-regular'} fa-heart`}></i>
                                                        </a>

                                                    </div>
                                                </div>
                                                
                                            </div> */}

                          <div class="carousel-header mb-5">
                            <div className="carousel-icons">
                              <div className="caarousel-icons_inner">
                                <a
                                  href="javaScript:;"
                                  className="cancel"
                                  data-dismiss="modal"
                                >
                                  <img src={Cancel} />
                                </a>

                                {LogoutData && (
                                  <a
                                    type="button"
                                    onClick={() => handleHeart(profilebyid?.id)}
                                    className="heart"
                                  >
                                    <i
                                      className={`  ${
                                        modellists?.is_favourite == true ||
                                        hearts
                                          ? "fa-solid"
                                          : "fa-regular"
                                      } fa-heart`}
                                    ></i>
                                  </a>
                                )}
                              </div>
                            </div>
                          </div>
                          <Swiper
                            slidesPerView={"auto"}
                            centeredSlides={true}
                            spaceBetween={15}
                            navigation={true}
                            // pagination={{
                            //   clickable: true,
                            // }}
                            modules={[Navigation]}
                            className="mySwiper mt-5"
                          >
                            {" "}
                            {profilebyid?.post_data?.map((data) => (
                              <SwiperSlide>
                                {/* <div> */}
                                <img
                                  src={
                                    data?.file ? baseurl + data?.file : dummy
                                  }
                                  className="modalpic"
                                />
                                {/* </div> */}
                              </SwiperSlide>
                            ))}
                          </Swiper>

                          <div className="carousel-footer">
                            <h4 className="carousel-footer_title">
                              <h3 className="modal-title mt-3">
                                {profilebyid?.post_title} :)
                              </h3>
                            </h4>
                            <p className="carousel-footer_body">
                              {profilebyid?.post_description}
                            </p>
                            <p className="carousel-footer_price">
                              ${profilebyid?.price}{" "}
                            </p>

                            <div className=" post_detail_view d-flex g-4  ">
                              <button
                                data-toggle="modal"
                                data-target=".exampleModaltip"
                                className="carousel-footer_button mb-3"
                              >
                                {" "}
                                {/* <i class="fas fa-donate profile_btn_icons"></i>{" "} */}
                                Send Tip{" "}
                              </button>
                              {/* {profilebyid?.price !== 0 ? ( */}
                              <button
                                onClick={handleclick}
                                // className="carousel-footer_button "
                                data-dismiss="modal"
                                className="carousel-footer_button mb-3"
                              >
                                Buy
                              </button>
                            </div>
                            {/* ) : (
                              ""
                            )} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div
                    class="modal fade exampleModaltip"
                    // id="exampleModal"

                    tabindex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog modal-dialog-centered my-modal">
                      <div class="modal-content">
                        <div className="carousel-modal">
                          <div class="carousel-header mb-3">
                            <div className="carousel-icons">
                              <div className="caarousel-icons_inner">
                                <a
                                  href="javaScript:;"
                                  className="cancel"
                                  data-dismiss="modal"
                                >
                                  <img src={Cancel} />
                                </a>
                              </div>
                            </div>
                          </div>

                          <div className="row    px-4 ">
                            <div className="col-md-12  mt-4 ">
                              <h3
                                className="following_heading"
                                data-aos="fade-right"
                                data-aos-anchor-placement="center-bottom"
                                data-aos-duration="3000"
                              >
                                Payment Information
                              </h3>

                              <div className="divider_row"></div>
                            </div>

                            <div
                              className="col-md-4 choose_amount_column payment_right_side"
                              data-aos="fade-up"
                              data-aos-anchor-placement="center-bottom"
                              data-aos-duration="3000"
                            >
                              <div className="payment_img_div">
                                <img
                                  className="img-fluid payment_img"
                                  src={modelImg02}
                                  alt="Brittanyvues"
                                />

                                {/* <p className="image_text">Brittanyvues</p> */}

                                <div className="model_div">
                                  <div className="image_with_text_row">
                                    <img
                                      className="img-fluid model_img"
                                      src={userProfilePic}
                                    />

                                    <p className="profile_name_one">
                                      {" "}
                                      Brittanyvues{" "}
                                    </p>
                                  </div>

                                  <div className="image_with_text_row_two">
                                    {/* <p className="free_locked_text">
                                      <span className="unlocked_icon">
                                        <i className="fa-solid fa-unlock"></i>
                                      </span>
                                      Free
                                    </p>

                                    <p className="lock_text_clr free_locked_text">
                                      <span className="locked_icon">
                                        <i className="fa-solid fa-lock"></i>
                                      </span>
                                      Locked
                                    </p> */}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              className="col-md-8 choose_amount_column"
                              data-aos="fade-up"
                              data-aos-anchor-placement="center-bottom"
                              data-aos-duration="3000"
                            >
                              <h4 className="  text-white mt-2 mb-4">
                                Choose Amount{" "}
                              </h4>
                              <div className="tipgap row mx-auto">
                                {[100, 200, 300, 400, 450, 500].map(
                                  (amount, index) => (
                                    <div
                                      key={index}
                                      className="paytips col-md-3 mb-4 justify-content-center"
                                    >
                                      <input
                                        className=""
                                        id={`t${index + 1}`}
                                        type="radio"
                                        checked={selectedTip === index + 1}
                                        onChange={() =>
                                          handleRadioChange(index + 1)
                                        }
                                      />
                                      <label htmlFor={`t${index + 1}`}>
                                        ${amount}
                                      </label>
                                    </div>
                                  )
                                )}
                              </div>

                              <h4 className="  text-white mt-2 mb-2">
                                Custom Amount{" "}
                              </h4>

                              <div className="custompay  mx-auto justify-content-center d-flex mb-4">
                                {" "}
                                $
                                <input className="custom" type="number" />
                              </div>

                              <button
                                data-dismiss="modal"
                                onClick={handlepay}
                                className="paybtn mt -4"
                              >
                                Pay Now
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div
                    className="modal fade"
                    tabindex="-1"
                    aria-labelledby="exampleModaltip"
                    aria-hidden="true"
                    id="modalTwo"
                  >
                    <div className="modal-dialog modal-dialog-centered my-modal">
                      <div className="modal-content">
                        <div className="carousel-modal">
                          {/* <div className="carousel-header mb-3">
                            <div className="carousel-icons">
                              <div className="carousel-icons_inner">
                                <a
                                  href="javascript:void(0);"
                                  className="cancel"
                                  data-dismiss="modal"
                                >
                                  <img src={Cancel} alt="Cancel" />
                                </a>
                              </div>
                            </div>
                          </div> */}

                          <div className="row px-4">
                            {/* <div className="carousel-header mb-3">
                            <div className="carousel-icons">
                              <div className="carousel-icons_inner">
                                <a
                                  href="javascript:void(0);"
                                  className="cancel"
                                  data-dismiss="modal"
                                >
                                  <img src={Cancel} alt="Cancel" />
                                </a>
                              </div>
                            </div>
                          </div> */}

                            <div className="col-md-12 mt-4">
                              <h3
                                className="following_heading"
                                data-aos="fade-right"
                                data-aos-anchor-placement="center-bottom"
                                data-aos-duration="3000"
                              >
                                Send New Message{" "}
                                {role === "3" ? "or request" : ""}
                              </h3>
                              <div className="divider_row"></div>
                            </div>

                            {/* <div
                              className="col-md-4 choose_amount_column payment_right_side"
                              data-aos="fade-up"
                              data-aos-anchor-placement="center-bottom"
                              data-aos-duration="3000"
                            >
                              <div className="payment_img_div">
                                <img
                                  className="img-fluid payment_img"
                                  src={modelImg02}
                                  alt="Brittanyvues"
                                />
                                <div className="model_div">
                                  <div className="image_with_text_row">
                                    <img
                                      className="img-fluid model_img"
                                      src={userProfilePic}
                                      alt="User Profile"
                                    />
                                    <p className="profile_name_one">
                                      Brittanyvues
                                    </p>
                                  </div>
                                  <div className="image_with_text_row_two">
                                    <p className="free_locked_text">
                                      <span className="unlocked_icon">
                                        <i className="fa-solid fa-unlock"></i>
                                      </span>
                                      Free
                                    </p>
                                    <p className="lock_text_clr free_locked_text">
                                      <span className="locked_icon">
                                        <i className="fa-solid fa-lock"></i>
                                      </span>
                                      Locked
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div> */}

                            <div
                              className="col-lg-12   choose_amount_column d-flex"
                              data-aos="fade-up"
                              data-aos-anchor-placement="center-bottom"
                              data-aos-duration="3000"
                            >
                              {/* <h4 className="text-white mt-2 mb-4">
                                Choose Amount
                              </h4> */}
                              {/* <div className="tipgap row mx-auto">
                                {[100, 200, 300, 400, 450, 500].map(
                                  (amount, index) => (
                                    <div
                                      key={index}
                                      className="paytips col-md-3 mb-4 justify-content-center"
                                    >
                                      <input
                                        type="radio"
                                        id={`t${index + 1}`}
                                        checked={selectedTip === index + 1}
                                        onChange={() =>
                                          handleRadioChange(index + 1)
                                        }
                                      />
                                      <label htmlFor={`t${index + 1}`}>
                                        ${amount}
                                      </label>
                                    </div>
                                  )
                                )}
                              </div> */}

                              <h4 className="text-white mt-2 ">To :</h4>
                              <div className="custompay  ml-2 me-4  d-flex  flex-grow-1">
                                <input
                                  className="custom "
                                  type="text"
                                  value={modellistsprofileview?.name}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="profile_detail_message col-md-12">
                              <div className="d-flex justify-content-between flex-wrap">
                                <label className="namin"> Message </label>{" "}
                                {/* <input type="checkbox" /> */}
                              </div>

                              <span className="messageprofiledetail">
                                <textarea
                                  value={formData?.message}
                                  placeholder="Enter description"
                                  type="text"
                                  name="message"
                                  className="nam w-100"
                                  onChange={handlechangemessage}
                                  maxlength="300"
                                >
                                  {" "}
                                </textarea>
                              </span>
                              {role === "3" && (
                                <label className="custom_check-box gap-2 g-2">
                                  <input
                                    type="checkbox"
                                    className="chackbox_input ml-2  position-relative  ml-2"
                                    checked={isCheckeds} // Control the checkbox with state
                                    onChange={handlePostOptionChange} // Toggle state on change
                                  />
                                  <span className="custom_check-text">
                                    Send as a request
                                  </span>
                                </label>
                              )}
                              <div className="mb-4">
                                <h4> Stay Classy! </h4>
                                <p>
                                  Message Must be respectful and adide by the
                                  messageing Terms if Use,
                                </p>
                                <p>Thank You!: </p>

                                <button
                                  disabled={
                                    formDatamessage.message.trim() == ""
                                  }
                                  onClick={handleSubmit}
                                  data-dismiss="modal"
                                  className="paybtn mt-4"
                                >
                                  Send It !
                                </button>
                              </div>
                            </div>

                            {/* <button
                                data-dismiss="modal"
                                onClick={handlepay}
                                className="paybtn mt-4"
                              >
                                Pay Now
                              </button> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div
                    class="modal fade exampleModalunlock"
                    // id="exampleModal"

                    tabindex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog modal-dialog-centered my-modal justify-content-center">
                      <div class="modal-content modal-content-2">
                        <div className="carousel-modal p-0">
                          <div class="carousel-header mb-3">
                            <div className="carousel-icons">
                              <div className="caarousel-icons_inner">
                                <a
                                  href="javaScript:;"
                                  className="cancel"
                                  data-dismiss="modal"
                                >
                                  <img src={Cancel} />
                                </a>
                              </div>
                            </div>
                          </div>

                          <div className="row    px-4 ">
                            <div className="col-md-12  mt-4 ">
                              <h3
                                className="following_heading"
                                data-aos="fade-right"
                                data-aos-anchor-placement="center-bottom"
                                data-aos-duration="3000"
                              >
                                Unlocked Image
                              </h3>

                              <div className="divider_row"></div>
                            </div>

                            <div className="col-sm-6 col-lg-10 mt-4 mx-auto">
                              <div
                                className="lock_icon_image modal_lock_btn"
                                data-toggle="modal"
                                data-target=".exampleModalunlock"
                              >
                                <img src={locked_icon} />
                              </div>

                              <div className="unlock_successfully_div">
                                <h4>Unlock Successfully</h4>
                                <button
                                  onClick={() => setunlockshow(true)}
                                  data-dismiss="modal"
                                  className="modal_got_it_btn cancel"
                                >
                                  Got it
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <Footer />
          </div>
        </div>
      )}
    </>
  );
};

export default ModelDetail;
